.create {
    padding: 0;
}

.form {
    width: 100%;
    margin-top: 30px;
}

.article {
    margin-top: 24px;
}

.rows {
    margin: 20px 0;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.field {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fieldHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.fieldCounter {
    color: #002A77;
    text-align: right;
    font-family: Outfit, sans-serif;
    font-size: 13px;
}

.fieldCounter.error {
    color: red;
}

.label {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
}

.input {
    appearance: none;
    width: 100%;
    display: block;
    border-radius: 8px;
    border: 1px solid #002A77;
    background: #EEE;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    padding: 9px 13px;
}

.textarea {
    appearance: none;
    width: 100%;
    display: block;
    border-radius: 8px;
    border: 1px solid #002A77;
    background: #EEE;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    padding: 9px 13px;
    min-height: 80px;
    resize: none;
}

.textarea:focus {
    outline: none;
}

.blockError {
    border: 1px solid red !important;
}

.fieldError {
    border-bottom: 1px solid red !important;
}

.editor {
    margin-top: 15px;
}

[class*="RaCreate-noActions"] {
    margin-top: 0;
}

.switchers {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
}

.switcher {
    width: calc(40% - 20px);
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.switcherField label {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
}

.editor {
    margin-top: 24px;
}

.actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.save {
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
    box-shadow: none !important;
    color: #fff !important;
    background: #042E6B !important;
    text-transform: initial !important;
    padding: 8px 69px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: auto !important;
}

.delete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
}

.link {
    background: #E1E5ED;
    display: flex;
    align-items: center;
    border: 1px solid #042E6B;
    border-radius: 50px;
    padding: 7px 9px;
}

.link span {
    padding-left: 18px;
    margin-right: auto;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.link svg {
    min-width: 38px;
}

.uploadBox {
    width: 400px;
    height: 210px;
    margin: 35px auto;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.uploadedBox {
    width: 400px;
    height: 210px;
    margin: 35px auto;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 8px;
    border: 2px dashed #042E6B;
    background: #E1E5ED;
    padding: 10px 12px;
}

.image {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.uploadContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 210px;
    align-items: center;
    position: relative;
}

.uploadContentBlock {
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 13px;
    height: calc(100% - 14px);
}

.uploadContentTitle {
    color: #042E6B;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 13px;
}

.uploadContentComment {
    height: 28px;
    color: #042E6B;
    text-align: center;
    font-size: 10px;
}


.uploadContentText {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 10px;
}
