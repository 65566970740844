.box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 60px;
    height: 67px;
    min-height: 34px;
    border-radius: 8px;
    border: none;
}

.btn.small {
    width: initial;
    height: initial;
}

.text {
    color: #FFF;
    font-family: Outfit, sans-serif;
    font-size: 14px!important;
    font-weight: 400;
    line-height: normal;
}
