.wrap {
  position: relative;
  margin-bottom: 20px;
  height: initial !important;
}
.content {
  height: 100%;
  max-width: 50vw;
}
.carousel {
  height: 100%;
  position: initial !important;

  [class~="swiper-pagination"] {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  [class~="swiper-button-prev"],
  [class~="swiper-button-next"] {
    border-radius: 8px !important;
    background-color: #042E6B !important;
    position: absolute;
    top: initial !important;
    bottom: 16px !important;

    &::after {
      color: white !important;
    }
  }
  [class~="swiper-button-prev"] {
    left: 16px;
  }
  [class~="swiper-button-next"] {
    right: 16px;
  }
}
.slide {
  padding-left: 2px;
  padding-right: 2px;
}
.itemContent {
  flex-grow: 1;
}
.info {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-top: 10px;
}
.item {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  &.hasNeighbors {
    padding-bottom: 60px;
    .itemContent {
      flex-grow: initial;
    }
    .info {
      padding-top: 8px;
    }
  }
}
.actions {
  padding-top: 10px;
}
.btn {
  border-radius: 8px !important;
  text-transform: none !important;
}
.icon {
  margin-left: 10px;
}
.dots {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  .dot {
    width: 9px;
    height: 9px;
    background-color: #d9d9d9;
    border-radius: 50%;

    &.active {
      background-color: #042E6B;
    }
  }
}

.noDataText {
  margin-top: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: calc(100% - 80px);
}

.farmsEmpty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.createFarmBtn {
  padding: 15px 0 15px 16px;
  font-size: 19px;
}
