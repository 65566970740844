.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}

._ {
    display: flex;
    width: 100%;
    height: calc(100% - 20px);
    min-height: 243px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}

.title {
    margin-top: 12px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -12px;
}

.item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
    padding: 9px 0;
}

.itemIcon {
    border-radius: 100px;
    border: 2px solid red;
    background: red;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;

    &.success {
        border: 2px solid #042E6B;
        background: #042E6B;
    }
}
