/* Card */
.card {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 12px 15px 15px;
  height: 100%;
  color: #000;
  width: 100%;
}

.card.autoHeight {
  height: auto;
  margin-bottom: 20px;
}

.card.riskPadding {
  padding: 15px 22px 20px;
}

.card.mediumPadding {
  padding: 15px 22px;
}

.card.extraPadding {
  padding: 20px 32px 26px;
}

.card.cardWithLink {
  border: 1px solid #40a9ff;
  box-shadow: 0 4px 8px 0 #3b49571a;
  cursor: pointer;
}
.card.fullHeight {
  height: 100%;
}

.cardWithoutHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.cardHeader {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cardHeader.center {
  justify-content: center;
}

.cardHeaderLeft {
  display: flex;
}
.cardHeaderLeft .tooltip {
  height: 22px;
  margin-left: 5px;
}

.card .cardInfo {
  display: flex;
  justify-content: center;
}

.lockIcon {
  color: #AAA;
  font-size: 22px;
}
