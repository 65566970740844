.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 44px !important;
  max-width: 44px !important;
  text-align: center !important;
}


.grid table[class~="MuiTable-root"] th[class~="column-isPaid"],
.grid table[class~="MuiTable-root"] td[class~="column-isPaid"] {
  min-width: 60px !important;
  max-width: 60px !important;
  text-align: center !important;
}

.grid table[class~="MuiTable-root"] th[class~="RaDatagrid-expandHeader"],
.grid table[class~="MuiTable-root"] td[class~="RaDatagrid-expandIconCell"] {
  min-width: 44px !important;
  max-width: 44px !important;
  width: 44px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.grid table[class~="MuiTable-root"] th[class~="column-status"],
.grid table[class~="MuiTable-root"] td[class~="column-status"] {
  min-width: 138px !important;
  max-width: 138px !important;
  text-align: center !important;
}

.grid table[class~="MuiTable-root"] th[class~="column-user.status"],
.grid table[class~="MuiTable-root"] td[class~="column-user.status"],
.grid table[class~="MuiTable-root"] th[class~="column-balance"],
.grid table[class~="MuiTable-root"] td[class~="column-balance"] {
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}

.grid table[class~="MuiTable-root"] th[class~="column-exchangeRate"],
.grid table[class~="MuiTable-root"] td[class~="column-exchangeRate"],
.grid table[class~="MuiTable-root"] th[class~="column-positionsCost"],
.grid table[class~="MuiTable-root"] td[class~="column-positionsCost"],
.grid table[class~="MuiTable-root"] th[class~="column-totalCost"],
.grid table[class~="MuiTable-root"] td[class~="column-totalCost"],
.grid table[class~="MuiTable-root"] th[class~="column-creditId"],
.grid table[class~="MuiTable-root"] td[class~="column-creditId"] {
  min-width: 100px !important;
  max-width: 100px !important;
}

.grid table[class~="MuiTable-root"] th[class~="column-marginPercent"],
.grid table[class~="MuiTable-root"] td[class~="column-marginPercent"] {
  min-width: 80px;
  max-width: 80px;
}

.grid table[class~="MuiTable-root"] th[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] td[class~="column-createdAt"] {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.grid table table {
  width: 100%;
}

.grid table table th {
  border-top: 1px solid #042E6B;
  border-bottom: 1px solid #042E6B;
  border-right: 1px solid #042E6B;
  overflow: hidden;
}

.grid table table th:first-child {
  border-radius: 10px 0 0 0;
}

.grid table table th:last-child {
  border-radius: 0 10px 0 0;
}
