.doctype {
  position: relative;
  font-family: Outfit, sans-serif;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 15px;
}
.col {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #eee;
  flex-grow: 1;
  padding: 10px 5px 0;
  margin-right: 10px;
}
.col:last-child {
  margin-right: 0;
}
.doctypeLeft, .doctypeRight {
  display: flex;
  align-items: center;
}
.doctypeTitle {
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-right: 15px;
  margin-bottom: 2px;
  font-size: 20px;
  line-height: 15px;
  font-weight: 500;
  color: #000;
}
.formInput {
  margin-top: 15px;
  padding: 8px 20px;
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #E1E5ED;
}
.formInput * {
  background: #E1E5ED;
}
.info {
  margin-top: 5px;
}
.info .infoBlock {
  margin-top: 20px;
}

.controlsOptional {
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -56px;
  border-radius: 4px;
  border: 1px solid #042E6B;
  color: #292929;
  background: #FFFFFF;
  padding: 4px 14px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 110px;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.controlsOptional:disabled {
  cursor: not-allowed;
}

.controlsOptional:not(.checked):hover {
  background-color: whitesmoke;
}

.controlsOptional.checked {
  border-color: #037032;
}

.controlsOptional.checked:before {
  position: absolute;
  top: -5px;
  left: -10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  content: '';
  border-radius: 50%;
  background: #1DC265 url('/public/icons/check-v.svg') no-repeat center;
  margin-right: 10px;
}
.controlsOptional[disabled] {
  background: #dfdfdf;
  color: #909090;
  border-color: #909090;
}

.overlay [class~="ant-tooltip-inner"] {
  background: #042E6B;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
}
.overlay [class~="ant-tooltip-arrow-content"]::before {
  background: #042E6B;
}
.content {
  font-size: 10px;
}

.docVisibility svg {
  fill: rgba(76, 78, 100, 0.40);
  margin-right: 6px;
}

.statusBadge {
  flex-direction: row-reverse;

  [class~="badge-icon-box"] {
    margin-right: 0;
    margin-left: 6px;
  }
}
