.cardWrapper {
    min-height: 200px;
}
.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}
.cardWrapper [class~="InitialCard-left"] {
    font-size: 16px;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}
.wrap {
    padding-top: 14px;
}

.title {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 15px;
}

.lastCall {
    padding-bottom: 12px;
}
.callTop, .callBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.callTop {
    color: #002A77;
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.actions {
    display: flex;
    align-items: center;
    gap: 6px;
}
.action {

}
.actionLeft {
    border-radius: 4px;
    border: 1px solid #042E6B;
    background: #042E6B;
    padding: 4px 10px;
    color: #FFF;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.actionTitle {
    font-weight: 600;
}
.actionRight {
    display: flex;
    align-items: center;
    padding-top: 6px;
}

.status {
    padding: 4px 12px;
    border-radius: 10px;
    background-color: #bbb;
    color: #FFF;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    &[class~="pending"], &[class~="high"] {
        background-color: rgb(253, 73, 45);
    }

    &[class~="complete"], &[class~="low"] {
        background-color: rgb(137, 210, 86);
    }

    &[class~="medium"] {
        background-color: rgb(255, 200, 45);
    }
}

.empty {
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
