.btn {
    display: flex!important;
    align-items: center!important;
    border-radius: 8px!important;
    background: white!important;
    height: 37px!important;
    border: 1px solid #042E6B!important;
    font-size: 14px!important;
    color: #042E6B!important;
    min-width: 110px!important;
    padding: 0!important;
    justify-content: space-between!important;
    overflow: hidden!important;

    [class~="anticon"] {
        display: inline-flex !important;
        align-items: center!important;
    }
}
.icon {
    display: inline-flex!important;
    justify-content: center!important;
    flex: 0 0 30px!important;
    max-width: 30px!important;
    margin: 0!important;
    font-size: 22px!important;
    background-color: #042E6B!important;
    border-radius: 0!important;
    align-self: stretch!important;
    color: white!important;
}
.text {
    display: inline-flex;
    width: 100%;
    margin: 0!important;
    justify-content: center;
}
