.box {
  padding: 5px 15px;
}
.btn {
  display: flex !important;
}
.btnIcon {
  font-size: 22px;
}
.select {
  width: 100%;
}
