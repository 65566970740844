._ {
    display: inline-flex;
    transform: translateY(4px);
    cursor: pointer;
    width: 24px;
    height: 24px;

    & svg {
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: none;
    }
}