.files {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px -20px 0 -5px;
}
.fileBox {
  position: relative;
  padding: 0 5px;
  width: 147px;
  height: 186px;
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #E1E5ED;
  margin-right: 11px;
  margin-bottom: 20px;
}

.fileBoxUpload {
  border-style: dashed;
  padding: 0 !important;
}
.file {
  margin: 13px auto 4px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 86px;
  height: 105px;
  border-radius: 10px;
  border: 2px solid #042E6B;
  background: #FFF;
}
.file > div, .file > div > div, .file > div > div > div {
  height: 100%;
}
.fileRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fileRowH {
  flex-direction: row;
  column-gap: 10px;
}
.removeIcon {
  display: inline-block;
  position: absolute !important;
  top: -2px;
  right: -2px;
  width: 30px;
  height: 30px;
}
.removeIcon:hover {
  color: red;
}
.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 105px;
  border-radius: 8px;
  border: 1px solid transparent;
}
.pdfIcon * {
  cursor: zoom-in;
}
.upload {
  display: block;
  width: 100% !important;
  height: 100% !important;
}
.upload > div {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
}
.uploadText {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
