.wrap {
    margin-bottom: 20px;
}

.row {
    @media screen and (min-width: 800px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    @media screen and (min-width: 1200px) {
        flex-wrap: initial;
        column-gap: 20px;
    }
}

.col {
    margin-bottom: 20px;

    @media screen and (min-width: 800px) {
        flex: calc(50% - 10px);
        max-width: calc(50% - 10px);
    }

    @media screen and (min-width: 1200px) {
        flex: 1;
        max-width: initial;
    }
}

.table {
    border-radius: 10px;
    border: 1px solid #042E6B;
    background: #FFF;
    overflow: hidden;
}

.td {
    padding: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    border-top: 1px solid #042E6B;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.tableHeader {
    color: #042E6B;
    font-size: 20px;
    font-weight: 500;
    background: #E1E5ED;
    border: none;
}

.totalValue {
    padding: 12px 20px;
    display: block;
    text-align: center;
}

.label {
    font-size: 12px;
    display: block;
    width: 100%;
    color: #666;
}