._ {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    width: 100%;
}

.label {
    font-size: 15px;
    font-weight: 500;
    min-width: 100px;
}
.label:after {
    content: ':';
}

.content {
    width: 100%;
    position: relative;
}

.value {
    appearance: none;
    display: block;
    width: 100%;
    font-size: 16px;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.1);
}
.value:focus {
    outline: none;
}
.value:-webkit-autofill {
    box-shadow: 0 0 0 1000px #FFFFFF inset;
}
.value:disabled {
    border-color: transparent;
}

.centered {
    text-align: center;
}

.error {
    position: absolute;
    top: 100%;
    color: red;
    font-size: 12px;
}
