.formWrap {
    /*position: relative;*/
}

.infoCol {
    display: flex;
    flex-direction: column;
}
.infoCol:last-child {

}

.formItem [class~="ant-input"][disabled] {
    background: white!important;
}
.formItem [class~="ant-form-item-label"] {
    text-align: left!important;
}
.formItem label {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.formItem label::before {
    display: none!important;
}
.formItem.checkboxItem {
    align-content: flex-end;
    flex-grow: 1;
}

.checkboxField {
    border-radius: 2px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 9px;
}
.checkboxField.disabled {
    filter: blur(2px);
}
.checkboxField [class~="ant-checkbox-inner"] {
    transform: translateY(-2px);
}
.checkboxField span {
    color: black!important;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.selectField {
    width: 100%;
}
.selectField [class~="MuiSelect-select"],
.selectField [class~="MuiSelect-icon"] {
    transform: translateY(-6px);
}
.selectField fieldset {
    border: none!important;
    border-radius: initial!important;
    padding-left: 0!important;
    padding-right: 0!important;
}
.selectField fieldset legend {
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}
.selectField [class~="MuiSelect-select"] {
    padding: 0!important;
}

.selectField.autocompleteField [class~="ant-select-selector"] {
    border-color: transparent!important;
    box-shadow: none!important;
    padding: 0!important;
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
    outline: none!important;
}
.selectField.autocompleteField [class~="ant-select-selection-search-input"] {
    font-size: 16px!important;
}
.selectField.autocompleteField [class~="ant-select-selection-search"] {
    left: 0!important;
    right: 0!important;
}
.option.disabledOption {
    opacity: .4;
}

.inputField {
    font-size: 16px;
    padding: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.inputField.maskedField {
    border: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    box-shadow: none!important;
}


.actions {
    position: absolute;
    bottom: -60px;
    left: 12px;
    display: flex;
    gap: 24px;
    justify-content: initial!important;
}
.actions.editActions {
    left: 0;
    bottom: 30px;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}

.delete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
    height: 38px !important;
}

.formItem[class~="ant-form-item-has-error"] .inputField,
.formItem[class~="ant-form-item-has-error"] .inputField.maskedField,
.formItem[class~="ant-form-item-has-error"] .selectField fieldset legend,
.formItem[class~="ant-form-item-has-error"] .selectField.autocompleteField [class~="ant-select-selector"] {
    border-bottom-color: red !important;
}
