.wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 26px;
}

.box {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
}
.boxTitle {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.boxContent {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.row {

}
.rowHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
    padding-bottom: 6px;
    margin-bottom: 10px;
}
.rowHeadLabel {
    color: #002A77;
    font-family: Outfit, sans-serif;
    font-size: 12px;
}
.rowHeadDate {
    color: #002A77;
    text-align: right;
    font-family: Outfit, sans-serif;
    font-size: 14px;
}
.rowContent {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
}

.user {
    padding: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(50% - 24px);
}
.userIcon {
    border-radius: 8px;
    background: #042E6B;
    width: 111px;
    height: 111px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userInfo {
    padding-left: 20px;
}
.userProps {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}
.userProp {
    display: flex;
}
.userPropLabel {
    min-width: 75px;
    max-width: 75px;
}
.userPropValue {
    width: 100%;
    padding-left: 20px;
}

.try {
    padding: 17px 33px;
    width: 50%;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.history {
    padding: 17px 33px 33px;
    min-width: 50%;
    max-width: 50%;
}

.tasks {
    padding: 17px 33px 33px;
    min-width: 50%;
    max-width: 50%;
}

.helpIcon {
    display: none;
    padding-left: 8px;
}
label[class~="MuiInputLabel-shrink"] .helpIcon {
    display: inline-block;
}
