.wrap [class~="InitialCard-left"] {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 22px);
}

.cardTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.cardTitle:not(:hover) span {
    color: #042E6B!important;
}

.title {
    margin-top: 12px;
    margin-bottom: 10px;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.link {
    background-color: #E1E5ED;
    border-radius: 26px;
    margin-bottom: 20px;
}
.link span {
    font-size: 14px;
}

.top {
    flex: 1;
}

.info {}
.infoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 10px;
}
.infoRow:first-child {
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
}
.infoIcon {
    font-size: 20px;
    color: #042E6B;
}
.infoIcon.error {
    color: #FF0A0A;
}

.footer {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer * {
    margin: 0 3px;
}
