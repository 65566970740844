.icon {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;
    position: relative;
}
.closeIcon {
    margin-top: 14px;
    color: white;
}
.modal {
    font-family: Outfit, sans-serif;
    top: 15px;
}
.modal [class~="ant-form-item"] {
    margin-bottom: 0;
}
.row {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.rowFlex {
    margin-bottom: 15px;
    display: flex;
}
.fieldSelect label {

}
.label, .labelCol {
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}
.fieldCol {
    flex: 1;
}
.labelFull {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    width: 100%;
    flex: 0 0 100%;
}
.centered {
    width: 100%;
    display: flex;
    justify-content: center;
}
.files {
    padding: 0;
    border: none;
}
.textArea {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #002A77;
    background-color: #EFEFEF !important;
    padding: 10px 16px;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.btn {
    width: 155px;
    text-transform: none;
}
