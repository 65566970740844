.page {
  padding: 30px 44px;
}

.form {
  padding: 0!important;
}

.wrapper:last-child {
  padding-bottom: 24px;
}

@media all and (min-width: 1200px) {
  .wrapper:first-child {
    padding-bottom: 24px;
  }
}

.wrapperColFirst, .wrapperColLast {
  margin-bottom: 24px;
}

@media all and (min-width: 1200px) {
  .wrapperColFirst, .wrapperColLast {
    margin-bottom: 0;
  }
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 26px 0;
  margin-bottom: 25px;
  height: 100%;
}

.blockHeader {
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}

.blockMiddle {
  display: flex;
  justify-content: space-between;
}

.blockMiddleIcon {
  flex: 0 0 80px;
  max-width: 80px;
}

.blockMiddleInfo {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}

.blockContent {
  padding-bottom: 0;
}

.formRow {
  margin-top: 0!important;
  min-height: initial;
}

.formLabelCol {
  font-size: 18px;
  font-weight: 400;
}

.mainFormRow  {
  margin-bottom: 12px;
  min-height: 24px;
}

.linkFormRow {
  margin-bottom: 24px;
}

.linkLabel {
  margin-bottom: 14px;
}

.messageRow {
  margin-bottom: 16px;
}

@media all and (min-width: 1200px) {
  .messageRow {
    margin-bottom: 0;
  }
}

.formRow div[class~="MuiInputBase-root"] {
  margin-top: 0!important;
  min-height: initial;
}

.formTitleCol {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
  display: none!important;
}

.formValueCol p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
  display: initial!important;
  color: red!important;
}

.formLinkValue p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
  text-align: center;
}

.formValueCol input[class~="MuiInput-input"] {
  padding: 0!important;
  color: #000!important;
  -webkit-text-fill-color: #000!important;
  font-family: Outfit, sans-serif;
  font-size: 18px!important;
  font-weight: 400!important;
  line-height: normal!important;
}
.formValueCol.formInfoValue input[class~="MuiInput-input"],
.formValueCol.formInfoValue div[class~="MuiInputBase-root"],
.formValueCol.formInfoValue span[class~="MuiTypography-root"] {
  font-size: 18px!important;
}
.formValueCol.formFooterValue input[class~="MuiInput-input"] {
  font-size: 16px!important;
}

.formValueColTextarea textarea[class~="MuiInput-input"] {
  background-color: #eee;
  border-radius: 8px;
  border: 1px solid #002A77;
  padding: 9px 13px;
  min-height: 70px!important;
  color: black!important;
  -webkit-text-fill-color: black!important;
}

.formValueColTextarea textarea[class~="MuiInput-input"]:disabled {
  cursor: not-allowed!important;
}

.editor div[class~="tox-tinymce"] {
  border-radius: 8px!important;
  border: 1px solid #042E6B!important;
}

.editor div[class~="tox-editor-header"] {
  border-bottom: 1px solid #000!important;
}

.editor iframe {
  background-color: #eee!important;
}

.editor div[class~="tox-statusbar"] {
  display: none!important;
}

.actions {
  padding: 0!important;
  min-height: initial!important;
  justify-content: flex-start!important;
  align-items: center!important;
  background-color: transparent!important;
}

.action {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.actionSave {
  color: #FFF!important;
  background: #042E6B!important;
}

.actionIcon {
  margin-right: 10px!important;
  font-size: 18px!important;
}
