.contractInitPage {
    [class~="ip-header-left"],
    [class~="ip-header-right"] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.exportBtn {
    padding: 8px 11px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 37px;
    border-radius: 8px!important;
    border: 1px solid #042E6B;
    background: #FFF!important;
    text-transform: initial!important;
}
.code {
    text-align: right;
    padding-top: 10px;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 1;
}
.gridList {
    th[class~="MuiTableCell-paddingCheckbox"] {
        min-width: 50px!important;
        max-width: 50px!important;
        width: 50px!important;
    }
}
