.cardWrapper {
  min-height: 200px;
}
.cardWrapper:not(:hover) {
  border-color: #042E6B !important;
}
.cardWrapper [class~="InitialCard-left"] {
  font-size: 16px;
}

.cardWrapper:hover .headerIcon {
  color: #40a9ff;
}

.headerIcon {
  color: #042E6B;
  font-size: 28px;
}
.wrap {
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.titleBlock {
  color: #002A77;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-bottom: 6px;
}

.status {
  padding: 4px 12px;
  border-radius: 10px;
  background-color: #bbb;
  color: #FFF;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;

  &[class~="pending"], &[class~="high"] {
    background-color: rgb(253, 73, 45);
  }

  &[class~="complete"], &[class~="low"] {
    background-color: rgb(137, 210, 86);
  }
}

/* FILE */
.documentBlank {
  margin-right: 10px;
  width: 86px;
  height: 105px;
  border: 2px dashed #042E6B;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #042E6B;
  font-weight: bold;
}

.document {
  margin-right: 10px;
  width: 86px;
  height: 105px;
  border: 2px solid #042E6B;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.documentCounter {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #042E6B;
  color: white;
  font-size: 10px;
  font-weight: 500;
}
