.content {
	display: flex;
	width: 100%;
	height: calc(100% - 20px);
	min-height: 250px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}
