.row {
  margin-top: 18px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  min-height: 24px;
  color: #000;
  font-family: Outfit, sans-serif;
  align-items: center;
}

.title {
  font-size: 15px;
  font-weight: 500;
}

/* Helpers styles */
.children p[class~="MuiFormHelperText-root"] {
  color: #C2C2C2;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
}

/* Labels styles */
.children label[class~="MuiFormLabel-root"] {
  color: rgba(0, 42, 119, 0.30);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

/* Placeholders styles */
.children textarea::-webkit-input-placeholder {
  color: rgba(0, 42, 119, 0.30);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.children textarea::-moz-placeholder {
  color: rgba(0, 42, 119, 0.30);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.children textarea:-moz-placeholder {
  color: rgba(0, 42, 119, 0.30);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.children textarea:-ms-input-placeholder {
  color: rgba(0, 42, 119, 0.30);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
