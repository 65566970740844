.wrap {
    padding-top: 24px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding-bottom: 6px;

    &:last-child {
        margin: 0;
    }
}

.label {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
}

.field {
    width: 60px;
}
.fieldText {
    width: initial;
    flex: 1 auto;
}
.select {
    width: 100%;
    min-width: 60px!important;

    [class~="MuiInput-root"] {
        margin: 0!important;
        text-align: center!important;
    }

    [class~="MuiInput-input"] {
        padding: 0 14px 0 0!important;
        color: #000!important;
        font-family: Outfit, sans-serif!important;
        font-size: 13px!important;
        font-weight: 400!important;
        line-height: normal!important;
    }
}
.labelText {
    padding-bottom: 20px;
}
.text {
    [class~="MuiInputBase-multiline"]::before, [class~="MuiInputBase-multiline"]::after {
        display: none!important;
    }
    textarea {
        color: #000!important;
        font-family: Outfit, sans-serif!important;
        font-size: 13px!important;
        font-weight: 400!important;
        /*line-height: normal!important;*/
    }
}
