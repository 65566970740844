#CallForm #status, #CallForm #risk {
  color: transparent;
}

#CallForm .CallFormBadge {
  margin-top: -28px;
}

.empty .MuiInputBase-input {
  color: #E1E5ED !important;
}

