.totalPercent {
  position: fixed;
  right: 50px;
  top: 80px;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .7);
  z-index: 100;
  font-size: 12px;
  font-weight: 500;
  color: rgb(211, 47, 47);
  border: 1px solid rgb(211, 47, 47);
  &.valid {
    color: #042E6B;
    border: 1px solid #042E6B;
  }
}
.totalPercentVal {
  font-weight: 900;
}
.variablesView {
  &.wrap {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    height: 100%;
    color: #000;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 992px) {
      flex-direction: initial;
    }
  }

  .col {
    padding: 15px 22px;

    &:first-child {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 992px) {
        flex: 0 0 66.66%;
        max-width: 66.66%;
      }

      @media screen and (min-width: 1200px) {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }

    &:last-child {
      border-top: 1px dashed #042E6B;

      @media screen and (min-width: 992px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        border-top: none;
        border-left: 1px dashed #042E6B;
        padding-top: 46px;
      }

      @media screen and (min-width: 1200px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .sections {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .section {
      flex: 0 0 100%;
      max-width: 100%;

      @media screen and (min-width: 800px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media screen and (min-width: 1200px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }

  .section {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 24px;
  }

  .sectionCounter {
    width: 80%;
  }

  .sectionCounterValue {
    font-size: 52px;
    font-weight: 400;
    text-align: center;
  }

  .sectionCounterLabel {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 24px;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid #042E6B;
  }

  .sectionPercent {
    font-size: 20px;
    font-weight: 500;

    &.empty {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .actions {
    margin-top: 32px;
    border: 1px solid #042E6B;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;

    @media screen and (min-width: 800px) {
      flex-direction: initial;
      justify-content: space-between;
      align-items: center;
    }
  }

  .actionTotal {
    font-size: 20px;
    font-weight: 700;
    color: #042E6B;
    padding-top: 20px;

    @media screen and (min-width: 800px) {
      padding-top: 0;
    }
  }
}

.actions {
  padding-left: 92px;
  display: flex;
  gap: 25px;
  margin: 30px 0;
}

.addSection {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #042E6B;
  color: #042E6B;
  padding: 0 20px;
  min-width: 250px;
  height: 40px;

  &:hover {
    color: #042E6B;
    border: 1px solid #042E6B;
    background: #F0F3FB;
  }

  &:focus {
    color: #042E6B;
    border: 1px solid #042E6B;
    background: #F0F3FB;
  }
}

.save {
  background: #042E6B;
  border-radius: 8px;
  border: 1px solid #042E6B;
  color: #fff;
  padding: 0 20px;
  min-width: 160px;
  height: 40px;

  &:hover {
    color: #fff;
    background: #042E6B;
    border: 1px solid #042E6B;
  }

  &:focus {
    color: #fff;
    background: #042E6B;
    border: 1px solid #042E6B;
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.section {
  position: relative;

  &:not(:first-child) {
    &::before {
      position: absolute;
      top: 25px;
      left: 92px;
      right: 53px;
      display: block;
      content: '';
      border-top: 2px solid #042E6B;
    }
  }
}

.variables {
  padding-top: 30px !important;
}

.errors {
  color: red;
  padding-left: 92px;
  height: 22px;
}
