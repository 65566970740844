._ {
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-size: 11px;
    padding: 3px 15px;
}

.isMinimal {
    display: inline-block;
}
