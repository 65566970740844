._ {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 832px;
  z-index: 100;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #042e6b0d;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 82px 60px 100px 80px;
  border-left: 1px solid #042E6B;
}

.close {
  min-width: 35px;
  min-height: 35px;
  background: url("/public/icons/close.svg") no-repeat top left;
  margin-left: auto;
  cursor: pointer;
}

.title {
  text-align: center;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  margin-top: 60px;
}

.uploadBox {
  aspect-ratio: 4/3;
  width: 357px;
  min-height: 232px;
  height: 232px;
  margin: 35px auto;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.uploadedBox {
  aspect-ratio: 4/3;
  width: 357px;
  min-height: 232px;
  height: 232px;
  margin: 35px auto;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 8px;
  border: 2px dashed #042E6B;
  background: #E1E5ED;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.fileError {
  text-align: center;
  font-size: 12px;
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.row label {
  width: 110px !important;
  min-width: 110px !important;
}

.actions {
  margin-top: 50px;
  padding-left: 68px;
}

.submit {
  border-radius: 8px;
  color: #E1E5ED;
  font-size: 14px;
  padding: 11px 115px !important;
  background: #042E6B !important;
  text-transform: initial !important;
}

.iconPlus {
  color: #042E6B;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
