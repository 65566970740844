._ {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    width: 100%;
}

.label {
    font-size: 15px;
    font-weight: 500;
    min-width: 100px;
}
.label:after {
    content: ':';
}

.form {
    border: none !important;
    width: 100%;
}
.form > div[class$="MuiSelect-root"]:before {
    border-bottom: 1px solid rgba(0, 42, 119, 0.1);
}
.form > div[class$="MuiSelect-root"]:hover:before {
    border-bottom: 1px solid !important;
}
.centered {
    text-align: center;
}

.error {
    color: red;
    font-size: 12px;
}
