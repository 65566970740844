.content {
    font-family: Outfit, sans-serif;
    color: #000;
    [class~="MuiCardContent-root"] {
        margin-top: 0 !important;
    }
}
.toolbar {
    justify-content: space-between !important;
    padding: 15px 0 0 !important;
}
.actionsLeft {
    padding-top: 22px;
}
.actionsRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 22px;
}
.action {
    width: 200px!important;
    height: 38px!important;
    color: white!important;
    border-radius: 8px!important;
    text-align: center!important;
    text-transform: initial!important;
    font-family: Outfit, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal!important;
}

.actionIcon {
    margin-right: 10px!important;
    font-size: 18px!important;
}

.cancelBtn {
    margin: 0!important;
    border-color: #D72B2C!important;
    color: #D72B2C!important;
    background-color: transparent!important;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2)!important;
    }
}
