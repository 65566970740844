.block {
  padding-top: 22px;
}

.table {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
}

.header {
  background: #042E6B;
  color: white;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.row {
  border-top: 1px solid #042E6B;
  display: flex;
}

.col {
  padding: 0 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid #042E6B;
  flex: 0 0 25%;
  max-width: 25%;
  min-height: 34px;
  display: flex;
  align-items: center;
}

.header .col {
  color: #FFF;
  justify-content: center;
  border-left: 1px solid #F0F3FB;
}

.col:first-child {
  border: none;
}

.body .col:last-child {
  display: initial;
  padding: 0;
}

.inputWrap {
  display: flex;
}

.input {
  width: 100% !important;
}

.input input {
  padding: 0;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
}

.innerRow {
  display: flex;
  height: 100%;
}

.innerCol {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCol:last-child {
  padding: 9px 15px;
  flex: 0 0 50%;
  max-width: 50%;
  justify-content: initial;
}

.innerCol:last-child {
  border-left: 1px solid #042E6B;
}

.badge {
  border-radius: 10px;
  padding: 3px 16px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
}

.badge.access {
  border: 1px solid #52C41A;
  background: #B7EB8F;
  color: #52C41A;
}

.badge.error {
  border: 1px solid #ff0000;
  background: #f55;
  color: #fff;
}

.colored {
  background: #F0F3FB;
}
