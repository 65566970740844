.box {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: zoom-in;
}

.download {
  display: block;
  z-index: 100;
  position: absolute;
  width: 45px;
  height: 48px;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -22px;
  cursor: pointer;
}

.icon {
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 2px;
  width: 36px;
  height: 36px;
}
