.content {
    margin-top: 25px;
}

.info {
    background: #FFFFFF;
    padding: 24px 32px;
    border-radius: 8px;
    border: 1px solid #042E6B;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.infoBody {
    background: #E1E5ED;
    padding: 20px 31px;
    min-width: 504px;
    border-radius: 8px;
    border: 1px solid #042E6B;
}

.infoHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.infoTitle {
    font-size: 20px;
    font-weight: 500;
}

.infoSwitcher {
    display: flex;
    align-items: center;
    gap: 12px;
}

.infoSwitcher button {
    background: transparent;
    border: 1px solid #000;
    width: 63px;
    height: 18px;
}
.infoSwitcher button:before {
    background: transparent;
    border: 1px solid #000;
}

.infoSwitcher [class~="ant-switch-handle"] {
    width: 24px;
    height: 16px;
    top: 0;
    left: 0;
}

.infoSwitcher [class~="ant-switch-handle"]::before {
    background: #002A77;
}

.infoSwitcher [class~="ant-switch-checked"] [class~="ant-switch-handle"] {
    left: calc(100% - 16px - 8px);
}

.infoProps {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.infoProps [class~="ant-picker-input"] {
    border-bottom: none !important;
}

.infoProps [class~="ant-select-selector"] {
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
}

.infoProps [class~="ant-picker-focused"] {
    box-shadow: none !important;
}

.aside {
    width: 100%;
}

.asideRow {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.asideRow [class~="ant-form-item-control-input-content"] {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.asideLabel {
    font-size: 15px;
    min-width: 160px;
}
.asideLabel:after {
    content: ':';
}

.asideValue {
    font-size: 16px;
    width: 100%;
    max-width: 500px;
}

.list {
    background: #FFFFFF;
    padding: 25px 32px;
    border: 1px solid #042E6B;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
    width: 100%;
}

.aside {
    display: flex;
    flex-direction: column;
}

.asideProps {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-top: 24px;
}

.asideLabel {
    font-weight: 500;
    font-size: 15px;
}

.asideValue {
    font-size: 16px;
}

.asideActions {
    height: 38px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 25px;
}

.row {
    margin-bottom: 0;
    align-items: center;
    gap: 25px;
}

.row label {
    font-size: 15px;
    font-weight: 500;
    min-width: 124px;
}

.row [class~="ant-select-selector"] {
    background: transparent !important;
    padding: 0 !important;
}

.row [class~="ant-input-status-error"] {
    background: transparent !important;
    box-shadow: none !important;
}

.row [class~="ant-form-item-explain"] {
    position: absolute !important;
    font-size: 12px !important;
    line-height: 12px;
}

.rowAside label {
    min-width: 200px;
}

.rowAside [class~="ant-input"] {
    border: none !important;
    border-bottom: 1px solid rgba(0, 42, 119, 0.1) !important;
    width: 100% !important;
    padding-left: 0;
}

.rowAside [class~="ant-input"]:focus {
    box-shadow: none;
}

.input {
    border: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.1) !important;
    width: 100% !important;
    min-height: 24px;
    display: block;
    background-color: transparent !important;
    padding: 0;
}

.input:focus {
    outline: none;
    box-shadow: none;
}

.submit {
    padding: 10px 50px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    display: inline-flex;
    gap: 15px;
    box-shadow: none !important;
}

.remove {
    padding: 10px 50px !important;
    background: transparent !important;
    color: #D72B2C !important;
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    box-shadow: none !important;
}
