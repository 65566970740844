.wrapper {
  padding: 10px 18px 10px !important;
}

.row {
  margin: 0 !important;
}

/* company */
.company {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  margin: 3px 20px 0;
}

.companySwitch, .companySwitchChecked {
  vertical-align: top;
  margin-top: 4px;
  height: 18px;
  width: 63px;
  background: #E1E5ED;
  border: 1px solid #002A77;
  opacity: 1;
}

.companySwitch > div, .companySwitchChecked > div {
  left: 1px !important;
  top: 1px;
  width: 26px;
  box-shadow: none;
  height: 14px !important;
}

.company .companySwitchChecked > div {
  left: auto !important;
  right: 1px;
}

.companySwitchChecked > div::before, .companySwitch > div::before {
  background-color: #002A77;
}
