.table {
    border-collapse: separate!important;
    overflow: hidden;
    border-radius: 10px!important;
    border-spacing: 0!important;
    margin-top: 25px;
    width: 100%;
}

.th {
    min-width: 40px;
    padding: 5px;
    border-right: 1px solid #042E6B;
    border-bottom: 1px solid #042E6B;
    background: #E1E5ED;
    text-align: center;
    vertical-align: middle;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.th:first-child {
    border-radius: 10px 0 0 0;
}

.th:last-child {
    border-radius: 0 10px 0 0;
}

.td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid #042E6B;
    border-bottom: 1px solid #042E6B;
    color: #74706B;
    font-family: Outfit, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.td:last-child {
    border-right: none;
}

.tdImg, .thImg {
    min-width: 70px;
}

.total {
}

.total .td {
    border-bottom: none;
    background-color: #042E6B;
    color: white;
    height: 47px;
    border-right: 1px solid white;
}

.total .td:first-child {
    border-radius: 0 0 0 8px;
}

.total .td:last-child {
    border-radius: 0 0 8px 0;
    border-right: none;
}

.link {
    color: #74706B;;
    font-size: 10px;
    text-decoration: underline;
}

.link:hover {
    color: inherit;
}

.image {
    max-height: 40px;
}

.price {
    margin-top: 5px;
    color: #333;
    font-size: 16px;
}

.noimageIcon {
    font-size: 32px;
    color: #AAA;
}

.noimg {
    width: 35px;
    height: 35px;
    background-color: #9E9E9E;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    line-height: 0;
}
