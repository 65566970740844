.wrapper {
  padding-bottom: 40px;
  padding-left: 44px;
}

.page div[class~="RaCreate-main"] {
  margin-top: 30px;
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 32px;
  margin-bottom: 25px;
}

.listRow > div[class~="MuiBox-root"] {
  margin-right: 0;
}

.form {
  padding: 0!important;
}

.row {
  display: flex;
  width: 100%;
}

.colFirst {
  flex: 0 0 calc(100% - 360px);
  max-width: calc(100% - 360px);
}

.test {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.testBtn {
  border-radius: 8px;
  border: 1px solid #042E6B;
  color: #042E6B;
  background: #FFF;
  width: 170px;
  height: 38px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: initial!important;
  flex: 0 0 170px;
}

.testAlert {
  flex: 0 0 calc(100% - 200px);
  max-width: calc(100% - 200px);
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.colLast {
  flex: 0 0 360px;
  max-width: 360px;
}

.message div[class~="MuiInputBase-root"]::before,
.message div[class~="MuiInputBase-root"]::after {
  border-bottom: none!important;
}

.message div[class~="MuiInputBase-root"] {
  border-radius: 8px;
  border: 1px solid #002A77;
  background: #EEE;
  margin-top: 15px;
  padding: 14px 20px;
  margin-bottom: 4px;
}

.message.error div[class~="MuiInputBase-root"] {
  border-color: red;
}

.listTitle {
  color: #000!important;
  font-size: 20px!important;
  font-weight: 600!important;
  line-height: normal!important;
  font-family: Outfit, sans-serif;
}

.listWrapper {
  margin-top: 0!important;
  overflow-x: initial!important;
}

.list div[class~="RaList-main"] {
  padding: 0;
}

.list div[class~="RaList-content"] {
  box-shadow: initial!important;
  border-radius: 8px;
}

.list th[class~="column-status"],
.list td[class~="column-status"] {
  width: 100px!important;
  min-width: 100px!important;
  max-width: 100px!important;
}
.list th[class~="column-stateId"],
.list td[class~="column-stateId"] {
  width: initial!important;
  max-width: initial!important;
  min-width: initial!important;
}

.actionsToolbar {
  padding: 0!important;
  min-height: initial!important;
  justify-content: flex-start!important;
  align-items: center!important;
}

.action {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.action[class~="Mui-disabled"] {
  cursor: not-allowed!important;
}

.startBtn {
  background: #042E6B!important;
  color: #E1E5ED!important;
  margin-right: 24px!important;
}

.saveBtn {
  color: #042E6B!important;
  background-color: transparent!important;
}

.actionIcon {
  margin-right: 10px!important;
  font-size: 18px!important;
}
