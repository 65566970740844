.list {}

.grid table[class~="MuiTable-root"] th,
.grid table[class~="MuiTable-root"] td {
  min-width: 175px;
  max-width: 175px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-company"],
.grid table[class~="MuiTable-root"] td[class~="column-company"],
.grid table[class~="MuiTable-root"] th[class~="column-fullName"],
.grid table[class~="MuiTable-root"] td[class~="column-fullName"] {
  text-align: initial;
  min-width: auto;
  width: auto;
  max-width: initial;
}

.grid table[class~="MuiTable-root"] th[class~="column-phone"],
.grid table[class~="MuiTable-root"] td[class~="column-phone"],
.grid table[class~="MuiTable-root"] th[class~="column-email"],
.grid table[class~="MuiTable-root"] td[class~="column-email"] {
  min-width: 140px;
  max-width: 140px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-visitedAt"],
.grid table[class~="MuiTable-root"] td[class~="column-visitedAt"],
.grid table[class~="MuiTable-root"] th[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] td[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] th[class~="column-visited"],
.grid table[class~="MuiTable-root"] td[class~="column-visited"] {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-webinarId"],
.grid table[class~="MuiTable-root"] td[class~="column-webinarId"] {
  min-width: 120px;
  max-width: 120px;
  text-align: center;
}

.btn {
  border-radius: 5px;
  color: #FFF;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  min-width: 100px;
  min-height: 20px;
}
.btnLink {
  background: #002A77;
}
.btnLink:hover, .btnLink:active, .btnLink:visited, .btnLink:focus {
  background: #002A77;
  opacity: .85;
}
