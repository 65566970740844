.cardWrapper {
  position: relative;
}

.card {

}

.box {
  margin-top: 15px;
  font-family: Outfit, sans-serif;
  color: #272727;
  max-width: 760px;
  position: initial;
}

@media all and (min-width: 2000px) {
  .box {
    max-width: 1560px;
  }
}

.carousel {
  position: initial;
}