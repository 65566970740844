.box {
  height: 100% !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-in;
}

.download {
  display: block;
  z-index: 100;
  position: absolute;
  width: 45px;
  height: 48px !important;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -22px;
  cursor: pointer;
}

.preview {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 40px;
  height: 25px !important;
}
.preview img {
  max-width: 100%;
  max-height: 100%;
}
