/* Cards */
.risk {
  min-height: 301px;
}
.infoline {
  margin-top: 25px;
  align-items: stretch;
}

.score {
  padding: 12px 15px 15px;
}

.scoreFooter {
  padding-top: 20px;
}

.scoreBtn {
  width: 140px;
  height: 35px;
  margin: 0 5px 4px!important;
  text-transform: initial!important;
}

.taskManagerWidget {
  min-height: 301px;
  margin-bottom: 0!important;
  display: flex;
  flex-direction: column;
}
.taskManagerContent {
  flex-grow: 1;
}
.taskManagerWidget [class~="RaLink-link"] {
  color: #002A77;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.taskManagerWidget [class~="actions"] {
  text-align: center;
}
.taskManagerWidget [class~="actions"] button {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #042E6B;
  color: white;
}
.taskManagerWidget [class~="actions"] button:hover,
.taskManagerWidget [class~="actions"] button:active,
.taskManagerWidget [class~="actions"] button:focus,
.taskManagerWidget [class~="actions"] button:visited {
  background: #0069c0;
  border: 1px solid #0069c0;
}
