._ {
    min-height: 32px;
    line-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background: #ededed;
    }
}