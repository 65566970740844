.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 7px;
  border-radius: 100px;
  border: 1px solid #042E6B;
  background: #E1E5ED;
}

.col {
  line-height: 0;
}

.colFirst, .colLast {
  flex: 0 0 40px;
  max-width: 40px;
}

.colMiddle {
  flex: 1 1 auto;
}

.input div[class~="MuiInputBase-root"]::after,
.input div[class~="MuiInputBase-root"]::before {
  display: none!important;
}

.input input {
  text-align: center!important;
  padding: 0 10px;
  color: #000!important;
  font-family: Outfit, sans-serif!important;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: normal!important;
}

.clickable {
  cursor: pointer;
}

.clickable:hover svg {
  opacity: 0.7;
}
