.icon {
  width: 27.5px;
  height: 27.5px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
}

.counter {
  position: absolute;
  top: -5px;
  right: -10px;
  color: #fff;
  background: red;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
}

.item {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.item::after {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: red;
  content: '';
}
.item:hover {
  background: #fafafa;
}

.empty {
  margin-top: 40px;
  color: #042E6B;
}

.content {
  display: flex;
  gap: 4px;
  flex-direction: row;
}

.count {
}

.count::before {
  content: '(';
}

.count::after {
  content: ')';
}
