.upload {
  width: 86px;
  height: 105px;
  font-family: Outfit, sans-serif;
  color: #042E6B;
}

.upload > div {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
  border: 2px dashed #042E6B !important;
  background: #E1E5ED !important;
}

.iconPlus {
  color: #042E6B;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.iconLoading {
  font-size: 20px;
  color: #042E6B;
}
