.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.download {
  display: inline-block;
  z-index: 100;
  position: absolute;
  top: 50%;
  margin-top: -23px;
  left: 50%;
  margin-left: -24px;
}
.downloadIcon {
  color: gray;
  font-size: 30px;
}
.downloadIcon:hover {
  color: #171725;
}
.skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
