.block {}

.table {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
}

.header {
  background: #042E6B;
  color: white;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.headerRow {
  display: flex;
  justify-content: center;
}

.headerCol {
  padding: 9px;
  flex: 1;
}

.row {
  position: relative;
  border-top: 1px solid #042E6B;
  display: flex;
}

.btnRemoveRow {
  position: absolute !important;
  right: 5px;
  top: 5px;
  border: 1px solid #042E6B !important;
  border-radius: 8px !important;
  min-width: auto !important;
  padding: 0 10px !important;
}

.rowColored {
  background: #F0F3FB;
}

.col {
  display: flex;
  align-items: center;
  min-height: 34px;
  padding: 0 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  line-height: 0.95em;
  font-weight: 500;
  border-left: 1px solid #042E6B;
  flex: 0 0 50%;
  max-width: 50%;
}

.col:first-child {
  border-left: none;
}

.colTitle {
  background-color: white;
}

.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 2px;
  width: 26px !important;
  height: 26px !important;
}

.tooltip svg {
  font-size: 18px;
  color: #aeb8c2;
}

.separator {
  min-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-family: Outfit, sans-serif;
  background-color: #042E6B !important;
}

.input {
  width: 100% !important;
  background: transparent !important;
  color: #042E6B !important;
}

.input input {
  padding: 0;
  color: #042E6B !important;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
}

.btnAddRow {
  margin-top: 15px !important;
  border: 1px solid #042E6B !important;
  border-radius: 8px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


