.grid table[class~="MuiTable-root"] th[class~="column-tag"],
.grid table[class~="MuiTable-root"] td[class~="column-tag"],
.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
    min-width: 50px;
    max-width: 50px;
    width: 50px;
}
.grid table[class~="MuiTable-root"] th[class~="column-question"],
.grid table[class~="MuiTable-root"] td[class~="column-question"],
.grid table[class~="MuiTable-root"] th[class~="column-answer"],
.grid table[class~="MuiTable-root"] td[class~="column-answer"] {
    max-width: 600px;
}

.page [class~="RaCreate-card"],
.page [class~="RaEdit-card"] {
    box-shadow: initial!important;
}

.toolbar {
    padding: 15px 0 0 !important;
}
.toolbar .removeBtn {
    order: initial!important;
}
.toolbar .viewBtn {
    color: #002A77!important;
}
.viewToolbar {
    width: 100% !important;
    flex-direction: column!important;
    align-items: initial!important;
    @media screen and (min-width: 900px) {
        flex-direction: row!important;
        align-items: center!important;
    }
}
.viewToolbar .viewBtn {
    margin-left: 0!important;
}
.viewToolbar.withNaw {
    justify-content: space-between !important;
}
.viewToolbar .nextBtn {
    margin-top: 10px;
    @media screen and (min-width: 900px) {
        margin-top: 0;
        margin-left: 24px!important;
    }
}
.viewToolbar .rightBtns {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 900px) {
        flex-direction: row;
        padding-top: 0;
    }
}
.returnBtn {
    text-transform: none;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
}
.ellipsis {
    display: inline-block;
    width: 100%; /* ширина блока */
    white-space: nowrap; /* предотвращаем перенос текста */
    overflow: hidden; /* скрываем текст, выходящий за пределы блока */
    text-overflow: ellipsis; /* добавляем троеточие в конце */
}
