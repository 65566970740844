.section {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.section.active {
  border-bottom: 1px solid #005F39;
}

.input {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  padding-left: 0;
}

.inputIcon {
  color: #D9D9D9;
}

.input span[class~="ant-input-prefix"] {
  margin-right: 14px;
}

.dropdownBlock {
  padding: 4px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
  position: absolute;
  left: 0;
  top: 38px;
  z-index: -10;
  opacity: 0;
}

.dropdownBlock.active {
  z-index: 10;
  opacity: 1;
}

.dropdownValues {
  display: flex;
}

.dropdownValuesList {
  height: 206px;
  width: 50px;
  overflow-y: scroll;
  padding: 4px;
}

.dropdownValuesList.first {
  border-right: 1px solid #eee;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.item.active {
  background-color: #e6f4ff;
}

.item:hover {
  cursor: pointer;
}

.dropdownActions {
  padding-top: 4px;
  padding-right: 4px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}
