.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"],
.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
    min-width: 50px;
    max-width: 50px;
    width: 50px;
}

.page [class~="RaCreate-card"],
.page [class~="RaEdit-card"] {
    box-shadow: initial!important;
}

.toolbar {
    padding: 15px 0 0 !important;
    gap: 15px !important;
}
.toolbar .viewBtn {
    color: #002A77!important;
    @media all and (min-width: 1024px) {
        margin-left: 15px!important;
    }
}
.tag {
    margin-bottom: 4px;
}
.link {
    color: #002a77!important;
    text-decoration: underline;
}
