.switcher {
    padding-top: 30px;
}
.switcher label {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
}

.passwordCard {
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.password {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    padding: 0 20px 20px;
    margin-top: 20px;
}
