.content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.questions {
    display: flex;
    flex-wrap: wrap;
    gap: 76px;
    margin-top: 27px;
}
.question {
    width: calc(100% / 2 - 40px);
}
.questionHead {
    display: flex;
    width: 100%;
    align-content: center;
    align-items: center;
    gap: 24px;
}
.questionNum {
    border-radius: 50%;
    background: #042E6B;
    min-width: 34px;
    width: 34px;
    height: 34px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
}
.questionName {
    color: #000;
    font-size: 18px;
}
.questionIcon {
    margin-left: auto;
}
.questionIcon svg {
    width: 34px;
    min-width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
}
.description {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    margin-top: 12px;
}

.answers {
    padding-left: 58px;
    margin-top: 20px;
}
.answer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #E1E5ED;
    border-left: 1px solid #042E6B;
    border-right: 1px solid #042E6B;
    border-bottom: 1px solid #042E6B;
}
.answer:first-child {
    border-top: 1px solid #042E6B;
    border-radius: 10px 10px 0 0;
}
.answer:last-child {
    border-radius: 0 0 10px 10px;
}
.answer:not(:last-child) {
}
.answerLabel {
    min-width: 242px;
    max-width: 242px;
    padding: 11px 19px 9px;
    border-right: 1px solid #042E6B;
}
.answerValue {
    padding: 11px 19px 9px;
    width: 100%;
}

.creditReports {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.creditReport {
    border-radius: 10px;
    border: 1px solid #042E6B;
    background: #FFF;
    width: calc(100% / 2 - 13px);
    padding: 18px 21px;
}
.creditReportHead {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.creditReportTitle {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}
.creditReportStatus {
    display: inline-flex;
    align-items: center;
    gap: 13px;
}
.creditReportStatusLabel {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}
.creditReportFiles {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.creditReportFile {
    border: 2px solid #042E6B;
    appearance: none;
    border-radius: 10px;
    background: #e1e5ed;
    width: calc(100% / 3 - 13px);
    min-height: 166px;
    aspect-ratio: 4 / 5;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.creditReportFileContent {
    background: #fff;
    width: 85px;
    height: 105px;
    border-radius: 10px;
    border: 2px solid #042E6B;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: auto;
    margin-top: auto;
    overflow: hidden;
}
.creditReportFileIcon {
    position: relative;
    top: 5px;
}
.creditReportFilePdf {}
.creditReportFileClose {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
}
.creditReportUpload {
    appearance: none;
    border-radius: 8px !important;
    border: 2px dashed #042E6B;
    background: #E1E5ED;
    width: 100%;
    min-height: 166px;
    aspect-ratio: 4 / 5;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-weight: 600;
    cursor: pointer;
}
.creditReportUpload div, .creditReportUpload span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.creditReportUpload .uploadText {
    font-size: 35px;
    color: #042E6B;
}
.creditReportFileStatus {
    background: #042E6B;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding: 8px;
    text-align: center;
}

.creditReportVerification {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    min-width: calc(100% / 3);
}
.creditReportVerificationTitle {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.verification {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-content: stretch;
}
.userVerification {
    margin-top: 15px;
}

.verificationGuarantor {
    padding-left: 45px;
    padding-right: 45px;
}
.guarantorContent {
    display: flex;
    flex-direction: row;
    gap: 36px;
    align-content: center;
    align-items: center;
}
.guarantorVerification {
    max-width: 230px;
    min-width: 230px;
}
.guarantor {
    padding: 20px 25px;
    border-radius: 10px;
    border: 1px solid #042E6B;
    width: 100%;
}
.guarantorTitle {
    color: #000;
    font-size: 25px;
    font-weight: 600;
}
.guarantorName {
    color: #000;
    font-size: 18px;
    margin-top: 12px;
}
.guarantorLabel {
    color: #000;
    font-size: 14px;
    margin-top: 5px;
}
.guarantorDate {
    color: #000;
    font-size: 14px;
    margin-top: 7px;
}

.tick {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 10px 0;
    position: relative;
}
.tick:not(:last-child):after {
    content: '';
    display: block;
    width: calc(100% - 36px);
    height: 1px;
    background: rgba(4, 46, 107, 0.50);
    position: absolute;
    bottom: 0;
    left: 36px;
}
.tickLabel {
    color: #272727;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.actions {
    margin-top: -20px;
    display: flex;
    gap: 24px;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}
.save[disabled] {
    background: grey !important;
}

.cancel {
    border-radius: 8px !important;
    border: 1px solid #D72B2C !important;
    background: transparent !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}

.tooltip {
    position: absolute;
    top: 4px;
    right: 0;
}

.upload {
    min-width: calc(100% / 3 - 13px);
    cursor: pointer;

    & span {
        width: 100%;
    }
}