.grid table[class~="MuiTable-root"] th[class~="column-status"],
.grid table[class~="MuiTable-root"] td[class~="column-status"] {
    min-width: 105px;
    max-width: 105px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] td[class~="column-status"] [class~="MuiChip-label"] {
    line-height: initial;
}

.grid table[class~="MuiTable-root"] th[class~="column-fullName"],
.grid table[class~="MuiTable-root"] td[class~="column-fullName"] {
    min-width: 150px;
    max-width: 150px;
}

.grid table[class~="MuiTable-root"] th[class~="column-stateId"],
.grid table[class~="MuiTable-root"] td[class~="column-stateId"] {
    min-width: 110px;
    max-width: 110px;
}

.grid table[class~="MuiTable-root"] th[class~="column-email"],
.grid table[class~="MuiTable-root"] td[class~="column-email"] {
    min-width: 130px;
    max-width: 130px;
}

.grid table[class~="MuiTable-root"] th[class~="column-code"],
.grid table[class~="MuiTable-root"] td[class~="column-code"] {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-marriage"],
.grid table[class~="MuiTable-root"] td[class~="column-marriage"] {
    min-width: 80px;
    max-width: 80px;
}

.grid table[class~="MuiTable-root"] th[class~="column-birthday"],
.grid table[class~="MuiTable-root"] td[class~="column-birthday"],
.grid table[class~="MuiTable-root"] th[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] td[class~="column-createdAt"] {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-phone"],
.grid table[class~="MuiTable-root"] td[class~="column-phone"] {
    min-width: 120px;
    max-width: 120px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-isCompany"],
.grid table[class~="MuiTable-root"] td[class~="column-isCompany"] {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-gender"],
.grid table[class~="MuiTable-root"] td[class~="column-gender"] {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-rfc"],
.grid table[class~="MuiTable-root"] td[class~="column-rfc"] {
    min-width: 120px;
    max-width: 120px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-responsibleAdminId"],
.grid table[class~="MuiTable-root"] td[class~="column-responsibleAdminId"],
.grid table[class~="MuiTable-root"] th[class~="column-login"],
.grid table[class~="MuiTable-root"] td[class~="column-login"] {
    min-width: 110px;
    max-width: 110px;
}

.grid table[class~="MuiTable-root"] th[class~="column-avatar"],
.grid table[class~="MuiTable-root"] td[class~="column-avatar"] {
    min-width: 120px;
    max-width: 120px;
    text-align: center;
}

.grid table[class~="MuiTable-root"] td[class~="column-avatar"] img {
    max-width: 90px;
    max-height: 90px;
}

.grid table[class~="MuiTable-root"] td[class~="column-history"] {
    min-width: 140px;
    max-width: 140px;
}

.historyItem {
    padding-bottom: 6px;
}

.historyItem:last-child {
    padding-bottom: 0;
}

.loginTry {
    min-width: 240px;
}
.loginTryDate {
    color: #002A77;
}
.loginTryStatus:before {
    content: '/';
    display: inline;
    margin-left: 8px;
    margin-right: 8px;
}