.box {
  margin-top: 25px;
  color: #000;
  font-family: Outfit, sans-serif;
}

.head {
  display: flex;
  align-items: center;
  gap: 28px;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tick {
  border: 1px solid #042E6B;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.guarantors {
  margin-top: 15px;
  align-items: stretch;
}

.addLink {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit, sans-serif;
  color: #042E6B;
  border-radius: 10px !important;
  border: 2px dashed #042E6B !important;
  box-shadow: 0 4px 8px 0 #3b49571a;
  cursor: pointer;
}

.iconPlus {
  color: #042E6B;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
