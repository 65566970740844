.page {
  padding: 30px 44px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.formRow {
  margin-top: 0!important;
  min-height: initial;
}

.formRow div[class~="MuiInputBase-root"] {
  margin-top: 0!important;
  min-height: initial;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
  display: none!important;
}

.formValueCol div[class~="MuiInputBase-root"]::before,
.formValueCol div[class~="MuiInputBase-root"]::after {
  display: none!important;
}
.formValueCol input[class~="MuiInput-input"] {
  padding: 0!important;
  color: #000!important;
  -webkit-text-fill-color: #000!important;
  font-family: Outfit, sans-serif;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: normal!important;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 160px;
}

.userTopRow {
  gap: 100px;
}

.col {
  margin-right: 0!important;
  flex: 1 1 auto;
}

.colId {
  flex: 0 0 70px;
  max-width: 70px;
}

.colStatus {
  flex: 0 0 180px;
  max-width: 180px;
}

.colTotal {
  flex: 0 0 240px;
  max-width: 240px;
}

.colDate {
  flex: 0 0 200px;
  max-width: 200px;
}

.textareaCol {
  padding-top: 24px;
}

.textareaLabel {
  padding-bottom: 20px;
}

.form {
  padding: 0!important;
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 32px;
  margin-bottom: 25px;
}

.blockForm {
  padding-bottom: 4px;
}

.subTitle {
  color: #000!important;
  font-size: 20px!important;
  font-weight: 600!important;
  line-height: normal!important;
  font-family: Outfit, sans-serif;
  padding-bottom: 22px;
}

.message div[class~="MuiInputBase-root"]::before,
.message div[class~="MuiInputBase-root"]::after {
  border-bottom: none!important;
}

.message div[class~="MuiInputBase-root"] {
  border-radius: 8px;
  border: 1px solid #002A77;
  background: #EEE;
  margin-top: 15px;
  padding: 14px 20px;
  margin-bottom: 4px;
}

.list div[class~="RaList-content"] {
  max-height: initial!important;
}

.list div[class~="RaList-main"] {
  padding: 0!important;
}

.list div[class~="MuiPaper-root"] {
  box-shadow: none;
  background-color: transparent;
}

.list table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.list table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}

.list table[class~="MuiTable-root"] th[class~="column-userId"],
.list table[class~="MuiTable-root"] td[class~="column-userId"] {
  min-width: auto;
  max-width: initial;
  width: auto;
}

.list table[class~="MuiTable-root"] th[class~="column-phone"],
.list table[class~="MuiTable-root"] td[class~="column-phone"] {
  min-width: 170px;
  max-width: 170px;
  width: 170px;
  text-align: center;
}

.list table[class~="MuiTable-root"] th[class~="column-status"],
.list table[class~="MuiTable-root"] td[class~="column-status"] {
  min-width: 170px;
  max-width: 170px;
  width: 170px;
  text-align: center;
  padding-left: 45px;
  padding-right: 45px;
}

.actionsToolbar {
  padding: 0!important;
  min-height: initial!important;
  justify-content: flex-start!important;
  align-items: center!important;
}

.action {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.action[class~="Mui-disabled"] {
  cursor: not-allowed!important;
}

.actionIcon {
  margin-right: 10px!important;
  font-size: 18px!important;
}

.error [class~="MuiInputBase-formControl"] {
  border-color: red !important;
}
