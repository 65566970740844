.button {
    padding: 8px 36px 8px 11px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 37px;
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    margin-left: auto;
}

.icon {
    font-weight: 700;
    font-size: 20px;
}