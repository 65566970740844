.card [class~="InitialCard-header"] {
    justify-content: flex-end;
}

.filter {
    padding-bottom: 22px;
}
.select {
    width: 200px;
}
.select [class~="ant-select-selector"] {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    box-shadow: none!important;
    height: 37px!important;
}
.select [class~="ant-select-selection-item"] {
    height: 35px!important;
    display: flex!important;
    align-items: center!important;
    color: #002A77!important;
}
.select input {
    height: 35px!important;
}
.select [class~="ant-select-selection-placeholder"] {
    margin-top: 7px!important;
    color: rgba(0, 42, 119, 0.50)!important;
    font-family: Outfit, sans-serif!important;
    font-size: 15px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.popup {
    border-radius: 8px!important;
    border: 1px solid #002A77!important;
}
.popup [class~="ant-select-item-option-content"] {
    color: #002A77!important;
    font-family: Outfit, sans-serif!important;
    font-size: 15px!important;
    font-weight: 400!important;
    line-height: normal!important;
}

.table table,
.table [class~="ant-table-container"] {
    border-color: #042E6B!important;
}

.col {
    color: #74706B;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-color: #042E6B!important;
}

th.col {
    color: #000!important;
    background: #E1E5ED!important;
    padding: 8px!important;
}
.col[class~="product-img"] {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    max-width: 65px;
    max-height: 65px;
    min-height: 65px;
}
.noImg {
    width: 65px;
    height: 65px;
    background-color: #dedede;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: 'No image';
    }
}
