.wrap {}

.fieldRow [class~="Mui-error"]::before {
    border-bottom-color: red!important;
}
.fieldRow [class~="MuiInputBase-formControl"]::after {
    display: none!important;
}

.descField {
    padding-top: 40px;
}
.fieldRow.descField [class~="MuiInputBase-formControl"]::before,
.fieldRow.descField [class~="MuiInputBase-formControl"]::after {
    display: none!important;
}
.fieldRow.descField [class~="MuiInputBase-formControl"] {
    padding-bottom: 0!important;
}
.fieldRow.descField [class~="MuiInputBase-formControl"] textarea {
    padding: 14px 6px!important;
    border-radius: 8px;
    border: 1px solid #000;
    background: rgba(0, 42, 119, 0.16);
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    min-height: 100px;
}
.fieldRow.descField [class~="Mui-error"] textarea {
    border-color: red;
}
