._ {

}

.actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.action {
    padding: 0 16px !important;
    height: 40px !important;
    color: #FFF!important;
    border-radius: 8px !important;
    background: #042E6B !important;

    &:hover {
        background-color: #0069c0 !important;
    }
}

.actionSubmit {
    background: #042E6B !important;
}

.actionCancel {
    background: #FFF !important;
    color: #042E6B !important;

    &:hover {
        background-color: #ddd !important;
        border-color: #ddd !important;
    }
}

[class~="ant-modal-header"] {
    padding: 15px 20px 13px !important;
    background: #042E6B !important;
    border-radius: 8px 8px 0 0 !important;

    & svg {
        fill: #fff;
    }
}

[class~="ant-modal-content"] {
    border-radius: 8px !important;
}

[class~="ant-modal-title"] {
    color: #FFF !important;
    font-size: 20px !important;
    font-family: Outfit, sans-serif !important;
    font-weight: 400 !important;
}