.wrap {}

.imgBlock, .videoBlock {
    width: 432px;
    height: 226px;
    border-radius: 5px;
    border: 1px dashed #000;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.pdfBlock {
    max-height: 800px;
    overflow-y: auto;
}
.pdfPage [class~="pdf-highlight"] {
    background-color: aquamarine;
    color: black;
}
