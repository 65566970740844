.image {
  padding-left: 57px;
  padding-right: 57px;
  width: 102px;
  height: 102px;
  box-sizing: content-box !important;
}

.rows {
  width: 100%;
}

.row {
  width: 100%;
  height: 50px;
}

.row input:disabled {
  padding-left: 0 !important;
}

.autocomplete {
  width: 100% !important;
}

.autocomplete [class~="ant-select-selector"] {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0 !important;
}

.autocomplete [class~="ant-select-selection-placeholder"] {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.48) !important;
}

.autocomplete [class~="ant-select-selection-search"] {
  right: 0 !important;
  left: 0 !important;
  font-size: 16px !important;
}

.extraction {
  font-size: 12px;
  color: #042E6B;
}

.extraction b {
  margin-right: 5px;
}
