.list {
}

.grid table[class~="MuiTable-root"] th,
.grid table[class~="MuiTable-root"] td {
  min-width: 175px;
  max-width: 175px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
  padding: 0 !important;
}

.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-name"],
.grid table[class~="MuiTable-root"] td[class~="column-name"],
.grid table[class~="MuiTable-root"] th[class~="column-undefined"],
.grid table[class~="MuiTable-root"] td[class~="column-undefined"] {
  min-width: 268px;
  max-width: 268px;
  text-align: center;
}

.nameCell {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
