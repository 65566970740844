.cardList {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.cardItem {
  width: 100%;
}

.cardItemTitle {
  font-size: 12px;
  color: #002A77;
}

.cardItemDate, .cardItemNumber {
  font-size: 14px;
  color: #002A77;
}

.cardItemHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(4, 46, 107, 0.50);
}

.cardItemBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
}

.cardItemStatus {
  display: inline-block;
}

.cartActions {
  margin-top: 22px;
}
.cartButton {
  padding: 10px 66px !important;
  text-transform: initial !important;
  color: #042E6B !important;
  border-color: #042E6B !important;
}

.list {
  border: 1px solid #042E6B;
  border-radius: 8px;
  background: #fff;
  padding: 26px 32px 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
  margin-top: 20px;
}

.listHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listTitle {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
}

.addInvoiceUpload {
  width: auto !important;
}
.addInvoiceUpload [class~="ant-upload"] {
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.text {
  font-size: 13px;
  font-weight: 400;
  margin-top: 13px;
}

.group {
  border: 1px solid #042E6B;
  border-radius: 10px;
  padding: 18px 21px 31px;
}

.groupTitle {
  font-size: 15px;
  color: #000;
}

.groupList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  margin-top: 26px;

  @media screen and (min-width: 1024px) {
    gap: 12px;
  }
}

.groupItem {
  width: calc(100% / 3 - 3px);
  background: #E1E5ED;
  border: 2px solid #042E6B;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 1024px) {
    width: calc(100% / 4 - 10px);
  }

  @media screen and (min-width: 1200px) {
    width: calc(100% / 6 - 11px);
  }

  @media screen and (min-width: 1600px) {
    width: calc(100% / 8 - 11px);
  }

}

.sendBy {
  position: absolute;
  top: 5px;
  right: 5px;
}

.groupItemContent {
  padding: 13px 13px 0;
  display: flex;
  width: 100%;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.groupItemInfo {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 12px;
}

.groupItemStatus {
  background: #042E6B;
  text-align: center;
  padding: 11px 27px;
}

.groupItemLabel {
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  gap: 10px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  padding: 0 17px 0 2px;
}
.groupItemLabel span:first-letter {
  text-transform: uppercase;
}

.groupItemLabel.approved {
  background: #52C41A;
  color: #fff;
}

.groupItemLabel.denied {
  background: #FF4D4F;
  color: #fff;
}

.groupItemLabel.pending {
  background: #FFC82D;
  color: #000000;
}

.groupItemFile {
  width: 86px;
  height: 105px;
  border: 2px solid #042E6B;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.groupItemRemove {
  appearance: none;
  position: absolute;
  top: 5px;
  right: 6px;
}

.detail {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 24px;
}

.detailCard {
  padding: 18px 30px 18px 23px;
  background: #fff;
  border: 1px solid #042E6B;
  border-radius: 8px;
  width: calc(50% - 12px);
}
.detailCard.fullWidth {
  width: 100%;
}
.detailCardNote {
  position: relative;
  z-index: 0;
}

.detailCardHead {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.detailCardTitle {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.detailCardDate {
  font-size: 13px;
}

.detailCardContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.amounts {
  margin-top: 20px;
  margin-bottom: 20px;
}

.info {
  min-width: 240px;
  margin-bottom: -4px;
  position: relative;
  bottom: -10px;
}
.infoCode {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.infoName {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  max-width: 480px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transactions {
  margin-top: 20px;
}

.preview {
  position: absolute;
  width: 100%;
  height: 100%;
}

.extraction {
  font-size: 12px;
  color: #042E6B;
}

.extraction b {
  margin-right: 5px;
}
