.list {}

.grid table[class~="MuiTable-root"] th,
.grid table[class~="MuiTable-root"] td {
  min-width: 175px;
  max-width: 175px;
}

.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}
