.block {
  padding-bottom: 8px;
}

.row {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background: #042E6B;
  margin-top: 20px;
  padding: 16px;
}

.col {
  display: flex;
  color: #FFF;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  flex: 1;
  padding: 0 10px;
}

.col:first-child {
  flex: 0 0 36%;
  max-width: 36%;
}

.col:last-child {
  flex: 0 0 16%;
  max-width: 16%;
}

.label {
  padding-right: 5px;
}

/* Tiny */
.blockTiny {
  height: 25px;
  padding: 0;
}

.blockTiny .row {
  margin: 0;
  padding: 0;
  border-radius: 5px;
}

.blockTiny .col {
  height: 35px;
  align-items: center;
  font-weight: 400;
}
