.page {
    padding: 30px 44px;
}

.form {
    padding: 0!important;
}

.wrapper {
    padding-bottom: 24px;
    margin-bottom: 60px;
}

.block {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    padding: 18px 26px 0;
    margin-bottom: 25px;
    height: 100%;
}

.blockHeader {
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.actions {
    display: flex;
    gap: 24px;
   justify-content: initial!important;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}
