.wrap {
    padding-left: 58px;
}
.empty {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
.answer {
    border-radius: 17px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    padding: 8px 12px;
    text-align: center;
}
