.box {
  display: flex;
  align-items: center;
}
.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  line-height: 20px;
  margin-right: 10px;
  text-align: center;
  border-radius: 50%;
}
.icon {
  color: white !important;
  font-size: 11px;
}
.text {
  font-family: Outfit, sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.okIcon {
  background-color: #275D3C;
}
.okText {
  color: #275D3C;
}
.infoIcon {
  background-color: #458EF7;
}
.infoText {
  color: #458EF7;
}
.pendingIcon {
  background-color: #FFA426;
}
.pendingText {
  color: #FFA426;
}
.warnIcon {
  background-color: #FF6737;
}
.warnText {
  color: #FF6737;
}
.errorText {
  color: darkred;
}
.errorIcon {
  background-color: darkred;
}
.draftText {
  color: #8C8C8C;
}
.draftIcon {
  background-color: #8C8C8C;
}
.defaultIcon {
  background-color: #8A94A6;
}
.defaultText {
  color: #8A94A6;
}
