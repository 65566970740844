.table {
  margin-top: 35px;
}

.thead {
  font-weight: 500 !important;
}

.checkCol {
  padding: 1px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.status {
  width: 100%;
  font-size: 16px;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.paid {
  width: 20px;
  height: 20px;
  background: url("/public/icons/check.svg") no-repeat top left;
  background-size: 100%;
}

.canceled {
  width: 20px;
  height: 20px;
  background: url("/public/icons/close.svg") no-repeat top left;
  background-size: 100%;
}

.value {
  font-size: 12px !important;
  padding: 1px 12px !important;
  text-align: center !important;
}

.code:hover {
  cursor: pointer;
  color: #002A77 !important;
}

.actions {
  position: absolute;
  top: 7px;
  right: 0;
}

.delete, .addBtn {
  margin-left: 15px;
  appearance: none;
  cursor: pointer;
  background-color: #D32F2F;
  border: none;
  color: white;
  position: relative;
  padding: 7px 10px;
  border-radius: 8px;
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.delete:hover {
  background-color: #b22b2b;
}

.addBtn {
  background-color: rgb(0, 95, 57);
}
.addBtn:hover {
  background-color: rgb(0, 66, 40);
}

.link {
  display: flex;
  width: 100%;
  min-height: 42px;
  align-items: center;
  justify-content: center;
}
