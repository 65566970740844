.card {
  margin-bottom: 14px;
  border-radius: 8px;

  [class~="ant-input"] {
    padding: 8px 14px!important;
    font-size: 13px!important;
    font-weight: 400!important;
    line-height: 16px!important;
    color: black!important;
    border: 1px solid #042E6B!important;
    border-right: none!important;
    height: 100% !important;

    &[class~="ant-input-status-error"] {
      border-color: red!important;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 0 0 calc(50% - 20px);
  max-width: calc(50% - 20px);
  height: 35px;
  position: relative;

  &:first-child {
    border-radius: 8px 0 0 8px;
    [class~="ant-input"] {
      border-radius: 8px 0 0 8px;
    }
  }

  &:nth-child(2) {
    border-radius: 0 8px 8px 0;
  }

  &:last-child {
    padding-left: 20px;
    flex: 0 0 40px;
    max-width: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.deleteItem {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteIcon {
  color: #D32F2F!important;

  svg {
    width: 20px!important;
    height: 20px!important;
  }
}

.ddMenu {
  border: 1px solid #042E6B;
  background: #FFFFFF;
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 8px;
  overflow: hidden;
  min-width: 230px;
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 34px;
  padding: 2px 12px;
}

.ddBtn {
  margin-left: 0!important;
  text-transform: initial!important;
  font-size: 13px!important;
  font-weight: 400!important;
  line-height: normal!important;
  padding: 5px!important;
  min-width: initial!important;
  border-radius: initial!important;
  height: 35px!important;
  width: calc(100% - 15px) !important;
  border-color: #042E6B!important;
  color: black!important;

  &.error {
    border-color: #D32F2F!important;
  }
}

.optionIcon {
  color: #042E6B;

  svg {
    width: 20px!important;
    height: 20px!important;
  }
}
.addMenuItem {
  background: #F0F3FB;

  &.disabled {
    background: #DDD;
    cursor: not-allowed;
  }
}
.optionTitle {
  font-size: 13px;
  font-weight: 400;
  flex-grow: 1;
}

.optionTitle {
  [class~="ant-input"] {
    padding: 8px 0!important;
    font-size: 13px!important;
    font-weight: 400!important;
    line-height: 16px!important;
    color: black!important;
    height: 100% !important;

    &[class~="ant-input-status-error"] {
      border-bottom: 1px solid red!important;
    }
  }
}

.decor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 15px;
  height: 100%;
  border: 1px solid #042E6B;
  border-left: none;
  border-radius: 0 8px 8px 0;
  right: 0;
  top: 0;
  background: #F0F3FB;
  cursor: grab;
}

.decorPoint {
  width: 3px;
  height: 3px;
  display: block;
  background: #042E6B;
  border-radius: 50%;
  margin: 2px 0;
}
