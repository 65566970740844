.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.headActions {
  margin-left: auto;
}

.content {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFFFFF;
  margin-top: 20px;
  padding: 47px 47px 20px 48px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.item {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  gap: 24px;
  padding-bottom: 20px;
}

.itemMeta {
  min-width: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay [class~="ant-tooltip-inner"] {
  background: #042E6B;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
}
.overlay [class~="ant-tooltip-arrow-content"]::before {
  background: #042E6B;
}

.docVisibility svg {
  fill: rgba(76, 78, 100, 0.40);
  margin-top: 10px;
  font-size: 20px;
}

.itemFixed {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.itemFixed svg {
  font-size: 34px;
  color: #d46b08;
}

.itemNumber {
  width: 34px;
  height: 34px;
  background: #042E6B;
  color: #FFFFFF;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
}

.itemNumber.valid {
  background: #4caf50;
}

.itemNumber.invalid {
  background: #ff4d4f;
}

.itemNumber.valid.optional {
  background: #042E6B;
}

.itemSave {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: auto;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #042E6B;
  background: rgba(4, 46, 107, .1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
}

.itemSave .itemIcon {
  color: #042E6B;
  transition: all .3s ease;
}

.itemSave:hover {
  background: #042E6B;
  box-shadow: 0 0 8px rgba(4, 46, 107, .5);
  transition: all .3s ease;
}

.itemSave:hover .itemIcon {
  color: white;
  transition: all .3s ease;
}

.itemPause {
  min-width: 35px;
  min-height: 35px;
  background: #757575 url("/public/icons/pause.svg") no-repeat center;
  border-radius: 50%;
  margin-left: auto;
  cursor: pointer;
  margin-top: 10px;
}

.itemContent {
  width: 100%;
}

.itemQuestion {
  margin-top: 2px;
}

.itemQuestion > .optional {
  margin-right: 4px;
  border: 1px solid #042E6B;
  color: #042E6B;
  font-size: 11px;
  padding: 0 2px 1px;
  border-radius: 8px;
}

.itemQuestion.invalid {
  color: #ff4d4f;
}

.answer {
  margin-top: 13px;
}

.answerCustomField {
  border-radius: 8px;
  border: 1px solid #042E6B;
  padding: 16px;
  font-size: 12px;
  min-height: 60px;
}

.answerCustomField:disabled {
  color: #333 !important;
}

.answerCheckboxes, [class~="ant-radio-group"] {
  display: flex;
  flex-wrap: wrap;
  column-gap: 52px;
  row-gap: 19px;
}

.answerItem {
  display: inline-flex;
  align-items: center;
}

.checkbox {
  margin-left: 0 !important;
}

.checkbox [class~="ant-checkbox-inner"] {
  border-color: #042E6B !important;
}

.checkbox [class~="ant-checkbox-checked"] [class~="ant-checkbox-inner"] {
  background-color: #042E6B !important;
}

.radio [class~="ant-radio-inner"] {
  border-color: #042E6B !important;
}

.radio [class~="ant-radio-checked"] [class~="ant-radio-inner"]::after {
  background-color: #042E6B !important;
}

.checkbox, .radio {
  font-size: 12px;
  color: #042E6B !important;
}

.tableWrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 60px;
}

.answerTable {
  padding: 0;
  background: #E1E5ED;
  border-radius: 8px;
  border: 1px solid #042E6B;
  position: relative;
  width: calc(50% - (60px / 2));
  height: 100%;
}

.answerTable.fullWidth {
  width: 100%;
}

.cursorText {
  cursor: text;
}

.tableFieldWrap .tableField.numberField {
  width: 100% !important;
  line-height: initial !important;
}

.tableFieldWrap [class~="ant-input-number"] {
  font-size: 12px !important;
  line-height: initial !important;
}

.tableFieldWrap .tableField.numberField input {
  padding: 0 !important;
  height: initial !important;
  min-height: initial !important;
  color: #042E6B !important;
  border-bottom: 1px dotted #042E6B !important;
  font-size: 12px !important;
  line-height: 1.1 !important;
}

.tableFieldWrap textarea {
  padding: 0 !important;
  font-size: 12px !important;
  min-height: initial !important;
  line-height: 1.1 !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px dotted #042E6B !important;
  outline: none !important;
  box-shadow: none !important;
  color: #042E6B !important;
}

.tableFieldWrap .tableField.optionField {
  width: 100% !important;
  line-height: initial !important;
  font-size: 12px !important;
}

.tableFieldWrap .tableField.optionField [class~="ant-select-selector"] {
  padding-left: 0 !important;
  background-color: transparent !important;
  border-bottom: 1px dotted #042E6B !important;
  font-size: 12px !important;
  line-height: 1.1 !important;
  height: initial !important;
  color: #042E6B !important;
}

.tableFieldWrap .tableField.optionField [class~="ant-select-selection-item"] {
  line-height: 1.1 !important;
}

.tableFieldWrap .tableField.optionField input {
  height: initial !important;
  line-height: initial !important;
}

.tableFieldWrap .tableField.optionField [class~="ant-select-selector"]::after {
  line-height: initial !important;
}

.addRow, .removeRow {
  position: absolute;
  bottom: -30px;
  width: 40px;
  height: 20px;
  background-color: #4caf50;
  color: white;
  font-size: 20px;
  line-height: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: .8;
}

.addRow:hover {
  opacity: 1;
}

.removeRow {
  background-color: #ff4d4f;
  right: 0;
  padding-bottom: 3px;
}

.answerTable:not(:last-child) {
  margin-bottom: 40px;
}

.answerTable:last-child .addRow, .answerTable:last-child .removeRow {
  bottom: -40px;
}

.answerTableLabel {
  color: #042E6B;
}

.row {
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: 12px;
}

.row {
  border-top: 1px solid #042E6B;
}

.row:first-child {
  border-top: none;
}

.col {
  padding: 11px 12px 9px;
  display: table-cell;
  min-width: 50%;
  color: #042E6B;
  letter-spacing: -.48px;
}

.col:not(:last-child) {
  border-right: 1px solid #042E6B;
}

.break {
  overflow-wrap: break-word;
}

.author {
  height: 0;
  line-height: 0;
  margin-top: 8px;
  font-size: 12px;
  text-align: right;
  padding-top: 4px;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.save {
  width: 200px;
  background-color: #042E6B !important;
  border-radius: 8px;
}

.save svg {
  margin-right: 14px;
  fill: white;
}

.save:disabled {
  background-color: #eee !important;
}

.save:disabled svg {
  margin-right: 14px;
  fill: rgba(0, 0, 0, 0.26);
}
