.box {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: zoom-in;
}

.download {
  display: block;
  z-index: 100;
  position: absolute;
  width: 45px;
  height: 48px;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -22px;
  cursor: pointer;
}

.pdfIcon {
  position: absolute;
  top: 3px;
  left: 3px;
}

.pdfIcon svg {
  font-size: 22px;
  color: maroon;
}
