._ {
    border: 1px solid #042E6B;
    border-radius: 8px;
    background: #fff;
    padding: 47px 20px;
    margin-top: 29px;
}

.head {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-left: 92px;
    margin-bottom: 30px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    padding-left: 92px;
    display: flex;
    gap: 24px;
    align-items: center;
}

.infoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.infoRow:not(:last-child) {
    margin-bottom: 10px;
}

.label {
    width: 50px;
}

.value {
    display: flex;
    flex-direction: column;
}

.select {
    width: 241px;
    font-size: 18px;
}

.select [class$="ant-select-selector"] {
    border: none !important;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10) !important;
}

.error {
    border-bottom: 1px solid red !important;
}

.actions {
    margin-top: 41px;
    padding-left: 92px;
}

.save {
    padding: 10px 49px !important;
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
    box-shadow: none !important;
    color: #fff !important;
    background: #042E6B !important;
    text-transform: initial !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
