.box {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.download {
  display: inline-block;
  z-index: 100;
  position: absolute;
  bottom: -15px;
  right: -15px;
}
.downloadIcon {
  font-size: 30px;
}
