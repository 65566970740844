.page {
    padding: 30px 44px;
}

.form {
    padding: 0!important;
}

.wrapper {
    padding-bottom: 24px;
}

.wrapperColFirst, .wrapperColLast {
    margin-bottom: 24px;
}

@media all and (min-width: 1200px) {
    .wrapperColFirst, .wrapperColLast {
        margin-bottom: 0;
    }
}

.block {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    padding: 18px 26px 0;
    margin-bottom: 25px;
    height: 100%;
}

.blockHeader {
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.formRow {
    margin-top: 0!important;
    min-height: initial;
    align-items: end;
    margin-bottom: 10px;
}

.formLabelCol {
    color: #272727;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
}

.formRow div[class~="MuiInputBase-root"] {
    margin-top: 0!important;
    min-height: initial;
}

.formTitleCol {
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
    display: none!important;
}

.formValueCol p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
    display: initial!important;
    color: red!important;
}

.formMainMultilineValue div[class~="MuiInputBase-root"] {
    border-radius: 8px;
    border: 1px solid #002A77;
    background: #EEE;
    padding: 13px 20px;
}

.formMainMultilineValue div[class~="MuiInputBase-root"]::before,
.formMainMultilineValue div[class~="MuiInputBase-root"]::after {
    display: none;
}

.formMainMultilineValue div[class~="MuiInputBase-root"] textarea {
    height: auto!important;
    min-height: 70px!important;
}

.formMainMultilineValue div[class~="MuiInputBase-root"] textarea::placeholder {
    color: rgba(0, 42, 119, 0.80);
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.formValueCol input[class~="MuiInput-input"] {
    padding: 0!important;
    color: #000!important;
    -webkit-text-fill-color: #000!important;
    font-family: Outfit, sans-serif;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.formValueCol.formInfoValue input[class~="MuiInput-input"],
.formValueCol.formInfoValue div[class~="MuiInputBase-root"],
.formValueCol.formInfoValue span[class~="MuiTypography-root"] {
    font-size: 18px!important;
}

.actions {
    padding: 0!important;
    min-height: initial!important;
    justify-content: flex-start!important;
    align-items: center!important;
    background-color: transparent!important;
}

.action {
    width: 200px!important;
    height: 38px!important;
    border-radius: 8px;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.actionSave {
    color: #FFF!important;
    background: #042E6B!important;
}

.actionIcon {
    margin-right: 10px!important;
    font-size: 18px!important;
}