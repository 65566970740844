.box {
  position: relative;
}
.download {
  display: inline-block;
  z-index: 100;
  position: absolute;
  bottom: -15px;
  right: -15px;
}
.downloadIcon {
  color: gray;
  font-size: 30px;
}
.downloadIcon:hover {
  color: #171725;
}
