.wrap {
    padding-left: 58px;
}
.table {
    border: 1px solid #042E6B;
    border-radius: 10px;
    background: #E1E5ED;
    margin-bottom: 20px;
}
.table:last-child {
    margin-bottom: 0;
}
.row {
    display: flex;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px solid #042E6B;
}
.row:last-child {
    border-bottom: none;
}
.col {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    padding: 11px 20px;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
.col:last-child {
    border-left: 1px solid #042E6B;
}
.empty {
     color: #042E6B;
     font-family: Outfit, sans-serif;
     font-size: 12px;
     font-weight: 400;
     line-height: normal;
     text-align: center;
 }
