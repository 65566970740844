.wrapper {
  display: flex;
  justify-content: center;
  margin-left: -20px;
}
.wrapper a {
  bottom: 0px;
  right: -40px;
}
.fileImg {
  max-width: 30px;
  max-height: 30px;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}
.pdfBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}
.pdfIcon {
  font-size: 30px;
  color: maroon;
}

