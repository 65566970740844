.wrap {
  padding: 22px 22px 80px 44px;
  font-family: Outfit, sans-serif;
  background-color: #e0e5ed;
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 4px;
}

.center {
  display: flex;
}

/* Link back */
.linkBack {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.linkBack:hover svg path {
  fill: #40a9ff;
}

.linkBack:hover .linkBackText {
  color: #40a9ff;
}

.linkBackText {
  display: inline-block;
  margin-left: 10px;
  color: #000;
}
