.wrap {
}
.row {
    @media screen and (max-width: 1199px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
}
.col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 18px;
}
.fileBlock {
    min-width: 120px;
    max-width: 170px;

    @media screen and (min-width: 800px) {
        min-width: 170px;
    }
}
.uploadBtn {
    height: 160px;
}
.filePreview {
    [class~="fc-file"] {
        min-width: 120px;
        max-width: 170px;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 800px) {
            min-width: 170px;
        }
    }
    [class~="fc-pdf-box"] {
        border: none;
    }
    [class~="pi-link"] {
        right: -30px !important;
        bottom: -30px !important;
    }
    [class~="fc-img"] {
        max-height: 156px;
        max-width: 166px;
        margin-top: 6px;
    }
}
.title {
    color: #000;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
