.page {
  padding: 30px 44px;
}

.page [class~="RaEdit-card"] {
  background-color: transparent!important;
  box-shadow: none!important;
}

.form {
  padding: 0!important;
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 26px 0;
  margin-bottom: 25px;
  height: 100%;
}

.blockHeader {
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}

.blockHeaderThin {
  font-size: 18px;
  font-weight: 400;
  align-self: flex-start;
  padding-top: 24px;
}

@media screen and (min-width: 800px) {
  .blockHeaderThin {
    margin-left: calc((100% - 400px) / 2);
  }
}

@media screen and (min-width: 1200px) {
  .blockHeaderThin {
    padding-top: 0;
  }
}

.blockContent {
  padding-bottom: 0;
}

.formRow {
  margin-top: 0!important;
  min-height: initial;
}

.formLabelCol {
  font-size: 18px;
  font-weight: 400;
}

.mainFormRow  {
  margin-bottom: 12px;
  min-height: 24px;
}

.innerColLast {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.switcherField label {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.formRow div[class~="MuiInputBase-root"] {
  margin-top: 0!important;
  min-height: initial;
}

.formTitleCol {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
  display: none!important;
}

.formValueCol p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
  display: initial!important;
  color: red!important;
}

.actions {
  padding: 0!important;
  min-height: initial!important;
  justify-content: flex-start!important;
  align-items: center!important;
  background-color: transparent!important;
}

.action {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.actionSave {
  color: #FFF!important;
  background: #042E6B!important;
}

.actionIcon {
  margin-right: 10px!important;
  font-size: 18px!important;
}

.checkboxes legend {
  margin: 0;
}

.checkboxes [class~="MuiFormGroup-root"] {
  justify-content: space-between!important;
}

.checkboxes [class~="MuiFormHelperText-root"] {
  display: none!important;
}

.checkboxes label {
  border-radius: 2px;
  border: 1px solid #042E6B;
  background: #E1E5ED;
  flex: 0 0 calc(25% - 30px);
  max-width: calc(25% - 30px);
  width: initial!important;
  margin: 10px 0 0 0!important;
}

.paddingBottom {
  padding-bottom: 24px;
}

.actionDelete {
  color: #D72B2C!important;
  border: 1px solid #D72B2C!important;
  background-color: transparent!important;
}
