.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  padding: 14px 20px;
  background: #FFF;
  height: 100%;
  flex-grow: 1;
}

@media all and (min-width: 992px) {
  .block {
    margin-bottom: 14px;
  }
}

.block:last-child {
  margin: 0;
}

.title {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 7px;
}

.actions {
  display: flex;
  justify-content: center;
}

.action {
  width: 168px!important;
  height: 38px!important;
  border-radius: 8px!important;
  border: 1px solid #042E6B!important;
  background: #FFF!important;
  text-transform: initial!important;
  color: #002A77!important;
}

.action [class~="ant-badge"] {
  color: #002A77!important;
}

.action:disabled [class~="ant-badge"] {
  color: gray!important;
}

.action sup[class~="ant-badge-count"] {
  right: -28px;
  top: 7px;
}

@media all and (min-width: 1100px) {
  .action {
    width: 200px!important;
  }
}
