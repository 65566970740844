.documents {
  display: flex;
  margin-bottom: -5px;
}
.doc {
  width: 72px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 5px;
  border: 1px solid #002a77;
  border-radius: 4px;
  position: relative;
}
.doc [data-x="download"] {
  right: -2px;
  top: 0;
  height: 100%;
  width: 32px;
  margin: 0 !important;
  left: unset;
}
.doc button {
  padding: 0;
}
.doc [data-icon="file-pdf"] {
  font-size: 17px !important;
}
.counter {
  position: absolute;
  bottom: -2px;
  left: 2px;
  font-size: 8px;
}
.fileImg {
  max-width: 30px;
  max-height: 30px;
  border-radius: 8px;
}
.pdfBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 8px;
}
.pdfIcon {
  font-size: 30px;
  color: maroon;
}
