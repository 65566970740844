.wrap {
    border-radius: 10px;
    border: 1px solid #042E6B;
    background: #FFF;
    display: flex;
    flex-direction: column;
    height: calc(100% - 10px);
    margin-top: 10px;
    padding: 20px 14px;
}
.wrap.error [class~="ant-upload-select"] {
    border-color: red!important;
}

.title {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 28px;
}

.content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.file {
    width: 148px;
    height: 148px;
}

.uploadWrap, .upload {
    height: 100%;
}
.preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid #042E6B;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.filePreview {
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.filePreview [class~="file-image-preview"] {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*    position: initial !important;*/
/*}*/
/*.filePreview [class~="file-image-img-default"] {*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*}*/
/*.filePreview [class~="file-video-preview"] {*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*    position: initial !important;*/
/*    display: flex!important;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*.filePreview [class~="file-video-preview"] [class~="anticon"] {*/
/*    font-size: 100px!important;*/
/*}*/
.removeBtn {
    display: inline-block;
    position: absolute!important;
    top: -2px!important;
    right: -2px!important;
    width: 30px!important;
    height: 30px!important;
    padding: 0!important;

    &:hover {
        color: red;
    }
}
