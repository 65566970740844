.grid table th[class~="column-code"],
.grid table td[class~="column-code"] {
    width: 120px!important;
    min-width: 120px!important;
    max-width: 120px!important;
}
.grid table th[class~="column-addressee"],
.grid table td[class~="column-addressee"] {
    width: 150px!important;
    min-width: 150px!important;
    max-width: 150px!important;
}
.grid table th[class~="column-userId"],
.grid table td[class~="column-userId"] {
    width: 100px!important;
    min-width: 100px!important;
    max-width: 100px!important;
}
.grid table th[class~="column-type"],
.grid table td[class~="column-type"] {
    width: 120px!important;
    min-width: 120px!important;
    max-width: 120px!important;
}
.grid table th[class~="column-bank"],
.grid table td[class~="column-bank"] {
    width: 120px!important;
    min-width: 120px!important;
    max-width: 120px!important;
}
.grid table th[class~="column-account"],
.grid table td[class~="column-account"] {
    width: 150px!important;
    min-width: 150px!important;
    max-width: 150px!important;
}
.grid table th[class~="column-branch"],
.grid table td[class~="column-branch"] {
    width: 150px!important;
    min-width: 150px!important;
    max-width: 150px!important;
}
.grid table th[class~="column-reference"],
.grid table td[class~="column-reference"] {
    width: 150px!important;
    min-width: 150px!important;
    max-width: 150px!important;
}
.grid table th[class~="column-phone"],
.grid table td[class~="column-phone"] {
    width: 120px!important;
    min-width: 120px!important;
    max-width: 120px!important;
}
.grid table th[class~="column-accountSetup"],
.grid table td[class~="column-accountSetup"] {
    width: 80px!important;
    min-width: 80px!important;
    max-width: 80px!important;
}
