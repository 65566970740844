._ {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.total {
  margin-left: 90px;
  width: 100%;
  max-width: 684px;
  border-top: 2px solid #042E6B;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.totalIva {
  margin-top: 10px;
  border-top: none;
}

.totalCheckbox {
  margin-left: 0;
}

.totalLabel {
  font-size: 16px;
  font-weight: 700;
  color: #042E6B;
  padding-left: 25px;
}

.totalValue {
  font-size: 16px;
  font-weight: 700;
  color: #042E6B;
  padding-right: 6px;
}

.list {
  padding-bottom: 20px;
}

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 940px;
  padding-left: 44px;
}

.product:not(:last-child) {
  margin-bottom: 20px;
}

.productContent {
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
}

.productHead {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 24px;
  font-size: 13px;
  padding-left: 48px;
}

.productInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;
}

.productImage {
  min-width: 136px;
  width: 136px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.productImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.productImageEmpty {
  min-width: 136px;
  width: 136px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 42, 119, 0.10);
  border-radius: 8px;
}

.number {
  width: 24px;
  font-size: 16px;
  font-weight: 500;
}

.name {
  width: 312px;
  position: relative;
}

.quantity {
  width: 145px;
}

.price {
  width: 145px;
}

.remove {
  width: 145px;
  display: flex;
  justify-content: center;
}

.removeIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.input {
  appearance: none;
  display: block;
  width: 100%;
  border-radius: 8px !important;
  border: 1px solid #042E6B !important;
  background: #FFF !important;
  font-family: Outfit, sans-serif !important;
  font-size: 13px !important;
  height: 40px !important;
}

.input fieldset {
  border: none !important;
}

.input legend {
  display: none !important;
}

.input div[class$="ant-select-selector"] {
  border: none !important;
  padding: 0 !important;
  height: 38px !important;
  border-radius: 8px !important;
}

.input [class$="ant-select-selection-placeholder"],
.input [class$="ant-select-selection-item"],
.input [class$="ant-input-number-input-wrap"] {
  border: none !important;
  padding: 0 11px !important;
  height: 38px !important;
  line-height: 40px !important;
  border-radius: 8px !important;
}

.input [class$="ant-select-selection-search-input"],
.input [class$="ant-input-number-input"] {
  border: none !important;
  padding: 0 !important;
  height: 38px !important;
  border-radius: 8px !important;
}
.input [class$="ant-select-selection-search-input"]:disabled,
.input [class$="ant-input-number-input"]:disabled {
  color: #4B5260 !important;
}

.add {
  margin-left: 90px;
  margin-bottom: 20px;
}

.addButton {
  border-radius: 8px !important;
  background: #042E6B !important;
  text-transform: initial !important;
  padding: 11px 39px !important;
}
.addButton:disabled {
  background: #70819b !important;
  color: white !important;
  cursor: not-allowed !important;
}

.extraction {
  position: absolute;
  left: -88px;
  top: -25px;
  background: white;
  font-size: 12px;
  color: #042E6B;
}

.extraction b {
  margin-right: 5px;
}
