.menu {
  margin-top: 0;
}
.menu .menuText {
  color: black;
}
.menuClosed .menuText {
  color: transparent !important;
}
.menuIcon, .menuIconTransparent {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.inverse .menuIcon {
  background-color: white;
}
.menuIconTransparent {
  background-color: transparent;
}
.menuIcon svg {
  color: white;
  font-size: 13px;
}
.menu a {
  padding-left: 20px;
  padding-right: 25px !important;
}
.menu a + sup {
  margin-right: 6px !important;
  margin-top: 18px !important;
}
.menu .menuSelected {
  background-color: #002A77 !important;
}
.menu .menuSelected * {
  color: white;
}
.menu .menuSelected .menuIcon, .menu .menuSelected .menuIconTransparent {
  background-color: #F38336;
}
.inverse.menuSelected .menuIcon {
  background-color: white;
}
.inverse.menuSelected .menuIcon svg path {
  fill: #F38336;
}

.menu .parent {
  padding-right: 0 !important;
  position: relative;
}
.menu .parentSelected {
  background: lightsteelblue;
}
.menu .parentIcon {
  color: black;
}
.menu .parentText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 55px;
  padding-right: 8px;
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.menu .children {
  display: none;
}
.menu .children > a {
  padding-left: 40px !important;
}
.menu .children > span > a {
  padding-left: 40px !important;
}
.menu .active {
  display: block;
}
.menu .children .ant-badge {
  display: block;
}
.menu .notification-badge .MuiBadge-badge {
  top: 50% !important;
  right: -20px !important;
}
.menu .badge {
  color: red;
  display: block;
}
