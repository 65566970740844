.box {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    font-size: 14px;
}

.col label {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.col.colProduct {
    flex: 1 1 auto;
}

.col {
    margin-right: 15px;
}

.col:last-child {
    margin-right: 0;
}

.col span[class~="ant-select-suffix"] svg {
    fill: black;
}

.col div[class~="ant-select-selector"],
.col div[class~="ant-input-number"] {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    height: 35px!important;
    overflow: hidden;
}

.col div[class~="ant-select-selector"] {
    width: initial!important;
    padding-right: 30px!important;
    max-width: 100% !important;
}

.col div[class~="ant-input-number"] {
    display: flex;
    align-items: center;
}

.col.colCheck label[class~="MuiFormLabel-root"]::before {
    content: '';
    display: inline-block;
    width: 4px;
}

.col span[class~="ant-checkbox"] {
    top: 6px;
    left: 6px;
}

.col span[class~="ant-checkbox-checked"] span[class~="ant-checkbox-inner"] {
    background-color: #042E6B;
}

.col span[class~="ant-checkbox-checked"] span[class~="ant-checkbox-inner"]::after {
    left: 30%;
}

.col span[class~="ant-checkbox-inner"] {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    border: 1px solid #042E6B!important;
}

.col.colOrder {
    width: 86px;
}

.selectProduct {
    width: 100%;
}

.position {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 13px;
    line-height: 1em;
}

.positionCol {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #8A94A6;
}

.positionCol:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.orderLink {
    display: inline-block;
    padding-top: 6px;
}

.removeLabel {
    text-align: right;
}

.removeIcon {
    fill: #FF4D4F!important;
    font-size: 20px!important;
}

.removeBtn {
    width: 30px;
}

.btn {
    border-radius: 8px!important;
    text-transform: initial!important;
    color: #E1E5ED;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: normal!important;
}

.btnIcon {
    width: 14px!important;
}