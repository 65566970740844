.formWrap {
    position: relative;
}

.infoCol {
    display: flex;
    flex-direction: column;
}

.code {
    display: flex;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}
.code.codeEmpty {
    justify-content: center;
}
.codeContentLeft::after,
.codeContentMiddle::after {
    content: '—';
    display: inline-block;
    color: black;
    font-size: 18px;
    font-weight: 400;
    padding: 0 10px;
}

.formItem [class~="ant-input"][disabled] {
    background: white!important;
}
.formItem.disabled label {
    color: rgba(0, 0, 0, .6) !important;
}
.formItem [class~="ant-form-item-label"] {
    text-align: left!important;
}
.formItem label, .labelCol, .fileFieldLabel {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.formItem label::before {
    display: none!important;
}
.fileFieldLabel {
    margin-bottom: 12px;
}

.customInputRow {
    @media screen and (min-width: 576px) {
        display: flex;
    }
    @media screen and (min-width: 768px) {
        margin-top: 6px;
        height: 46px;
    }
}
.customInputLabelCol {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 12px;
    line-height: 1.5715;

    @media screen and (min-width: 576px) {
        flex: 0 0 33%;
        max-width: 33%;
        transform: translateY(-8px);
    }
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 992px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1200px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 1600px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
.customInputLabelCol.disabled {
    color: rgba(0, 0, 0, 0.60);

    @media screen and (min-width: 768px) {
        padding-top: 8px;
    }
}
.customInputValueCol {
    margin-bottom: 24px;

    @media screen and (min-width: 576px) {
        flex: 1;
    }
}
.customInputValueCol input {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
    height: 32px!important;
}
.selectField [class~="ant-select-selector"] {
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}
.selectField[class~="ant-select-status-error"]:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) [class~="ant-select-selector"] {
    border-color: transparent!important;
    border-bottom-color: red !important;
}

.selectField {
    width: 100%;
}
.selectField .selectOptionWrap,
.selectField [class~="ant-select-selection-item"] {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
    max-width: 400px!important;

    @media screen and (min-width: 840px) and (max-width: 1200px) {
        max-width: 500px!important;
    }
}
.selectField [class~="ant-select-selector"] {
    padding: 0!important;
}
.selectField fieldset {
    border: none!important;
    border-radius: initial!important;
    padding-left: 0!important;
    padding-right: 0!important;
}
.selectField fieldset legend {
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}
.selectField [class~="MuiSelect-select"] {
    padding: 0!important;
}
.selectField[class~="Mui-disabled"] [class~="MuiSelect-select"] {
    color: black!important;
    -webkit-text-fill-color: black!important;
    cursor: not-allowed;
}

.dateInput [class~="ant-picker-suffix"] {
    transform: translateX(-8px);
}

.inputField {
    padding: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.inputField.maskedField {
    border: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    box-shadow: none!important;
}
.inputField.moneyField {
    width: 100% !important;
}
.inputField.moneyField input {
    padding: 0!important;
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.inputField.moneyField[class~="ant-input-number-disabled"] {
    background-color: transparent!important;
}

.actions {
    position: absolute;
    bottom: -90px;
    left: -26px;
    display: flex;
    gap: 24px;
    justify-content: initial!important;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}

.delete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
    height: 38px !important;
}

.formItem[class~="ant-form-item-has-error"] .inputField,
.formItem[class~="ant-form-item-has-error"] .inputField.maskedField,
.formItem[class~="ant-form-item-has-error"] .selectField fieldset legend,
.formItem[class~="ant-form-item-has-error"] .selectField.autocompleteField [class~="ant-select-selector"] {
    border-color: transparent!important;
    border-bottom-color: red !important;
}

.activation {
    width: 132px;
    height: 132px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 14px;
    margin-left: calc(50% - 66px);
}
.activationText {
    display: inline-block;
    align-self: flex-start;
    padding: 4px 6px;
    background-color: white;
    color: #000;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 2;
}
.activationWrapper {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 5px solid #042E6B;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activationIcon svg {
    font-size: 40px;
}
.activationIcon .iconEmpty svg {
    fill: #74706B;
}
.activationIcon .iconActive svg {
    fill: #0C5541;
}
