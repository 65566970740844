.title {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 20px;
}

.body {
  display: flex;
  justify-content: space-between;
}

.bodyLeft, .bodyRight {
  flex: 0 0 calc(50% - 81px);
  max-width: calc(50% - 81px);
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.bodyLeft {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 6px;
}

.bodyRight {
  display: flex;
  align-items: center;
  padding-left: 6px;
}

.widget {
  height: 146px;
  width: 162px;
  position: relative;
  z-index: 0;
}

.bodyCenter {
  background-image: url("./bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

.avatar {
  bottom: -10px;
  left: 50%;
  margin-left: -31px;
  position: absolute;
  width: 62px;
  height: 62px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.avatar img {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid white;
}

.widgetPercents {
  width: 112px;
  height: 112px;
  border-radius: 112px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  top: calc(50% - 46px);
  left: calc(50% - 56px);
}

.widgetPercents::after {
  display: block;
  content: '';
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100px;
  border: 1px solid #E7E8EF;
}

.widgetPercentsTitle {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 13px;
}

.withClick {
  cursor: pointer;
}

.withClick:hover {
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 #3b49571a;
}

.withAvatar .widgetPercentsTitle {
  margin-top: -24px;
}

.widgetPercentsValue {
  color: #000;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
}

.withAvatar .widgetPercentsValue {
  margin-top: -14px;
}

.widgetArrow {
  width: 162px;
  height: 16px;
  position: absolute;
  top: calc(50% + 2px);
  left: 0;
  transform: rotate(0deg);
  transition: transform .8s ease;
}

.blackArrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 12px solid #3C3B4E;
  border-bottom: 6px solid transparent;
  position: absolute;
  left: 14px;
  top: 0;
}

.point {
  position: absolute;
  left: -3px;
  top: -1px;
  width: 14px;
  height: 14px;
  background: white;
  border-radius: 50%;
  border: 2px solid #666;
}

.point::after {
  width: 6px;
  height: 6px;
  content: '';
  display: block;
  border-radius: 50%;
  background: #666;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
}
