.badge {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.badge.isUser, .badge.isAdmin {
  background-image: url('/public/icons/icon-farmer.svg');
}

.badge.isManager {
  background-image: url('/public/icons/icon-partner.svg');
}

.overlay [class~="ant-tooltip-inner"] {
  background: #042E6B;
  border-radius: 4px;
}
.overlay [class~="ant-tooltip-arrow-content"]::before {
  background: #042E6B;
}
.content {
  font-size: 10px;
}
