.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    gap: 24px;
    margin-top: 30px;
}

.col {
    width: 50%;
}

.values {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin-top: 16px;
}

.property {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.label {
    width: 130px;
    min-width: 130px;
    font-size: 18px;
}
.label:first-letter {
    text-transform: uppercase;
}

.value {
    appearance: none;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10);
    font-family: "Outfit", sans-serif;
    width: 100%;
    max-width: 247px;
}
.value.centered {
    text-align: center;
}
.value:focus {
    outline: none;
    border-color: #002A77;
}
.value[disabled] {
    background: transparent;
    border-bottom: none;
}

.value[class$="MuiSelect-root"]:before {
    border-bottom: 1px solid rgba(0, 42, 119, 0.1);
}
.value[class$="MuiSelect-root"]:hover:before {
    border-bottom: 1px solid !important;
}
.actions {
    margin-top: 25px;
    display: flex;
    gap: 24px;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}

.cancel {
    border-radius: 8px !important;
    border: 1px solid #D72B2C !important;
    background: transparent !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}
