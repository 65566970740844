._ {
	margin-top: 25px;
	margin-bottom: 25px;
}

.wrap {
	width: 100%;
}

.title {
	font-size: 25px;
	font-weight: 500;
}

.item {
	border-radius: 10px;
	border: 1px solid #042E6B;
	background: #FFF;
	min-height: 169px;
	position: relative;
}

.itemLink {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	color: #000000 !important;
	padding: 20px 22px 25px;
}
.itemLink:hover {
	color: #000000;
}

.itemTitle {
	font-size: 22px;
	font-weight: 600;
}
.copyIcon {
	font-size: 18px!important;
	line-height: 1!important;
	transform: translateY(-2px);
}

.itemAddress {
	font-size: 16px;
	display: inline-flex;
	justify-content: space-between;
}

.itemRemove {
	appearance: none;
	top: 6px;
	right: 7px;
	width: 18px;
	height: 18px;
	position: absolute;
	cursor: pointer;
}

.newItem {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	border: 2px dashed #042E6B;
	min-height: 169px;
	position: relative;
}

.iconPlus {
	color: #042E6B;
	font-size: 35px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.address {
	border-radius: 8px;
	border: 1px solid #042E6B;
	background: #FFF;
	padding: 21px 29px 22px;
}

.addressTitle {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 20px;
}

.addressForm {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.addressActions {
	display: flex;
	flex-direction: row;
	gap: 25px;
	margin-top: 20px;
}

.addressSave {
	border-radius: 8px !important;
	background: #042E6B !important;
	font-size: 15px !important;
	font-family: Outfit, sans-serif !important;
	font-weight: 500 !important;
	text-transform: initial !important;
	padding: 10px 55px !important;
}

.addressRemove {
	border-radius: 8px !important;
	border: 2px solid #D32F2F !important;
	color: #D32F2F !important;
	font-size: 15px !important;
	font-family: Outfit, sans-serif !important;
	font-weight: 500 !important;
	text-transform: initial !important;
	padding: 10px 45px !important;
}
.addressRemove:hover {
	border: 2px solid #D32F2F !important;
}

.add {
	margin-top: 30px !important;
	background: #005F39 !important;
	font-family: Outfit, sans-serif !important;
	font-size: 15px !important;
	font-weight: 500 !important;
	padding: 10px 58px !important;
	border-radius: 8px !important;
	text-transform: initial !important;
}

.error {
	border-bottom: 1px solid red !important;
}
