.page [class~="RaCreate-card"],
.page [class~="RaEdit-card"] {
    box-shadow: initial!important;
}

.toolbar {
    padding: 15px 0 0 !important;
}
.toolbar .removeBtn {
    order: initial!important;
}
.toolbar .viewBtn {
    color: #002A77!important;
}
.returnBtn {
    text-transform: none;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
}
