.wrap {
    height: initial!important;
    .box {
        padding-bottom: 0 !important;
    }
    .chart {
        margin-top: -40px;
    }
    .labels {
        margin-top: 30px;
        padding: 0 20px 0 0;
        max-height: 260px;
        overflow-y: auto;
    }
    .labels.oneRow {
        max-width: 335px;
    }
    .labels.multiRows {
        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(46%, 1fr));
            column-gap: 16px;
        }
    }
    .label {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;
    }
    .labelColor {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        border-radius: 5px;
    }
    .labelText {
        margin-left: 10px;
        min-width: 120px;
        flex: 1;
        text-transform: capitalize;
    }
    .labelValue {
        justify-self: flex-end;
    }
    [class~="ant-empty"] {
        min-height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .loader {
        min-height: 260px;
    }
}
