.wrapper table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.wrapper table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}
.wrapper table[class~="MuiTable-root"] th[class~="column-id"],
.wrapper table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}
.wrapper table[class~="MuiTable-root"] th[class~="column-message"],
.wrapper table[class~="MuiTable-root"] td[class~="column-message"] {
  width: auto;
}
.wrapper table[class~="MuiTable-root"] th[class~="column-status"],
.wrapper table[class~="MuiTable-root"] td[class~="column-status"] {
  min-width: 105px;
  max-width: 105px;
  text-align: center;
}
.wrapper table[class~="MuiTable-root"] th[class~="column-statusDetail"],
.wrapper table[class~="MuiTable-root"] td[class~="column-statusDetail"] {
  min-width: 90px;
  max-width: 100px;
  text-align: center;
}
.wrapper table[class~="MuiTable-root"] th[class~="column-createdAt"],
.wrapper table[class~="MuiTable-root"] td[class~="column-createdAt"] {
  width: 170px;
}

.search {
  width: 186px;
}
