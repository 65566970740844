.grid table th[class~="column-costCategory.name"],
.grid table td[class~="column-costCategory.name"],
.grid table th[class~="column-descriptionName"],
.grid table td[class~="column-descriptionName"] {
    width: 200px!important;
    min-width: 200px!important;
    max-width: 200px!important;
}
.grid table td[class~="column-costCategory.name"] > span,
.grid table td[class~="column-descriptionName"] > span {
    display: inline-block!important;
    max-width: 100% !important;
    -webkit-line-clamp: 1!important;
    -webkit-box-orient: vertical!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
}
