.actions {
  display: block;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.row:not(:last-child) {
  margin-bottom: 20px;
}
.col:not(:last-child) {
  margin-right: 15px;
}
.product {
  min-width: 400px;
}
.price {
  width: 180px;
}
.select {
  width: 100%;
}
.input {
  display: block;
  width: 100%;
}
.externalInput {
  width: 230px !important;
}
.actions {
  margin-top: 20px;
}
.remove {
  padding-top: 26px;
}
.number {
  width: 16px;
}
.numberValue {
  line-height: 32px;
  padding-top: 22px;
}
.summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 320px;
  margin-bottom: 20px;
}
.summaryTitle {
  font-size: 16px;
}
