.wrap {
    margin-top: 30px;
    padding: 28px;
    min-height: 1200px;
    justify-content: initial;
}
.initialPage [class~="ip-header-right"] {
    display: flex;
    align-items: flex-end;
}
.initialPage.hide {
    position: fixed;
    width: 1360px;
    top: -10000px;
}
.icon {
    margin-right: 8px;
}
.exportBtn, .secondExportBtn {
    width: 130px!important;
    height: 36px!important;
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    background: #FFF!important;
    color: #042E6B!important;
    font-family: Outfit, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal!important;
    text-transform: initial!important;
}
.secondExportBtn {
    margin-top: 12px!important;
}
.secondExportBtn.hide {
    display: none;
}
.headerRow {
    border-bottom: 3px #042E6B80 solid;
    padding-bottom: 12px;
}
.headerLogo {
    width: 136px;
    height: 48px;
    background-image: url('/public/icons/logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.headerCol:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
}
.headerCol:first-child::after {
    content: '';
    position: absolute;
    background-color: #042E6B80;
    width: 3px;
    height: 76px;
    right: -15px;
    bottom: 0;
}
.headerTitle {
    margin-left: 15px;
    padding-left: 30px;
    position: relative;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: normal;
}
.headerTitle::before {
    content: '';
    position: absolute;
    display: block;
    left: -15px;
    top: 15px;
    width: 50px;
    height: 2px;
    transform: rotate(-75deg);
    flex-shrink: 0;
    background-color: #042E6B;
}
.headerLine {
    display: flex;
    align-items: center;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}
.headerLine:first-child {
    margin-bottom: 6px;
}
.headerLineLabel {
    min-width: 110px;
}
.questionHeader {
    display: flex;
    align-items: center;
    padding: 20px 0;
}
.empty {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding-top: 30px;
}
.questionHeaderCnt {
    flex: 0 0 34px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #042E6B;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}
.questionHeaderTitle {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding-left: 24px;
    flex: 0 0 calc(100% - 34px);
    max-width: calc(100% - 34px);
}
.counter {

}
@media screen and (min-width: 1240px) {
    .wrap {
        padding: 28px 52px 60px;
    }
    .headerRow {
        display: flex;
        gap: 30px;
        padding-bottom: 0;
    }
    .headerCol {
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
    }
    .headerCol:first-child {
        position: relative;
        padding-bottom: 24px;
    }
    .headerCol:first-child::after {
        display: block;
    }
    .bodyRow {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
    .bodyCol {
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
    }
}

@media screen and (min-width: 1400px) {
    .headerRow {
        gap: 100px;
    }
    .headerCol {
        flex: 0 0 calc(50% - 50px);
        max-width: calc(50% - 50px);
    }
    .headerCol:first-child {
        justify-content: flex-end;
    }
    .headerCol:first-child::after {
        right: -50px;
    }
    .bodyRow {
        gap: 70px;
    }
    .bodyCol {
        flex: 0 0 calc(50% - 35px);
        max-width: calc(50% - 35px);
    }
}
