._ {
  padding-top: 25px;
  padding-left: 92px;
  padding-right: 53px;
}

.head {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 40px;
}

.headActions {
  margin-left: auto;
}

.input {
  border: none;
  border-bottom: 1px solid rgba(0, 42, 119, 0.10);
  font-size: 18px;
  font-weight: 500;
  border-radius: 0;
  width: 250px;

  &:hover {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10);
  }

  &:focus {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10);
  }

  &[class~="ant-input-status-error"] {
    box-shadow: none !important;
  }
}

.formItem label {
  font-size: 18px;
  font-weight: 500;
  margin-top: 1px;
  margin-right: 26px;
}

.remove {
  white-space: nowrap;
  padding: 0 14px !important;
  font-weight: 600;
  color: #042E6B !important;
  display: inline-flex;
  gap: 13px;
  border-radius: 8px !important;
  border: 1px solid #042E6B !important;
  text-transform: none !important;
  height: 40px;
  align-items: center;

  &:hover {
    background: #F0F3FB;
  }
}

.removeReadonly {
  opacity: 0.3;
}

.labels {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 62px;
}

.col {
  width: calc(100% / 3);
}

.colLabel {
  font-size: 18px;
  font-weight: 500;
  padding: 21px 0;
  text-align: center;
}
.col:nth-child(1) .colLabel {
  text-align: left;
}

.total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #042E6B;
  width: calc(100% - 60px);
  padding-top: 10px;
  padding-bottom: 40px;
}
.totalLabel {
  color: #042E6B;
  font-size: 16px;
  font-weight: 700;
}
.totalValue {
  color: #042E6B;
  font-size: 16px;
  font-weight: 700;
  width: calc(100% / 3);
  text-align: center;
}
