.page {
  padding: 30px 44px;
}

.form {
  padding: 0 !important;
}

.wrapper {
  padding-bottom: 24px;
}

.wrapperCol {
  display: flex;
  flex-direction: column;
}

.wrapperColFirst, .wrapperColLast {
  margin-bottom: 24px;
}

@media all and (min-width: 1200px) {
  .wrapperColFirst, .wrapperColLast {
    margin-bottom: 0;
  }
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 26px 24px;
  margin-bottom: 25px;
}

.block:last-child {
  margin-bottom: 0;
}

.blockFullHeight {
  height: 100%;
  min-height: 100%;
}

.blockHeader {
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blockMiddle {
  display: flex;
  justify-content: space-between;
}

.blockMiddleIcon {
  flex: 0 0 130px;
  max-width: 130px;
  padding-top: 12px;
}

.blockMiddleInfo {
  flex: 0 0 calc(100% - 130px);
  max-width: calc(100% - 130px);
}

.blockCompanyIcon {
  flex: 0 0 80px;
  max-width: 80px;
  padding-top: 12px;
}

.blockCompanyInfo {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}

.type {
  font-size: 18px;
  color: #000;
}

.formRow {
  margin-top: 0 !important;
  min-height: initial;
}

.formLabelCol {
  font-size: 18px;
  font-weight: 400;
}

.passwordLabelCol {
  color: #272727;
  font-size: 15px;
  font-weight: 500;
}

.mainFormRow {
  margin-bottom: 4px;
  min-height: 40px;
}

.checkboxRow {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}

.formRow div[class~="MuiInputBase-root"] {
  margin-top: 0 !important;
  min-height: initial;
}

.mainInnerRow {
  margin-top: 24px;
}

.mainInnerCol {
  margin-bottom: 18px;
}

.mainInnerCol:last-child {
  margin-bottom: 0;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
  display: none !important;
}

.formValueCol p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
  display: initial !important;
  color: red !important;
}

.formValueCol input[class~="MuiInput-input"] {
  padding: 0 !important;
  color: #000;
  -webkit-text-fill-color: #000;
  font-family: Outfit, sans-serif;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.formValueCol.formInfoValue input[class~="MuiInput-input"],
.formValueCol.formInfoValue div[class~="MuiInputBase-root"],
.formValueCol.formInfoValue span[class~="MuiTypography-root"] {
  font-size: 18px !important;
}
.formValueCol.formFooterValue input[class~="MuiInput-input"] {
  font-size: 16px !important;
}
.formValueCol [class~="ra-input-gender"] {
  min-width: 100% !important;
}
.formValueCol [id="gender"] {
  color: #000 !important;
  font-family: Outfit, sans-serif;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-bottom: 0!important;
}


.actions {
  padding: 0 !important;
  min-height: initial !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: transparent !important;
}

.action {
  width: 200px !important;
  height: 38px !important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.actionSave {
  color: #FFF !important;
  background: #042E6B !important;
}

.actionIcon {
  margin-right: 10px !important;
  font-size: 18px !important;
}

.mainPasswordInfo {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #E1E5ED;
  padding: 19px 26px;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 34px;
}

.mainPasswordCol {
  flex: auto;
}

.switcherField * {
  font-size: 18px !important;
  color: #000 !important;
}
