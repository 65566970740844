.box {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}
.order {
  width: 500px;
  min-width: 500px;
  padding: 12px 0;
  box-shadow: 0 4px 8px 0 #3b49571a;
  border: 1px solid #eee;
  border-radius: 18px;
  background-color: white;
}
.controls {
  padding: 12px 20px;
}

/* HEADER */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #555466;
}
.created {
  margin: 0 10px;
}
.cost {
  margin: 0 10px;
}
.status {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.isWaitingIcon, .isPaidIcon, .isRejectedIcon {
  font-size: 30px;
  margin-left: 10px;
}
.isWaiting {
  color: #002A77;
}
.isWaitingIcon {
  color: #002A77;
}
.isPaid {
  color: #005F39;
}
.isPaidIcon {
  color: #005F39;
}
.isRejected {
  color: darkorange;
}
.isRejectedIcon {
  color: darkorange;
}

/* CONTENT */
.content {
  display: flex;
  width: 100%;
  padding: 0 15px;
  justify-content: space-between;
}
.positions {
  max-width: 400px;
  flex-shrink: 0;
  flex-grow: 1;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 24px;
  margin-left: 26px;
  border-left: 1px solid #eee;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
}
.value {
  color: #171725;
}
.details {
  text-align: right;
}

/* POSITION */
.position {
  margin-bottom: 15px;
}
.product {
  display: flex;
  width: 100%;
  background-color: white;
  cursor: pointer;
  text-decoration: none !important;
}
.productImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 140px;
  min-width: 140px;
  height: 110px;
  background-size: 95%;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px 0 #3B49571A;
  border: 1px solid #eee;
  border-radius: 12px;
}
.productIcon {
  font-size: 80px;
  color: lightgray;
  opacity: 0.5;
}
.productInfo {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  flex-grow: 1;
}
.productTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.productName {
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  color: #171725;
}
.productBrand {
  margin-top: 8px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #616D81;
}
.productPrice {
  font-size: 18px;
  line-height: 20px;
  color: #171725;
}

@media (max-width: 840px) {
  .order {
    padding: 15px 0;
  }
  .title, .status {
    font-size: 16px;
    line-height: 18px;
  }
  .content {
    flex-direction: column;
    padding: 0 15px;
  }
  .info {
    margin-top: 10px;
    padding-top: 15px;
    margin-left: 0;
    padding-left: 0;
    border-top: 1px solid #eee;
    border-left: none;
  }
}

@media (max-width: 460px) {
  .product {
    flex-direction: column;
  }
  .productInfo {
    margin-top: 10px;
    padding-left: 0;
  }
}
