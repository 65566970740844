.cardWrapper {
    min-height: 200px;
}
.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}
.cardWrapper [class~="InitialCard-left"] {
    font-size: 16px;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}
.wrap {
    padding-top: 14px;
}

.title {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 15px;
}

.value {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 15px;

    &.last {
        padding-bottom: 0;
    }
}
