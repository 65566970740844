.html {
  font-size: 15px;
  line-height: 18px;
  color: #333;
  margin-top: 10px;
}
.html ol, .html ul {
  padding-left: 15px;
}
.html img {
  max-width: 100% !important;
  max-height: 600px !important;
}
