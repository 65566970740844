.block {
  padding: 32px 20px 18px;
}

.headerIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.headerTitle {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}

.headerChildren {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 14px;
}