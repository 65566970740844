.files {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.file {
  margin-right: 14px;
  margin-bottom: 14px;
}

.uploadBox {
  margin-top: 15px;
  background-color: white;
}

.uploadText {
  margin-top: 8px;
}
