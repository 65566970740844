.block {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
  margin-top: 22px;
}

.row {
  display: flex;
  align-items: center;
  border-top: 1px solid #042E6B;
}

.row:first-child {
  border: none;
}

.col {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 2px 20px;
  min-height: 34px;
}

.colTitle {
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.colMenu {
  border-left: 1px solid #042E6B;
  background: #F0F3FB;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed {
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
}

.dropdown {
  /*max-width: 110px;*/
}

.dropdownOverlay {
  border-radius: 8px!important;
  border: 1px solid #042E6B!important;
  background: #F0F3FB!important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)!important;
  overflow: hidden!important;
}

.selected, .selectedText {
  border-radius: 10px;
  border: 1px solid #042E6B;
  padding: 3px;
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;

  &.empty {
    min-height: 21px;
  }
}

.selectButton {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.selectButton.empty .selectedText {
  border: none;
}

.arrowIcon {
  color: #042E6B;
}

.selectedText {
  min-width: 77px;
  margin-right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}
