.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-position"],
.grid table[class~="MuiTable-root"] td[class~="column-position"] {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-nameMx"],
.grid table[class~="MuiTable-root"] td[class~="column-nameMx"] {
  min-width: 100px;
  max-width: 100px;
}

.grid table[class~="MuiTable-root"] th[class~="column-requirements"],
.grid table[class~="MuiTable-root"] td[class~="column-requirements"] {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-withCustomAnswer"],
.grid table[class~="MuiTable-root"] td[class~="column-withCustomAnswer"],
.grid table[class~="MuiTable-root"] th[class~="column-isTable"],
.grid table[class~="MuiTable-root"] td[class~="column-isTable"],
.grid table[class~="MuiTable-root"] th[class~="column-isEnabled"],
.grid table[class~="MuiTable-root"] td[class~="column-isEnabled"],
.grid table[class~="MuiTable-root"] th[class~="column-isOptional"],
.grid table[class~="MuiTable-root"] td[class~="column-isOptional"],
.grid table[class~="MuiTable-root"] th[class~="column-isMultiple"],
.grid table[class~="MuiTable-root"] td[class~="column-isMultiple"] {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}
