.subTitle {
    font-weight: 400!important;
    font-size: 18px!important;
}

.topRow {
    margin-bottom: 24px!important;
}
.rowTopIn {
    margin-top: 24px;
}

.label {
    font-size: 16px;
    font-weight: 600;
}
.value {
    font-size: 16px;
}