.removeBox {
  padding-top: 10px;
}

.removeBtnIcon {
  margin-right: 10px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.colAnswer {
  margin-bottom: 8px;
}

.enabled span[class~="MuiFormControlLabel-label"] {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.removeIcon {
  color: #FF4D4F;
}

.removeText {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.textField fieldset, .textField fieldset:focus {
  border-radius: 8px;
  border: 1px solid #042E6B!important;
}

.textField input{
  font-family: Outfit, sans-serif;
  font-weight: 400!important;
}

.btn, .btn:hover {
  border-radius: 8px!important;
  background: #042E6B!important;
  width: 200px!important;
  height: 38px!important;
  color: #E1E5ED!important;
  text-align: center!important;
  font-family: Outfit, sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: normal!important;
  text-transform: initial!important;
  margin-top: 17px!important;
}

.btn:hover {
  opacity: .9;
}

.row {
  display: flex;
}

.wrap {
  display: flex;
}

.inputLabel {
  color: #000;
  font-family: Outfit, 'sans-serif';
  font-size: 13px;
  padding-bottom: 9px;
  display: block;
}

.actions {
  margin-top: 22px;
}

.input {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
}

.error {
  font-size: 12px;
  color: red;
}
