.page td {
  text-align: center !important;
}

.grid table th[class~="column-code"],
.grid table td[class~="column-code"] {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.grid table th[class~="column-account.account"],
.grid table td[class~="column-account.account"] {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
.grid table th[class~="column-credit.code"],
.grid table td[class~="column-credit.code"] {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.grid table th[class~="column-amount"],
.grid table td[class~="column-amount"] {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.grid table th[class~="column-paymentDate"],
.grid table td[class~="column-paymentDate"] {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.grid table th[class~="column-credit.accredited"],
.grid table td[class~="column-credit.accredited"] {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
.grid table th[class~="column-purpose"],
.grid table td[class~="column-purpose"] {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.grid table th[class~="column-sent"],
.grid table td[class~="column-sent"] {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}
