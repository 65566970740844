.cardWrapper {
    min-height: 301px;
}
.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}

.wrap {

}

.balanceValue {
    margin-top: 24px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.balanceText {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    width: 100%;
}

.balanceValueText {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    width: 100%;
}

.parIcon {
    width: 62px;
    height: 62px;
    background-image: url('/public/icons/par-icons/par-5.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.variant_1 {
        background-image: url('/public/icons/par-icons/par-1.svg');
    }

    &.variant_2 {
        background-image: url('/public/icons/par-icons/par-2.svg');
    }

    &.variant_3 {
        background-image: url('/public/icons/par-icons/par-3.svg');
    }

    &.variant_4 {
        background-image: url('/public/icons/par-icons/par-4.svg');
    }
}

.invoices {
    margin-top: 22px;
}

.invoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #042E6B;
}

.invoiceIndicator {
    display: inline-block;
    background-color: #FF0000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 9px;
}
.invoiceIndicator.invisible {
    background-color: transparent;
}
.invoiceTitle, .invoiceCounter {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
