.form {
  margin-top: 20px;
  margin-bottom: 16px;
  border: 1px solid #042E6B;
  border-radius: 10px;
  overflow: hidden;
}

.row {
  border-bottom: 1px solid #042E6B;
  display: flex;
  min-height: 34px;

  &:last-child {
    border: none;
  }
}

.col {
  display: flex;
  align-items: center;
  min-height: 100%;

  &.colLabel {
    color: #042E6B;
    flex: 0 0 40%;
    max-width: 40%;
    padding: 2px 6px;
    font-size: 12px;

    @media screen and (min-width: 1200px) {
      padding: 2px 6px 2px 24px;
    }
  }

  &.colValue {
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    padding: 2px 6px;
    position: relative;
    font-size: 13px;
    align-items: center;
    border-left: 1px solid #042E6B;
    line-height: 1.1;
    background-color: #F0F3FB;

    @media screen and (min-width: 1200px) {
      padding: 2px 24px 2px 6px;
    }

    &.long_answer {
      cursor: text;
    }

    &.fixed {
      padding: 2px 6px 2px 6px;
    }
  }
}

.fixedField {
  display: inline-block;
  padding-left: 6px;
}

.fieldIcon {
  display: inline-block;
  flex: 0 0 13px;
  max-width: 13px;
  line-height: 1;
  color: #777;
}

.numberInput {
  flex: 0 0 calc(100% - 13px);
  max-width: calc(100% - 13px);

  input {
    padding: 0 0 0 6px!important;
  }
}

.shortInput {
  padding: 0 0 0 6px!important;
}

.longFieldView {
  width: 222px;
  padding-left: 6px;
  margin-bottom: 0!important;
}

.fixedText {
  margin-left: 6px;
}

.selectField {
  width: 100%;
  padding-left: 6px;

  [class~="ant-select-selector"] {
    padding: 0!important;
    background-color: transparent!important;
    border-bottom-color: transparent!important;
  }

  [class~="ant-select-arrow"] {
    right: -6px!important;
  }

  [class~="ant-select-selection-item"] {
    padding-right: 8px!important;
  }
}

.selectFieldPopup {
  border: 1px solid #042E6B;
  padding: 0!important;
  border-radius: 8px!important;

  [class~="ant-select-item-option"] {
    &[class~="ant-select-item-option-selected"] {
      font-weight: 400!important;
      background-color: #F0F3FB!important;
    }
  }
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
