.pageWrap {
  display: flex;
  flex-direction: column;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: center;
  flex-grow: 1;
}

.wrap {
  margin-top: 20px;
}

.score {
  padding-bottom: 60px;
}

.btn {
  text-transform: initial!important;
  width: 200px;
  height: 38px;
  margin-top: 10px;
  position: absolute;
  bottom: 0;

  @media screen and (min-width: 1200px) {
    bottom: 40px;
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 30px;
}

.grid {
  display: flex;
  width: 100%;
}

.gridCol.gridStart {
  flex: 0 0 80px;
  max-width: 80px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding-top: 6px;
}

.gridCol.gridEnd {
  flex: 0 0 110px;
  max-width: 110px;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding-top: 6px;
}

.gridCol.gridSlider {
  flex: 0 0 auto;
  flex-grow: 1;
  max-width: initial;
}

.gridSlider {
  [class~="ant-slider-dot"] {
    width: 1px!important;
    height: 30px!important;
    background-color: black!important;
    border: none!important;
    border-radius: 0!important;
    top: -11px!important;
    transform: none!important;
  }

  [class~="ant-slider-rail"] {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    border-radius: 5px;
    height: 10px!important;
    background: linear-gradient(90deg, #F65E5E 0%, #FFEF5C 34.87%, #54E29A 77%)!important;
  }

  [class~="ant-slider-track"] {
    height: 10px!important;
    background-color: transparent!important;
  }

  [class~="ant-slider-handle"] {
    width: 42px!important;
    height: 42px!important;
    background: #55E39B!important;
    border-radius: 50%!important;
    margin-top: -15px!important;
    border: 4px solid white!important;
    box-shadow: none!important;
    z-index: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;


    }

    &::after {
      width: 32px!important;
      height: 32px!important;
      background: #55E39B!important;
      border-radius: 50%!important;
      border: 10px solid white!important;
      top: 1px!important;
      left: 1px!important;
      right: initial!important;
      bottom: initial!important;
      box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
      z-index: 1;
    }
  }
}

.modal {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #002A77;
}

.modal.scoreModal [class~="ant-modal-content"] {
  min-height: initial!important;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.action {
  margin: 0 7px!important;
  text-transform: initial!important;
  width: 200px!important;
  height: 38px!important;
}
