._ {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 620px;
    z-index: 1000;
    background: #fff;
}

.wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 91px 60px 80px 56px;
    border-left: 1px solid #042E6B;
}

.close {
    min-width: 35px;
    min-height: 35px;
    background: url("/public/icons/close.svg") no-repeat top left;
    margin-left: auto;
    cursor: pointer;
}

.head {
    margin-top: 31px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
}

.title {
    font-size: 25px;
    font-weight: 500;
}

.date {
    margin-left: 24px;
    font-size: 14px;
}

.status {
    font-size: 25px;
    font-weight: 500;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.paid {
    width: 20px;
    height: 20px;
    background: url("/public/icons/check.svg") no-repeat top left;
    background-size: 100%;
}

.canceled {
    width: 20px;
    height: 20px;
    background: url("/public/icons/close.svg") no-repeat top left;
    background-size: 100%;
}

.content {
    margin-top: 33px;
    background: #E1E5ED;
    border: 1px solid #042E6B;
    border-radius: 8px;
}

.list {
    padding: 20px 31px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.total {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 31px;
    justify-content: space-between;
    border-top: 1px solid #042E6B;
}

.item {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
}

.itemImage {
    min-width: 137px;
    width: 137px;
    height: 140px;
    background: #042E6B;
    border-radius: 8px;
    padding: 6px;
}

.itemImage picture {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.itemImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.itemInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.itemDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.actions {
    margin-top: 25px;
    margin-left: auto;
}

.action {
    appearance: none;
    border: 1px solid #002A77;
    background: transparent;
    color: #002A77;
    font-size: 14px;
    font-family: "Outfit", sans-serif;
    padding: 10px 34px;
    border-radius: 8px;
    cursor: pointer;
}

.action:hover {
    background: #002A77;
    color: #FFFFFF;
}
