.wrap {
    border-radius: 12px;
    background: #74706B;
    color: #FFF;
    text-align: center;
    font-family: Outfit,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 3px 10px 4px;
    max-width: 300px;

    &.completed {
        background: #119BF0;
    }

    &.signature_in_process {
        background: #f38237;
    }

    &.signed {
        background: #0C5541;
    }
}
