.wrap {
    max-width: 480px;
    width: 100%;
}
.list {
    margin-top: 20px;
}
.empty {
    margin-bottom: 12px;
}
