.block {
  border-radius: 10px;
  border: 1px solid #042E6B;
  margin-top: 20px;
  overflow: hidden;
}

.row {
  display: flex;
  width: 100%;
}

.col {
  display: flex;
  align-items: center;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 0 16px;
  min-height: 48px;
}

.col.title {
  flex: 0 0 36%;
  max-width: 36%;
  color: #FFF;
  background: #042E6B;
}

.col.extra {
  border-right: 1px solid #042E6B;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 60px;
  display: flex;
  justify-content: center;
}

.col.select {
  flex: 1;
}

.col.percent {
  flex: 0 0 16%;
  max-width: 16%;
  border-left: 1px solid #042E6B;
  justify-content: center;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.dropdownBlock {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  justify-content: flex-end;
}

.dropdown {
  max-width: 110px;
}

.dropdownOverlay {
  border-radius: 8px!important;
  border: 1px solid #042E6B!important;
  background: #F0F3FB!important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)!important;
  overflow: hidden!important;
}

.info {
  flex: 0 0 30%;
  max-width: 30%;
}

.tooltip {
  color: red;
}

.tooltipOverlay [class~="ant-tooltip-arrow-content"]::before {
  background: #042E6B!important;
}

.tooltipOverlay [class~="ant-tooltip-inner"] {
  border-radius: 8px;
  background: #042E6B;
  padding: 0;
  border: 1px solid rgb(87, 122, 187) !important;
  border-bottom: none!important;
}

.infoBlock {
  padding: 12px 16px;
  min-width: 140px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.infoRow:last-child {
  margin-bottom: 0;
}

.infoCol {
  color: #FFF;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.infoCol:first-child {
}

.infoCol:last-child {
  padding-left: 32px;
  font-weight: 500;
}

.tooltipOverlay.empty {
  display: none!important;
}

.selected, .selectedText {
  border-radius: 10px;
  border: 1px solid #042E6B;
  padding: 3px;
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
}

.selectButton {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.selectButton.empty .selectedText {
  border: none;
}

.arrowIcon {
  color: #042E6B;
}

.selected.orange, .selectedText.orange {
  background: #FF7A45;
  border: 1px solid #FF7A45;
  color: #FFF;
}

.selected.blue, .selectedText.blue {
  background: #1677FF;
  border: 1px solid #1677FF;
  color: #FFF;
}

.selected.green, .selectedText.green {
  background: #7CB305;
  border: 1px solid #7CB305;
  color: #FFF;
}

.selectedText {
  min-width: 70%;
  margin-right: 10px;
}

.info {
  padding-left: 10px;
}

.colored {
  background: #F0F3FB;
}
