.wrap [class~="InitialCard-left"] {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cardTitle:not(:hover) span {
  color: #042E6B !important;
}

.top {
  flex: 1;
}

.info {
  margin-top: 5px;
}
.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(4, 46, 107, 0.50);
}
.infoRow:last-child {
  border-bottom: none;
}

.infoIcon {
  font-size: 20px;
  color: #042E6B;
}
.infoIcon.error {
  color: #FF0A0A;
}
.infoIcon.pending {
  color: #AAA;
}

.footer {
  padding-top: 6px;
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer * {
  margin: 0 3px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.controlsBtn {
  text-transform: none !important;
  border-radius: 18px !important;
}

.extraction {
  margin-top: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(4, 46, 107, 0.50);
}

.extractionTitle {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}
