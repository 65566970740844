.list {
    width: 100%;

    & [class~="ant-table-thead"] {
        & [class~="ant-table-cell"] {
            padding: 8px 16px !important;
            min-height: 47px !important;
            background: #E1E5ED !important;
            border-bottom: 1px solid #042E6B !important;
            position: relative;
            z-index: 1;
        }

        & [class~="ant-table-cell"]:not(:last-child) {
            border-right: 1px solid #042E6B !important;
        }

        & [class~="ant-table-cell-scrollbar"] {
            box-shadow: 0 0 0 1px #E1E5ED !important;
        }
    }

    & [class~="ant-table-tbody"] {
        & [class~="ant-table-row"] td {
            padding: 8px 16px !important;
            min-height: 47px !important;
        }
        & [class~="ant-table-row"]:not(:last-child) td {
            border-bottom: 1px solid #042E6B !important;
        }

        & [class~="ant-table-cell"]:not(:last-child) {
            border-right: 1px solid #042E6B !important;
        }
    }

    & [class~="ant-table-container"] {
        border: 1px solid #042E6B !important;
        border-radius: 8px !important;
        overflow: hidden;
    }

    & [class~="ant-table-placeholder"] {
        & [class~="ant-table-cell"] {
            max-height: 150px !important;
        }
    }
}