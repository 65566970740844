.title {
  margin-bottom: 0;
  padding: 0 0 4px;
  color: #000;
  font-size: 25px!important;
  font-weight: 600!important;
  line-height: normal!important;
}

.linkBack {
  display: inline-block!important;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px!important;
}

.linkBack:hover svg path {
  fill: #40a9ff;
}

.linkBack:hover .linkBackText {
  color: #40a9ff;
}

.linkBackText {
  display: inline-block;
  margin-left: 10px;
  color: #000;
}
