.name {
    font-size: 16px;
}

.state {
    font-size: 14px;
}

.credit {
    margin-top: 17px;
}

.creditLabel {
    font-size: 14px;
}

.creditCode {
    font-size: 20px;
    font-weight: 700;
}

.image {
    padding-left: 57px;
    padding-right: 57px;
    width: 102px;
    height: 102px;
    box-sizing: content-box !important;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
