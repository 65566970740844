.grid table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.grid table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-id"],
.grid table[class~="MuiTable-root"] td[class~="column-id"] {
  min-width: 56px;
  max-width: 56px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-status"],
.grid table[class~="MuiTable-root"] td[class~="column-status"] {
  min-width: 105px;
  max-width: 105px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] td[class~="column-createdAt"],
.grid table[class~="MuiTable-root"] th[class~="column-closedAt"],
.grid table[class~="MuiTable-root"] td[class~="column-closedAt"] {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-phone"],
.grid table[class~="MuiTable-root"] td[class~="column-phone"] {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.grid table[class~="MuiTable-root"] th[class~="column-message"],
.grid table[class~="MuiTable-root"] td[class~="column-message"] {
  min-width: auto;
  width: auto;
}

.grid table[class~="MuiTable-root"] th[class~="column-firstName"],
.grid table[class~="MuiTable-root"] td[class~="column-firstName"],
.grid table[class~="MuiTable-root"] th[class~="column-middleName"],
.grid table[class~="MuiTable-root"] td[class~="column-middleName"],
.grid table[class~="MuiTable-root"] th[class~="column-lastName"],
.grid table[class~="MuiTable-root"] td[class~="column-lastName"] {
  min-width: 100px;
  max-width: 100px;
}

