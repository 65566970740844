.box {
  display: flex;
  padding-bottom: 25px;
}
.credit {
  flex-grow: 1;
  padding: 12px 0;
  box-shadow: 0 4px 8px 0 #3b49571a;
  border: 1px solid #eee;
  border-radius: 18px;
  background-color: white;
}
.controls {
  padding: 12px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 28px 17px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #555466;
}
.date {
  font-size: 14px;
  color: #888;
  margin-top: 3px;
}

.content {
  padding: 0 28px 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.row:not(:last-child) {
   margin-bottom: 12px;
 }

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
}
.value {
  color: #171725;
}

.details {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.link {
  color: #1890ff;
  border-bottom: 1px solid;
  cursor: pointer;
}
.link:hover {
  color: #0769c9;
}
