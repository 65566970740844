.cardContent {
    padding-top: 10px;

    @media screen and (min-width: 1024px) {
        padding: 10px 80px 0;
    }
}

.row {
    margin-top: 16px;
}

.fieldRow [class~="ir-label"] {
    color: #042E6B;
    font-weight: 600;
    margin-bottom: 26px;
}
.fieldRow.htmlField [class~="ir-label"] {
    margin-bottom: 16px;
}
.fieldRow.htmlField .html {
    color: rgba(51, 51, 51, 0.81);
    font-family: Outfit, sans-serif!important;
    font-size: 18px!important;
    font-weight: 400;
    line-height: normal;
}

.search {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    height: 37px;
}

.descField .value {
    margin-bottom: 0!important;
    border-radius: 8px;
    border: 1px solid #000;
    background: rgba(0, 42, 119, 0.16);
    padding: 16px;
    color: rgba(51, 51, 51, 0.81);
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
}
.value {
    margin-bottom: 0 !important;
}

.file {
    padding-top: 18px;
}

.dateRow {
    padding: 18px 0 18px;
    display: flex;
    justify-content: space-between;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    max-width: 550px;
    margin: 0 auto;
}
