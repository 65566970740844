.formWrap {
    position: relative;
}

.infoCol {
    display: flex;
    flex-direction: column;
}

.formItem [class~="ant-input"][disabled] {
    background: white!important;
}
.formItem.disabled label {
    color: rgba(0, 0, 0, .6) !important;
}
.formItem [class~="ant-form-item-label"] {
    text-align: left!important;
}
.formItem label, .labelCol {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.formItem label::before {
    display: none!important;
}
.formItem.checkboxItem {
    align-content: flex-end;
    flex-grow: 1;
}
.formItem.formItemHorizontal label {
    @media screen and (min-width: 576px) {
        transform: translateY(-8px) !important;
    }
}
.fieldRow {
    @media screen and (min-width: 993px) {
        position: relative;
    }
}
.fieldCol:last-child {
    @media screen and (min-width: 993px) {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
}

div.formItem.purposeField label {
    @media screen and (min-width: 768px) {
        transform: translateY(-8px);
    }
}

.conceptValue {
    display: flex;
    justify-content: center;
    transform: translateY(-2px);
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;

    @media screen and (min-width: 993px) {
        margin-bottom: 0;
        justify-content: flex-end;
    }

    @media screen and (min-width: 1600px) {
        width: 200px;
        justify-content: space-between;
    }
}
.conceptValueSplitter {
    width: 40px;
    display: flex;
    justify-content: center;
}

.customInputRow {
    @media screen and (min-width: 576px) {
        display: flex;
    }
    @media screen and (min-width: 768px) {
        margin-top: 6px;
        height: 46px;
    }
}
.customInputLabelCol {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 12px;
    line-height: 1.5715;

    @media screen and (min-width: 576px) {
        flex: 0 0 33%;
        max-width: 33%;
        transform: translateY(-8px);
    }
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 992px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1200px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 1600px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
.customInputLabelCol.disabled {
    color: rgba(0, 0, 0, 0.60);

    @media screen and (min-width: 768px) {
        padding-top: 8px;
    }
}
.customInputValueCol {
    margin-bottom: 24px;

    @media screen and (min-width: 576px) {
        flex: 1;
    }
}
.customInputValueCol input {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
    height: 32px!important;
}

.checkboxField {
    border-radius: 2px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 9px;
}
.checkboxField.disabled {
    filter: blur(2px);
}
.checkboxField [class~="ant-checkbox-inner"] {
    transform: translateY(-2px);
}
.checkboxField span {
    color: black!important;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.selectField {
    width: 100%;
}
.selectField [class~="MuiSelect-select"],
.selectField [class~="MuiSelect-icon"] {
    transform: translateY(-6px);
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.selectField fieldset {
    border: none!important;
    border-radius: initial!important;
    padding-left: 0!important;
    padding-right: 0!important;
}
.selectField fieldset legend {
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}
.selectField [class~="MuiSelect-select"] {
    padding: 0!important;
}
.selectField[class~="Mui-disabled"] [class~="MuiSelect-select"] {
    color: black!important;
    -webkit-text-fill-color: black!important;
    cursor: not-allowed;
}

.selectField.autocompleteField [class~="ant-select-selector"] {
    border-color: transparent!important;
    box-shadow: none!important;
    padding: 0!important;
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
    outline: none!important;
}
.selectField.autocompleteField [class~="ant-select-selection-search-input"] {
    font-size: 16px!important;
}
.selectField.autocompleteField [class~="ant-select-selection-search"] {
    left: 0!important;
    right: 0!important;
}
.selectField.autocompleteField.invalid [class~="ant-select-selector"] {
    border-bottom-color: red !important;
}
.selectField.autocompleteField[class~="ant-select-disabled"] [class~="ant-select-selector"] {
    background: none !important;
}
.option.disabledOption {
    opacity: .4;
}

.inputField {
    padding: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.inputField.maskedField {
    border: 0!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    box-shadow: none!important;
}
.inputField.moneyField {
    width: 100% !important;
}
.inputField.moneyField input {
    padding: 0!important;
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.inputField.moneyField[class~="ant-input-number-disabled"] {
  background-color: transparent!important;
}

.actions {
    position: absolute;
    bottom: -90px;
    left: -26px;
    display: flex;
    gap: 24px;
   justify-content: initial!important;
}

.save {
    border-radius: 8px !important;
    background: #042E6B !important;
    min-width: 200px !important;
    height: 38px !important;
    padding: 11px 24px !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    text-transform: initial !important;
    display: inline-flex !important;
    gap: 15px;
    box-shadow: none !important;
}

.delete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
    height: 38px !important;
}

.formItem[class~="ant-form-item-has-error"] .inputField,
.formItem[class~="ant-form-item-has-error"] .inputField.maskedField,
.formItem[class~="ant-form-item-has-error"] .selectField fieldset legend,
.formItem[class~="ant-form-item-has-error"] .selectField.autocompleteField [class~="ant-select-selector"] {
    border-bottom-color: red !important;
}

.selectField.autocompleteField input[class~="ant-select-selection-search-input"] {
    color: #000!important;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: normal!important;
}

.fileFieldLabel {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 12px;
}
.fileFieldLabel.disabled {
    color: rgba(0, 0, 0, 0.60);
}

.fileField {
    padding-bottom: 20px;
}

.uploadedBox {
    width: 400px;
    height: 210px;
    border-radius: 10px !important;
    border: 2px dashed #042E6B !important;
    background: #E1E5ED !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.uploadedBox .fileIcon {
    color: #042E6B;
    font-size: 30px;
}

.uploadedBox .fileImg {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.uploadBox {
    display: block;
    width: 400px;
    height: 210px;
    cursor: pointer;
}

.uploadContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 210px;
    align-items: center;
    position: relative;
}

.uploadContentBlock {
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 13px;
    height: calc(100% - 14px);
}

.uploadContentTitle {
    color: #042E6B;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 13px;
}

.uploadContentComment {
    height: 28px;
    color: #042E6B;
    text-align: center;
    font-size: 10px;
}

.removeImg {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100!important;
}

.removeImg::after {
    display: none;
    content: '';
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .55);
    box-shadow: 0 0 5px rgba(255, 255, 255, .7);
    z-index: 1;
}

.removeImg:hover::after {
    display: block;
}

.fileField.invalid [class~="ant-upload-select-picture-card"] {
    border-color: red!important;
}

.preview [class~="file-image-preview"] {
    width: initial!important;
    height: initial!important;
    max-width: 100% !important;
    max-height: 100% !important;
}
.previewDownload {
    display: block;
    z-index: 100;
    position: absolute;
    width: 45px;
    height: 48px;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -22px;
    cursor: pointer;
}
.previewImg {
    width: 400px;
    height: 210px;
    object-fit: contain;
}
