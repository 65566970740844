.row {
    @media screen and (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.col {
    min-height: 30px;
    display: flex;
    align-items: center;
}
.rowLast {
    justify-content: flex-end;

    .col {
        justify-content: flex-end;
    }
}
.userContacts {
    margin-top: 0;
    justify-content: flex-end;

    @media screen and (min-width: 1200px) {
        margin-left: 40px;
    }

    [class~="uc_contact"] {
        padding: 0;
    }
}
