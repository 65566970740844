.formContainer {
    input[class~="MuiInputBase-input"] {
        padding-bottom: 0!important;
    }
}
.switcher {
    padding-top: 20px;
}
.switcher label {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
}

