.cardWrapper {
    min-height: 200px;
}
.cardWrapper:not(:hover) {
    border-color: #042E6B!important;
}
.cardWrapper [class~="InitialCard-left"] {
    font-size: 16px;
}

.cardWrapper:hover .headerIcon {
    color: #40a9ff;
}

.headerIcon {
    color: #042E6B;
    font-size: 28px;
}
.wrap {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download, .update {
    border-radius: 5px;
    background: #042E6B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-family: Outfit,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    }
}
.update {
    justify-content: flex-end;
    padding-bottom: 4px;
}
.updateBottom {
    color: #FFF;
    font-family: Outfit,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
}
