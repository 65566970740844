.wrap {
  display: flex;
  height: 100%;
}
.card {
  padding: 12px 25px 18px;
  border-radius: 10px;
  border: 1px solid #042E6B;
  background: #FFF;
  color: #000;
  font-family: Outfit, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.name {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.info {
  margin-top: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deleteBtn {
  position: absolute !important;
  top: 0;
  right: 0;
}
