.padding {
  padding: 16px;
}

.scoreWidget {
  padding-top: 32px;
}

.tableCol:first-child {
  padding-bottom: 16px;
}

@media all and (min-width: 1600px) {
  .tableCol:first-child {
    padding-bottom: 0;
  }
}

.colFirst {
  margin-bottom: 20px;
}

@media all and (min-width: 1200px) {
  .colFirst {
    margin-bottom: 0;
  }
}

.aside {
  margin-top: 20px;
}

@media all and (min-width: 1200px) {
  .aside {
    margin-top: 0;
  }
}

.marginTop {
  margin-top: 20px;
}

.colored {
  background: #F0F3FB;
}

.bordered {
  border-radius: 10px;
}

.surveyExport {
  width: 200px!important;
  height: 38px!important;
  margin-top: 0!important;
}
