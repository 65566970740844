.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 32px;
  margin-bottom: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle {
  color: #000 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-family: Outfit, sans-serif;
  margin: 0 !important;
}

.btnAdd {
  padding: 10px 46px !important;
  text-transform: none !important;
  border-radius: 8px !important;
}

.btnAdd svg {
  font-size: 14px;
  margin-right: 8px;
}

/* LIST */
.list {
  margin-top: 14px;
}

.list div[class~="RaList-content"] {
  max-height: initial !important;
}

.list div[class~="RaList-main"] {
  padding: 0 !important;
}

.list div[class~="MuiPaper-root"] {
  box-shadow: none;
  background-color: transparent;
}

.list td {
  min-height: 34px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.list table[class~="MuiTable-root"] th[class~="MuiTableCell-paddingCheckbox"],
.list table[class~="MuiTable-root"] td[class~="MuiTableCell-paddingCheckbox"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 44px;
  max-width: 44px;
  text-align: center;
}

.list table[class~="MuiTable-root"] th[class~="column-name"],
.list table[class~="MuiTable-root"] td[class~="column-name"] {
  min-width: auto;
  max-width: initial;
  width: auto;
}

.list table[class~="MuiTable-root"] th[class~="column-phone"],
.list table[class~="MuiTable-root"] td[class~="column-phone"] {
  min-width: 170px;
  max-width: 170px;
  width: 170px;
}

.list table[class~="MuiTable-root"] th[class~="column-position"],
.list table[class~="MuiTable-root"] td[class~="column-position"] {
  min-width: 220px;
  max-width: 220px;
  width: 220px;
}


.list table[class~="MuiTable-root"] th[class~="column-email"],
.list table[class~="MuiTable-root"] td[class~="column-email"] {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
