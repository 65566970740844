.wrap {
  width: calc(100vw - 80px);
  padding-bottom: 80px;
  position: relative;
  padding-top: 40px;

  @media screen and (min-width: 992px) {
    width: calc(100vw - 324px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
}

.headerTitle {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
}

.headerTotal {
  color: #000;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.carousel {
  height: 100%;
  position: initial !important;

  [class~="swiper-button-prev"],
  [class~="swiper-button-next"] {
    top: initial!important;
    bottom: 0;
    border-radius: 8px!important;
    border: 0!important;
    background-color: #042E6B;
    width: 40px!important;
    height: 40px!important;

    &::after {
      color: white!important;
    }
  }

  [class~="swiper-button-prev"] {
    left: calc(50% - 130px);
  }
  [class~="swiper-button-next"] {
    right: calc(50% - 130px);
  }

  [class~="swiper-pagination"] {
    bottom: 50px!important;
  }

  [class~="swiper-pagination-bullet"] {
    background-color: white!important;
    opacity: 1!important;

    &[class~="swiper-pagination-bullet-active"] {
      background-color: #042E6B!important;
    }
  }
}

.slide {
  display: flex;
  align-self: stretch!important;
  height: initial!important;
}

.cardWrapper {
  display: flex;
  flex: 1;
}

.cardContent {
  display: flex;
  flex: 1;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.status {
  color: #000;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.icon {
  color: #042E6B;
  font-size: 16px;
  line-height: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.title {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
}

.date {
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 16px;
}

.subtitle {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.value {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 16px;
}

.actions {
  text-align: center;
}

.orderBtn {
  width: 120px!important;
  height: 30px!important;
  border-radius: 15px!important;
  text-transform: initial!important;
  font-size: 10px!important;
  font-weight: 400!important;
}

.ordersBtn {
  position: absolute!important;
  bottom: 0;
  left: calc(50% - 80px);
  width: 160px!important;
  border-radius: 8px!important;
  height: 40px!important;
  text-transform: initial!important;
  font-size: 16px!important;
  font-weight: 400!important;
}

.card {
  flex: 1;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  row-gap: 20px;

  .cardNoSlide {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &.cards_1, &.cards_2, &.cards_3, &.cards_4 {
    @media screen and (min-width: 1024px) {
      column-gap: 20px;

      .cardNoSlide {
        flex: 0 0 calc(25% - 15px);
        max-width: calc(25% - 15px);
      }
    }
  }
}
