.cancelBtn, .okBtn {
  color: #FFF !important;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin-left: 0 !important;
  border-radius: 4px;
  padding: 0 10px;
  height: 22px;
}

.overlay div[class~="ant-popover-content"] > div[class~="ant-popover-inner"] {
  background-color: #042E6B;
  border-radius: 8px;
}
.overlay div[class~="ant-popover-content"] span[class~="ant-popover-arrow-content"] {
  background-color: #042E6B;
}
.overlay div[class~="ant-popover-content"] span[class~="ant-popover-arrow-content"]::before {
  background-color: #042E6B;
}
.overlay div[class~="ant-popover-content"] div[class~="ant-popover-inner-content"] {
  padding: 9px 20px;
  width: 146px;
}
.overlay div[class~="ant-popover-content"] div[class~="ant-popover-message"] {
  color: #FFF !important;
  padding: 4px 0 8px;
}
.overlay div[class~="ant-popover-content"] div[class~="ant-popover-message-title"] {
  color: #FFF !important;
  padding: 0;
  text-align: center;
}
.overlay div[class~="ant-popover-content"] div[class~="ant-popover-buttons"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

/* bottom */
/*ant-popover-placement-bottom*/
.overlay.bottom {

}
