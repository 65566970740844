.wrap {
    width: 100%;
    align-self: flex-end;
}
.steps {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.step {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    background-color: #AAA;
    color: white;
    padding: 2px 2px 2px 16px;
    width: 100px;
    min-height: 20px;
    font-size: 11px;
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.step.active {
    background-color: #F38336;
}
.step:not(:last-child)::after {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    border-top: 10px solid #AAA;
    border-right: 10px solid #AAA;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    right: -10px;
    transform: rotate(45deg);
    z-index: 2;
}
.step.active:not(:last-child)::after {
    border-top: 10px solid #F38336;
    border-right: 10px solid #F38336;
}
.step:not(:last-child)::before {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    border-top: 12px solid #FFF;
    border-right: 12px solid #FFF;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    right: -12px;
    transform: rotate(45deg);
    z-index: 2;
}
