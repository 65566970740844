.reminderWrap {
    position: relative;
    order: -2;
}

.reminder {
    position: absolute;
    bottom: calc(100% + 23px);
    background: #042E6B;
    width: 100%;
    padding: 11px 9px;
    border-radius: 8px;
}

.reminder:after {
    position: absolute;
    top: 100%;
    left: calc(50% - 7px);
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 18px solid #042E6B;
}

.reminderDate {
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-top: 8px;
}

.reminderDay {
    width: 100%;
    border-radius: 3px 0 0 3px !important;
    border: none !important;
    height: 24px !important;

}
.reminderDay input {
    font-size: 15px;
    padding: 4px 7px;
    height: 21px !important;
}
.reminderDay [class$="ant-picker-input"] {
    border: none !important;
}

.reminderTime {
    min-width: 60px;
    max-width: 60px;
    border-radius: 0 3px 3px 0 !important;
    border: none !important;
    height: 24px !important;
}
.reminderTime input {
    font-size: 15px;
    padding: 4px 7px;
    height: 21px !important;
}
.reminderTime [class$="ant-picker-input"] {
    border: none !important;
}

.reminderTitle {
    color: #ffffff;
    text-align: center;
}

.reminderActions {
    display: flex;
    justify-content: space-around;
    margin-top: 4px;
}
.reminderActions button {
    margin-left: 0 !important;
    min-width: auto !important;
}
