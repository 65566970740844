.block {
  padding-top: 22px;
}

.table {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
}

.header {
  background: #042E6B;
  color: white;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.headerRow {
  display: flex;
  justify-content: center;
}

.headerCol {
  padding: 9px;
}

.row {
  border-top: 1px solid #042E6B;
  display: flex;
}

.footerRow {
  display: flex;
}

.hCol {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 9px 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid #042E6B;
}

.col {
  padding: 9px 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid #042E6B;
  flex: 0 0 50%;
  max-width: 50%;
  background: #F0F3FB;
}

.col:first-child, .hCol:first-child {
  border-left: none;
}

.bodyRow {
  display: flex;
}

.bodyCol:first-child {
  flex: 66.66%;
  max-width: 66.66%;
}

.bodyCol:last-child {
  flex: 33.33%;
  max-width: 33.33%;
  border-left: 1px solid #042E6B;
  border-top: 1px solid #042E6B;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  background: #F0F3FB;
}

.body .col:first-child {
  display: flex;
  align-items: center;
}

.input {
  width: 100% !important;
}

.input input {
   padding: 0;
   color: #042E6B;
   font-family: Outfit, sans-serif;
   font-size: 12px;
   font-weight: 500;
   line-height: normal;
   height: 18px;
 }

.input.centered input {
  text-align: center;
 }

.centered {
  text-align: center;
}
