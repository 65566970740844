._ {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.wrap {
    border-radius: 8px;
    border: 2px solid #042E6B;
    display: flex;
    flex-direction: row;
    width: 312px;
    height: 40px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.action {
    width: 50%;
    background: #042E6B;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.label {
    width: 50%;
    background: #FFC82D;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
    border-radius: 0 6px 6px 0;
}

.label:first-letter {
    text-transform: uppercase;
}

.list {
    position: absolute;
    top: calc(100% + 5px);
    right: -2px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 2px solid #042E6B;
    background: #fff;
    width: 162px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.item {
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 12px;
    font-weight: 500;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
}

.item:first-letter {
    text-transform: uppercase;
}

.pending {
    background: #FFC82D;
}

.approved {
    background: #52C41A;
}


.denied {
    background: #FF4D4F;
}

.submit {
    border-radius: 8px;
    background: #053 !important;
    width: 312px;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    height: 40px;
    text-transform: initial;
}

.modalTitle {
    font-size: 20px;
    font-weight: 600;
}

.modal [class*="ant-modal-content"] {
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
}

.modal [class*="ant-modal-header"] {
    border-radius: 8px 8px 0 0 !important;
    border-bottom: 1px solid #042E6B !important;
}

.modalSelect {
    margin-top: 25px;
    margin-bottom: 10px;
    border: 1px solid #042E6B !important;
    border-radius: 8px !important;
    width: 100% !important;
    display: flex !important;
}

.modalSelect [class*="ant-select"] {
    border: none !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.modalSelect [class*="ant-select-selector"] {
    box-shadow: none !important;
    border-radius: 8px !important;
}


