.table {
    border-radius: 10px;
    border: 1px solid #042E6B;
    overflow: hidden;
}

.header {
    background: #042E6B;
    color: white;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    padding: 9px;
    text-align: center;
}

.row {
    border-bottom: 1px solid #042E6B;
    display: flex;
}

.footerRow {
    display: flex;
}

.col {
    padding: 9px 15px;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    border-left: 1px solid #042E6B;
}

.colInput {
    padding: 0 15px;
}

.col:first-child {
    border-left: none;
}

.colLabel {
    flex: 0 0 170px;
    max-width: 170px;
}

.colValue {
    flex-grow: 1;
    background: #F0F3FB;
    display: flex;
    align-items: center;
}

.colPercent {
    flex: 0 0 90px;
    max-width: 90px;
    background: #F0F3FB;
    display: flex;
    align-items: center;
}

.footer .colValue {
    justify-content: center;
}

.inputWrap {
    width: 100%;
}

.input {
    width: 100%!important;
}

.input input {
    padding: 0;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.centered {
    text-align: center;
}

.hybridField {
    display: inline-block;
    width: 100%;
}

.show {
    opacity: 1;
    height: initial;
    position: initial;
}

.hide {
    opacity: 0;
    height: 0;
    position: absolute;
}

.dropdown {
    flex: 0 0 140px;
    max-width: 140px;
}

.dropdownOverlay {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    background: #F0F3FB!important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)!important;
    overflow: hidden!important;
    text-align: center;
}

.selected, .selectedText {
     border-radius: 10px;
     border: 1px solid #042E6B;
     padding: 3px;
     color: #042E6B;
     text-align: center;
     font-family: Outfit, sans-serif;
     font-size: 10px;
     font-weight: 400;
     line-height: normal;
 }

.selectButton {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.selectButton.empty .selectedText {
    border: none;
}

.selected.red, .selectedText.red {
    background: #F55;
    border: 1px solid #F55;
    color: #FFF;
}

.selected.blue, .selectedText.blue {
    background: #1677FF;
    border: 1px solid #1677FF;
    color: #FFF;
}

.selectedText {
    width: 140px;
    margin-right: 10px;
}
