.row {
    margin-bottom: 20px;

    @media screen and (min-width: 800px) {
        margin-top: -20px;
    }
}

.card {
    height: calc(100% - 22px);
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info {
    border-radius: 5px;
    border: 5px solid #042E6B;
    padding: 20px 20px 10px;
    position: relative;
    width: 100%;
}

.label {
    position: absolute;
    left: -14px;
    top: -20px;
    background-color: white;
    padding: 4px 10px;
}

.content {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
}
