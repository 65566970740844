.wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 30px;
}

.card {
    width: calc(50% - 12px);
}

.card.fullWidth {
    width: 100%;
}

.withLink {
    cursor: pointer;
}

.content {
    height: 100%;
}

.title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 0 18px;
}

.cardInfo {
    padding: 16px 18px;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.cardValuesRow {
    display: flex;
    flex-direction: row;
}

.cardValuesRow:not(:last-child) {
    margin-bottom: 15px;
}

.cardLabel {
    min-width: 140px;
    width: 140px;
    padding-right: 22px;
}

.cardValue {
    width: 100%;
}

.icon {
    width: 55px;
    height: 55px;
    background: #042E6B;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.history {
    padding: 18px;
}

.historyRow:not(:last-child) {
    margin-bottom: 15px;
}

.historyContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.historyLabel {
    font-size: 14px;
}

.historyDate {
    color: #002A77;
}

.historyDiff {
    color: #002A77;
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 0.50);
    padding: 4px 28px;
    display: inline-flex;
    gap: 4px;
    align-items: center;
}

.historyDiff:first-letter {
    text-transform: uppercase;
}

.transactions {
    padding: 18px;
}

.transactionsContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(4, 46, 107, 0.50);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.transactionsDate {
    color: #002A77;
}

.transactionsAmount {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
    background: #053;
    padding: 4px 20px;
    display: inline-block;
}

.transactionsSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.transactionsDiff {
    display: flex;
    gap: 4px;
    color: #002A77;
    text-align: right;
    font-size: 12px;
}

.composition {
    margin-top: 50px;
    margin-bottom: 40px;
}

.actions {
    display: flex;
    gap: 24px;
    flex-direction: row;
}

.actionSubmit {
    border-radius: 8px !important;
    background: #042E6B !important;
    color: #E1E5ED !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
}

.actionCancel {
    border: 1px solid #F2A93B !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #F2A93B !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
}

.actionDelete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
}

.dates {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: flex-end;
    padding-left: 90px;
    margin-top: 30px;
}

.quotationCard {
    padding: 12px;
}

.quotationActions {
    padding-left: 90px;
    display: flex;
    gap: 24px;
}

.quotationSubmit {
    border-radius: 8px !important;
    box-shadow: none !important;
    color: #fff !important;
    background: #042E6B !important;
    text-transform: initial !important;
    padding: 11px 39px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.quotationRequest {
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
    box-shadow: none !important;
    color: #042E6B !important;
    background: transparent !important;
    text-transform: initial !important;
    padding: 11px 39px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.datesLabel {
    font-size: 10px;
    color: #042E6B;
}

.dateInput {
    font-size: 18px !important;
    height: auto !important;
}

.dateInput:before {
    border-bottom: 1px solid rgba(0, 42, 119, 0.10) !important;
}

.dates [class*="ant-picker"] {
    border: 1px solid transparent !important;
    border-bottom-color: rgba(0, 42, 119, 0.10) !important;
    height: auto !important;
}

.dates [class*="ant-picker"] input {
    width: 200px !important;
    font-size: 18px !important;
}

.dates [class*="ant-picker-disabled"] {
    background: transparent !important;
}

.modal [class*="ant-modal-title"] {
    color: #000;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.modal [class*="ant-modal-content"] {
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
}

.modal [class*="ant-modal-header"] {
    border-radius: 8px 8px 0 0 !important;
    border-bottom: 1px solid #042E6B !important;
}

.modalSelect {
    margin-top: 25px;
    margin-bottom: 10px;
    border: 1px solid #042E6B !important;
    border-radius: 8px !important;
    width: 100% !important;
    display: flex !important;
}

.modalSelect [class^="ant-select"] {
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.modalSelect [class^="ant-select-arrow"] {
    width: 12px !important;
}

.modalSelect [class*="ant-select-selector"] {
    box-shadow: none !important;
    border-radius: 8px !important;
}

.modalContent {
    font-size: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.hoursInput {
    border: 1px solid #042E6B !important;
    border-radius: 4px !important;
}

.modal [class*="ant-modal-footer"] {
    display: flex !important;
    justify-content: center;
    gap: 18px;
    border-top: none !important;
    padding-bottom: 24px !important;
}

.modal [class*="ant-btn-default"] {
    border-radius: 8px !important;
    border: 1px solid #042E6B !important;
    box-shadow: none !important;
    color: #042E6B !important;
    background: transparent !important;
    text-transform: initial !important;
    padding: 8px 69px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: auto !important;
}

.modal [class*="ant-btn-primary"] {
    border-radius: 8px !important;
    box-shadow: none !important;
    color: #fff !important;
    background: #042E6B !important;
    text-transform: initial !important;
    padding: 8px 69px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: auto !important;
}

.modalSummary {
    font-size: 20px;
}
