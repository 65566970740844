.box {
  color: #000;
  font-family: Outfit, sans-serif;
  padding-top: 10px;
}

.dealer {
  margin-bottom: 20px;
}

.dealerName {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dealerLabels {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dealerContracts [class~="iconPlus"] {
  display: inline-block;
  min-width: 80px;
}
