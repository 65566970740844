.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.file {
  position: relative;
  height: 105px;
  width: 86px;
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #FEFEFE;
}
.file.fileFullWidth {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.file div[data-rmiz-wrap="visible"] {
  position: static;
}
.fileRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 10px;
}
.removeIcon {
  position: absolute !important;
  top: -2px;
  right: -2px;
  width: 30px;
  height: 30px;
}
.removeIcon:hover {
  color: red;
}
.fileImg {
  max-width: 90%;
  max-height: 90%;
  border-radius: 4px;
  opacity: 0.3;
}
.fileImgZoom {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  opacity: 1;
}
.pdfBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}
.pdfIcon {
  font-size: 70px;
  color: maroon;
}
