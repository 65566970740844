.box {
    margin-top: 15px;
    font-family: Outfit, sans-serif;
    color: #272727;
}

.item {
    margin-bottom: 15px;
}
.header {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid rgba(4, 46, 107, .5);
    padding-bottom: 6px;
}
.by {
    color: #002A77;
    font-weight: 500;
}
.footer {
    margin-top: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.showMoreBtn {
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 8px;
    text-transform: none;
    color: #FD8A32;
    font-weight: 400;
}
