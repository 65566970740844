.RaFilterForm-filterFormInput #responsibleAdminId {
  width: 175px;
}
.RaFilterForm-filterFormInput .MuiSelect-select:not([class~="MuiSelect-multiple"]) {
  width: 175px;
}

.RaFilterForm-filterFormInput .MuiSelect-select:not([class~="MuiSelect-multiple"]).MuiInputBase-input {
  /*line-height: 26px !important;*/
}
