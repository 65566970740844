[class~="MuiFormHelperText-root"] {
  display: none;
}

.uploadTitle {
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.uploadIcon {
  margin-bottom: 30%;
}

.uploadNotion {
  color: #042E6B;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
}

.preview [data-rmiz-wrap="visible"] {
  z-index: 1;
}

.field {
  max-width: 400px;
  position: relative;
}

.field [class~="RaFileInput-dropZone"] {
  border-radius: 10px;
  border: 2px dashed #042E6B;
  background: rgba(4, 46, 107, 0.10)!important;
  max-width: 400px;
  min-height: 210px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

[class~="hasPreview"] div[class~="RaFileInput-dropZone"] {
  display: none;
}

[class~="hasPreview"] div[class~="RaFileInput-removeButton"] {
  position: initial!important;
}

[class~="hasPreview"] div[class~="RaFileInput-removeButton"] button {
  opacity: 1!important;
  z-index: 2!important;
}

[class~="empty"] div[class~="previews"] {
  display: none!important;
}

.field div[class~="previews"] {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  border-radius: 10px;
  border: 2px dashed #042E6B;
  background: #E1E5ED;
  position: relative;
  line-height: 1!important;
}

.field div[class~="previews"] img {
  max-height: 210px;
  max-width: 396px;
  border-radius: 10px;
}

.field div[class~="previews"] [class~="RaFileInputPreview-removeButton"] svg {
  width: 20px!important;
  height: 20px!important;
}

.field div[class~="RaFileInput-dropZone"] p {
  margin: 0;
}
