.box {
}
.boxFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.title {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: gray;
}
.item {
  position: relative;
  margin-bottom: 10px;
  padding: 12px 20px 14px;
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #E1E5ED;
  font-family: Outfit, sans-serif;
}
.row {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 10px;
}
.timestamp {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 13px;
  text-align: right;
}
.itemComment {
  font-size: 14px;
  line-height: 15px;
}
.itemTitle {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  color: #000;
}
.itemStatus {
  margin-top: 3px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.commentIcon {
  font-size: 15px !important;
  color: darkorange;
  display: block !important;
  position: absolute;
  top: 4px;
  left: 2px;
}
.removeIcon {
  display: inline-block;
  position: absolute !important;
  top: -2px;
  right: -2px;
  width: 30px;
  height: 30px;
}
.removeIcon:hover {
  color: red;
}
.btnShowAll {
  width: 100px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #042E6B;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
