.box {
  position: relative;
}

.modal {
  overflow-y: auto;
}

.modalBox {
  padding: 8px 44px 16px;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 50px);
  width: 1000px;
  border: 2px solid #042E6B;
  border-radius: 10px;
  background: #FFF;
}

.modalTitle {
  margin: 5px 0 5px;
  color: #042E6B;
  font-size: 15px;
  font-weight: 400;
}

.columns label {
  display: flex;
  margin-right: 20px;
  width: 170px;
  line-height: 15px;
}

.paging {
  margin-top: 15px;
}

.pagingText {
  color: #74706B;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 15px;
}

.pagingInput {
  margin-left: 10px;
  padding: 0 10px;
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 2px;
  border: 1px solid #042E6B;
  background: #FFF;
}

.modal .saveBtn {
  height: 35px;
  border-radius: 8px;
  background: #FD8A32;
  color: #FFF;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 15px;
}
