.row {
    margin-bottom: 20px;
}

.link {
    display: inline-flex;
    line-height: 1;
    font-size: 1px;
    width: 28px;
    height: 28px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid transparent;
    box-shadow: 0 0 2px rgba(4, 46, 107, 0.3);
    transition: all .5s ease;

    &:hover {
        box-shadow: 0 0 10px rgba(4, 46, 107, 0.5);
        transform: rotate(360deg);
        transition: all .5s ease;
    }
}

.linkIcon {
    width: 24px;
    height: 24px;
    fill: #042E6B;
}

.table {
    border-radius: 8px;
    border: 1px solid #042E6B;
    overflow: hidden;
    margin-top: 20px;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.tr {
    &:last-child {
        .td {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.tbody {
}

.th {
    display: table-cell;
    background: #E1E5ED;
    color: #042E6B;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    border-right: 1px solid #042E6B;
    text-align: center;

    &:first-child {
        text-align: initial;
    }

    &:last-child {
        border-right: none;
    }
}

.td {
    display: table-cell;
    border-bottom: none;
    border-top: 1px solid #042E6B;
    border-right: 1px solid #042E6B;
    text-align: center;
    color: #74706B;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    padding: 10px;

    &:first-child {
        text-align: initial;
    }

    &:last-child {
        border-right: none;
    }
}
