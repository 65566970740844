.wrap {

}

.table {
    border-radius: 10px;
    border: 1px solid #042E6B;
    overflow: hidden;
}
.table [class~="ant-table-thead"] {
    background: #E1E5ED!important;
}
.table [class~="ant-table-cell"] {
    background: transparent!important;
    color: #042E6B!important;
    font-size: 11px!important;
    font-weight: 400!important;
    line-height: normal!important;
    padding: 6px 10px!important;
    border-bottom: 1px solid #042E6B!important;
}
.table [class~="ant-table-cell"]::before {
    background-color: black!important;
    height: 100% !important
}
.table [class~="ant-table-cell"][class~="ant-table-cell-scrollbar"] {
    box-shadow: none!important;
}
.table .tableRow:last-child [class~="ant-table-cell"],
.table [class~="ant-table-placeholder"] [class~="ant-table-cell"] {
    border-bottom: none!important;
}
.table .tableRow [class~="ant-table-cell"] {
    border-right: 1px solid black!important;
    color: #74706B!important;
}

.filterWrap {
    padding-bottom: 16px;
    display: flex;
    column-gap: 20px;
}
.filterField.firstCol {
    flex: 200px;
    max-width: 200px;
}
.filterField.secondCol {
    flex: 150px;
    max-width: 150px;
}
.filterField.lastCol {
    flex: 1;
    text-align: right;
}
.filterBtn {
    background-color: #042E6B !important;
    border-color: #042E6B !important;
    border-radius: 8px !important;
}
.searchField [class~="ant-input"] {
    border: 1px solid #042E6B!important;
    border-radius: 8px 0 0 8px!important;
    border-right: none!important;
    box-shadow: none!important;
    color: #042E6B!important;
}
.searchField [class~="ant-input-search-button"] {
    border: 1px solid #042E6B!important;
    border-radius: 0 8px 8px 0!important;
    border-left: none!important;
    cursor: initial!important;
}
.searchField svg {
    fill: #042E6B!important;
}
.searchField ::-webkit-input-placeholder { color: rgba(0, 42, 119, 0.50)!important; }
.searchField :-moz-placeholder { color: rgba(0, 42, 119, 0.50)!important; }
.searchField ::-moz-placeholder { color: rgba(0, 42, 119, 0.50)!important; }
.searchField :-ms-input-placeholder { color: rgba(0, 42, 119, 0.50)!important; }
.searchField ::-ms-input-placeholder { color: rgba(0, 42, 119, 0.50)!important; }
.searchField ::placeholder { color: rgba(0, 42, 119, 0.50)!important; }

.selectField [class~="ant-select-selector"] {
    border: 1px solid #042E6B!important;
    border-radius: 8px!important;
    box-shadow: none!important;
}
.selectField [class~="ant-select-selection-placeholder"] {
    color: rgba(0, 42, 119, 0.50)!important;
}
.selectField [class~="ant-select-selection-item"] {
    color: #042E6B!important;
}
.selectField svg {
    fill: #042E6B!important;
}
