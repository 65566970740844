._ {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
}

.wrap {
    min-width: 620px;
    background: #FFFFFF;
    padding: 127px 94px 200px 59px;
    z-index: 2;
    position: relative;
    height: 100%;
    overflow-y: auto;
    border-left: 2px solid #042E6B;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(5px);
    background-color: #042e6b0d;
}

.close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.closeIcon {
    cursor: pointer;
}

.title {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 100%;
    flex-direction: row;
}

.submit {
    padding: 11px 40px !important;
    border-radius: 8px !important;
    background: #042E6B !important;
    color: #E1E5ED !important;
    text-transform: initial !important;
    display: flex !important;
    gap: 12px;
    width: 310px;
}
.submit:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.update {
    padding: 11px 40px !important;
    border-radius: 8px !important;
    background: #042E6B !important;
    color: #E1E5ED !important;
    text-transform: initial !important;
    display: flex !important;
    gap: 12px;
    width: 200px;
}

.delete {
    border: 1px solid #D72B2C !important;
    border-radius: 8px !important;
    background: transparent !important;
    color: #D72B2C !important;
    text-align: center !important;
    font-family: Outfit, sans-serif !important;
    font-size: 14px !important;
    padding: 10px 69px !important;
    text-transform: initial !important;
    box-shadow: none !important;
    width: 200px;
}
