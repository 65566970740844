.wrap {
    padding-top: 36px;
}
.col:nth-child(7),
.col:nth-child(8) {
    .value {
        padding-bottom: 0;
    }
}
.title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}
.titleText {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.value {
    color: #1E2C5C;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 30px;
}