._ {
  position: relative;
}

.input {
  width: 100% !important;
}

.popup {
  z-index: 10;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  display: flex;
  font-family: Outfit, sans-serif;
  padding: 4px 0;
  overflow: hidden;
  font-size: 16px;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.select {
  height: 100px;
  overflow-y: scroll;
}

.select:first-child {
  margin-right: 25px;
}

.selectItem, .selectItemPicked {
  padding-left: 15px;
  padding-right: 40px;
  cursor: pointer;
}

.selectItem:hover, .selectItemPicked:hover {
  text-decoration: underline;
}

.selectItemPicked {
  color: #333;
  font-weight: bold;
}
