.row {
    margin-top: 18px;
    font-size: 15px;
    line-height: 1.1;
    font-weight: 400;
    min-height: 24px;
}

.title {
    font-size: 15px;
    font-weight: 500;
}
.subtitle {
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 30px 0 24px;
}
.footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
}

.exportBtn {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    width: 254px !important;
    height: 37px;
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

[class~="swiper-pagination"] {
    top: 26px;
    right: 60px;
    left: initial;
    bottom: initial;
    width: 52px;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: rgba(116, 112, 107, 0.50);
}

[class~="swiper-pagination-current"] {
    color: #042E6B;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

[class~="swiper-button-prev"],
[class~="swiper-button-next"] {
    top: 40px;
    right: 116px;
    left: initial;
    bottom: initial;
    width: 40px;
    height: 40px;
    border: 1px solid #042E6B;
    border-radius: 50%;
}

[class~="swiper-button-prev"]::after,
[class~="swiper-button-next"]::after {
    font-size: 14px;
    font-weight: 900;
    color: #042E6B;
}

[class~="swiper-button-next"] {
    right: 15px;
}

.form {
    padding-right: 2px;
    padding-bottom: 4px;
}

.formSelect {
    width: 100%;
}

.formSelect  [class~="ant-select-selector"] {
    border: none!important;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10)!important;
}

.formSelect  [class~="ant-select-selector"]:focus, [class~="ant-select-selector"]:hover,
.formSelect  [class~="ant-select-focused"] [class~="ant-select-selector"] {
    box-shadow: none!important;
    border-bottom-color: #002A77!important;
}

.formInput {
    border: none;
    border-bottom: 1px solid rgba(0, 42, 119, 0.10);
    width: 100%;
}

.formInput:focus, .formInput:hover,
.formInput[class~="ant-input-number-focused"] {
    box-shadow: none;
    border-bottom-color: #002A77;
}

.carousel [class~="ant-picker-input"] {
    border-bottom: 1px solid rgba(0, 42, 119, 0.10)!important;
}

@media all and (min-width: 1400px) {
    .actions {
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
    }
}

@media all and (min-width: 1140px) {
    .actionsGroup {
        display: flex;
        justify-content: space-between;
    }
}

.action {
    padding-top: 25px;
}

@media all and (min-width: 1400px) {
    .action {
        padding-top: 0;
    }
}

.submitBtn {
    border-radius: 8px!important;
    background-color: #042E6B!important;
    border: none!important;
    height: 38px!important;
    width: 132px!important;
    margin-right: 14px!important;
    text-transform: initial!important;
    color: white!important;
}

.submitBtn:hover {
    background-color: #053e8c!important;
}

.submitBtn:disabled {
    background-color: #c3c3c3 !important;
}

.addBtn {
    border-radius: 8px;
    height: 38px;
    width: 132px;
    border: 1px solid #042E6B;
    background: #E1E5ED;
    color: #042E6B;
}

.addBtn:hover {
    background: #a1c6f1;
}

.removeBtn button, button.removeBtn {
    border-radius: 8px;
    height: 38px;
    width: 132px;
    border: none;
    background: #D32F2F;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

button.removeBtn span, .removeBtn button span {
    margin-left: 9px;
    border-radius: 8px;
}

.removeBtn:hover {
    background: #cb6868;
    border-radius: 10px;
}
