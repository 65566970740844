.card {
    max-width: calc(100vw - 316px);
    position: relative;
}
.blur {
    filter: blur(2px);
}

.carousel {
    position: initial;
}

.dateInput {
    width: 100%;
}
.dateInput input {
    padding-top: 0!important;
    padding-left: 0!important;
}
.dateInput > div {
    background-color: initial!important;
}
.dateInput > div::before {
    border-bottom-color: rgba(0, 0, 0, 0.1)!important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2;
}
.popup {
    position: absolute;
    padding: 20px 70px;
    width: 340px;
    height: 140px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #042E6B;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    top: calc(50% - 70px);
    left: calc(50% - 170px);
    z-index: 3;
}
