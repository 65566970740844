.block {
  padding-top: 22px;
}

.table {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
}

.header {
  background: #042E6B;
  color: white;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.row {
  border-top: 1px solid #042E6B;
  display: flex;
}

.col {
  padding: 9px 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid #042E6B;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.header .col {
  color: #FFF;
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  border-left: 1px solid #F0F3FB;
}

.col:first-child {
  border: none;
}

.body .col:first-child {
  display: flex;
  align-items: center;
}

.bodyRow {
  display: flex;
}

.bodyCol:first-child {
  flex: 0 0 75%;
  max-width: 75%;
}

.bodyCol:last-child {
  flex: 0 0 25%;
  max-width: 25%;
  border-left: 1px solid #042E6B;
  border-top: 1px solid #042E6B;
  display: flex;align-items: center;
  padding: 9px 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.titleRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.titleRemove {
  width: 20px;
  height: 20px;
  border: 1px solid rgb(246 95 94);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  line-height: 1;
  font-size: 18px;
  font-weight: 300;
  color: rgb(246 95 94);
  cursor: pointer;
}

.titleRemove:hover {
  box-shadow: 0 0 3px rgb(246 95 94 / 50%);
}

.inputWrap {
  display: flex;
}

.input {
  width: 100% !important;
}

.input input {
  padding: 0;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
}

.footer {
  border-top: 1px solid #042E6B;
  padding: 6px 15px 8px;
}

.btn, .bth:active, .btn:focus, .btn:hover {
  border-radius: 5px;
  border: 1px solid #042E6B;
  background: #042E6B;
  width: 80px;
  height: 20px;
  color: #FFF;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.btn:hover {
  box-shadow: 0 0 4px rgb(0 0 0 / 60%);
}

.colored {
  background: #F0F3FB;
}
