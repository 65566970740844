.actionsLeft {
    padding-top: 22px;
}
.actionsRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 22px;
}
.action {
    width: 200px!important;
    height: 38px!important;
    color: white!important;
    border-radius: 8px!important;
    text-align: center!important;
    text-transform: initial!important;
    font-family: Outfit, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal!important;
}

.actionIcon {
    margin-right: 10px!important;
    font-size: 18px!important;
}

.cancelBtn {
    margin: 0!important;
    border-color: #D72B2C!important;
    color: #D72B2C!important;
    background-color: transparent!important;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2)!important;
    }
}
.saveBtn, .completeBtn, .downloadBtn {
    background: #042E6B!important;
    opacity: 1!important;
    transition: opacity 0.2s;
    &:hover {
        opacity: .8!important;
        transition: opacity 0.2s;
    }
}

.completeBtn, .toSignBtn, .signedBtn, .downloadBtn {
    margin-left: 10px!important;
}

.toSignBtn {
    background: #F38336!important;
}

.cancelSignBtn {
    background: #ff0000!important;
}

.signedBtn {
    background: #0c5541!important;
}

.previewActionBtn {
    background: #002A77!important;
}

.actionDisabled {
    opacity: .8!important;
}

.trim {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.dialogTitle {
    color: #000;
    font-family: Outfit, sans-serif;
    padding-top: 26px;
    padding-bottom: 56px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.file {
    padding-bottom: 65px;
}
.uploader {
    display: flex;
    justify-content: center;
}
.uploadBtn {
    width: 150px;
    height: 190px;
}
.dialogBtns {
    display: flex;
    justify-content: center;
}
.filePreview > div {
    width: 170px;
    height: 190px;
    align-items: center;
}
.filePreview.filePdf > div {
    display: flex;
    justify-content: center;
    [class~="pi-link"] {
        right: -38px;
        bottom: -40px;
    }
}
.loader {
    margin-right: 4px;
}
