._ {
    background: #FFFFFF;
    padding: 25px 32px;
    border: 1px solid #042E6B;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
    width: 100%;
}

.itemHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.actions {
    width: 100%;
}

.list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.document {
    width: calc(50% - 12px);
    padding: 18px 21px 25px;
    border-radius: 10px;
    border: 1px solid #042E6B;
}

.downloadBtn {
    text-transform: inherit !important;
    padding: 10px 33px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
