.wrap {}

.row {
    margin-top: 16px;
}

.card {

}

.search {
    border-radius: 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    height: 37px;
}

.descField .value {
    margin-bottom: 0!important;
    border-radius: 8px;
    border: 1px solid #000;
    background: rgba(0, 42, 119, 0.16);
    padding: 16px;
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
}
.value {
    margin-bottom: 0 !important;
}
