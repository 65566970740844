/* Cards */
.infoline {
  margin-top: 25px;
  align-items: stretch;
}

/* User Avatar */
.userAvatar {
  display: flex;
  justify-content: center;
}

.avatar {
  display: flex;
  justify-content: center;
}

.avatarImg {
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #E1E5ED;
  border-radius: 50%;
}

.avatarBlank {
}

/* User info */
.userName {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.userStatus {
  margin-top: 5px;
  text-align: center;
}

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.contacts .contact {
  margin: 0 5px;
}

/* User company */
.company {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.companyText {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  margin: 0 15px;
}

.companySwitch, .companySwitchChecked {
  vertical-align: top;
  margin-top: 4px;
  height: 18px;
  width: 63px;
  background: #E1E5ED;
  border: 1px solid #002A77;
  opacity: 1;
}

.companySwitch > div, .companySwitchChecked > div {
  left: 1px !important;
  top: 1px;
  width: 26px;
  box-shadow: none;
  height: 14px !important;
}

.company .companySwitchChecked > div {
  left: auto !important;
  right: 1px;
}

.companySwitchChecked > div::before, .companySwitch > div::before {
  background-color: #002A77;
}

/* Orders */
.orders {
  padding: 25px 35px 0;
}

.orders .order {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.orders .order:last-child {
  margin-bottom: 0;
}

.orders .orderBtn {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.55;
  text-transform: none;
  font-weight: 400;
}

/* Links */
.links {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 35px 0;
}

.links .link {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.links .link:last-child {
  margin-bottom: 0;
}

.links .linkBtn {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.55;
  text-transform: none;
  font-weight: 400;
}

/* Credits */
.creditsWrap {
  margin-top: 30px;
}

.creditsHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.credit {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 15px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: #000;
  user-select: none;
}

.credit .creditCode {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.credit .creditDate {
  margin-top: 3px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 14px;
}

.credit .creditBalance {
  margin-top: 12px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.credit .creditAmount {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.credit .creditCrop {
  margin-top: 10px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.credit .creditStatus {
  margin-top: 15px;
  width: auto !important;
  height: 30px !important;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10px !important;
}

a:hover > .credit {
  border: 1px solid #40a9ff;
  box-shadow: 0 4px 8px 0 #3b49571a;
}

.title {
  font-size: 25px;
  font-weight: 500;
}

.addressesWrap {
  margin-top: 25px;
}

.tracker {
  display: flex;
  width: 100%;
  height: calc(100% - 20px);
  min-height: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.close {
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
}

.addDraft {
  display: flex !important;
  gap: 7px !important;
  color: rgba(116, 112, 107, 0.7) !important;
  border: 1px solid rgba(116, 112, 107, 0.7) !important;
  background: #fff !important;
  box-shadow: none !important;
  padding: 7px 24px !important;
  text-transform: none !important;
}

.addDraft:hover {
  background: #f4f4f4 !important;
  box-shadow: none !important;
}

.historyTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.historyTitle:not(:hover) span {
  color: #042E6B!important;
}
.history [class~="InitialCard-left"] {
  width: 100%;
}
