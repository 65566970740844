.select {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  overflow: hidden;
}

.smallBtn {
  border: none;
  background-color: transparent;
}

.smallBtn:hover, .smallBtn:visited, .smallBtn:focus {
  background-color: transparent;
}

.btnText {
  margin-left: 6px;
}

.modal [class~="ant-modal-content"] {
  border-radius: 8px;
  border: 1px solid #042E6B;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.modal [class~="ant-modal-header"] {
  padding-right: 54px;
  border-bottom-color: #042E6B;
}

.modal [class~="ant-modal-title"] {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.modal [class~="ant-modal-body"] [class~="ant-select-selector"] {
  border-bottom: none!important;
}

.modal [class~="ant-modal-footer"] {
  border-top-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding-bottom: 36px;
  gap: 25px
}

.modal [class~="ant-modal-footer"] [class~="ant-btn"] {
  border-radius: 8px;
  width: 153px;
  height: 34px;
}

.modal [class~="ant-modal-footer"] [class~="ant-btn-default"] {
  border: 1px solid #042E6B;
  color: #042E6B;
}

.modal [class~="ant-modal-footer"] [class~="ant-btn-primary"] {
  background: #042E6B;
  margin: 0;
  border: none;
}


