.form {
	padding-bottom: 40px;
}

.title {
	font-family: arial,sans-serif;
	font-size: 20px;
	font-weight: 400;
	color: #1a0dab;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0,0,0,.1);
	display: inline-block;
	line-height: 1.3;
	margin-bottom: 3px;
	max-width: 560px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.description {
	font-family: arial,sans-serif;
	font-size: 14px;
	color: #4d5156;
	line-height: 1.58;
}

.result {
	font-size: 20px;
	margin-top: 40px;
	margin-bottom: 40px;
	max-width: 600px;
}

.result h3 {
	opacity: 0.65;
	font-size: 20px;
}
