.files {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 5px 0 5px;
}
.fileBox {
  margin: 0 20px 10px 0;
  border-radius: 10px;
  border: 2px solid #042E6B;
  background: #fff;
  overflow: hidden;
  width: 86px;
  height: 104px;
  position: relative;
  z-index: 0;
}
.file {
  display: flex;
  justify-content: center;
  background-color: white;
}
.fileRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 10px;
}
.removeIcon, .denyIcon, .denyIconBig, .confirmIcon, .confirmIconBig {
  position: absolute;
  font-size: 24px;
  top: -12px;
  right: -12px;
  color: maroon;
}
.removeIcon:hover {
  color: red;
}
.fileImg {
  max-width: 100%;
  max-height: 104px;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}
.pdfBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}
.pdfIcon {
  font-size: 60px;
  color: maroon;
}
.uploadBox {
  background-color: white;
}
.uploadText {
  margin-top: 8px;
}

.fileCloseIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}

.files [class~="dd-pdfBox"] {
  width: 100%;
}

.files [class~="pi-icon"] {
  display: none;
}

.files [class~="pi-link"] {
  position: initial;
}

.files [class~="dd-file"] [class~="ant-upload-select-picture-card"] {
  border-radius: 10px;
  border: 2px dashed #042E6B;
  background: #E1E5ED;
  width: 86px;
  height: 104px;
}

.files [class~="dd-uploadText"] {
  display: none;
}

.files [class~="dd-uploadText-box"] [class~="anticon-plus"] {
  color: #042E6B;
  font-size: 20px;
}
