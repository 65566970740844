.checkbox {
    [class~="MuiFormGroup-row"] {
        row-gap: 10px;
        column-gap: 10px;

        @media screen and (min-width: 1280px) {
            row-gap: 10px;
            column-gap: 26px;
        }
    }
    [class~="MuiFormControlLabel-labelPlacementEnd"] {
        border-radius: 2px;
        border: 1px solid #042E6B;
        background: #E1E5ED;
        margin: 0;
        padding-right: 10px;
        flex: 0 0 100%;
        max-width: 100%;

        @media screen and (min-width: 800px) {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px);
        }

        @media screen and (min-width: 1024px) {
            flex: 0 0 calc(33.33% - 10px);
            max-width: calc(33.33% - 10px);
        }

        @media screen and (min-width: 1280px) {
            flex: 0 0 calc(25% - 20px);
            max-width: calc(25% - 20px);
        }
    }
    [class~="MuiFormControlLabel-labelPlacementEnd"][class~="Mui-error"] {
        border-color: red;
    }
}
