.wrap {
  margin-bottom: 20px;
  border: 1px solid #042E6B!important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 13px;
}

.progressFormat {
  color: #0C5541;
}

.progressText {
  margin-top: 15px;
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn {
  margin-top: 15px !important;
  width: 100%;
  max-width: 248px;
  text-transform: none;
}
