.titleIcon {
    font-size: 19px;
    color: #042E6B;
    margin-left: 12px;
}
.tooltipOver {
    color: red;
}
.tooltipOver {
    [class~="ant-tooltip-inner"] {
        background-color: #E1E5ED!important;
        padding: 3px 8px!important;
        min-height: initial!important;
        color: #002A77!important;
        font-family: Outfit, sans-serif!important;
        font-size: 9px!important;
        font-weight: 400!important;
        line-height: normal!important;
    }
    [class~="ant-tooltip-arrow-content"],
    [class~="ant-tooltip-arrow-content"]::before {
        background-color: #E1E5ED!important;
        --antd-arrow-background-color: #E1E5ED!important;
    }
}

.filters {
    padding-top: 16px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 900px) {
        flex-direction: row;
    }
}
.filter {
    display: flex;
    /*gap: 6px;*/
    flex-direction: column;
    justify-content: center;
}
.filterLinks {
    min-width: 70px;
    justify-content: flex-end;
}
.label {
    color: #042E6B;
    display: block;
    font-size: 12px;
    margin-top: 4px;
    line-height: 1;
    margin-bottom: 3px;
}
.autocomplete {
    width: 200px;

    @media screen and (min-width: 1200px) {
        width: 300px;
    }
}
.autocomplete [class~="ant-select-selector"] {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    background: #FFF!important;
    box-shadow: none!important;
    height: 37px!important;
}
.autocomplete input[class~="ant-select-selection-search-input"] {
    height: 35px!important;
}
.autocomplete [class~="ant-select-selection-placeholder"] {
    margin-top: 7px!important;
    color: rgba(0, 42, 119, 0.50)!important;
    font-family: Outfit, sans-serif!important;
    font-size: 15px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.select {
    width: 200px;
}
.select [class~="ant-select-selector"] {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    box-shadow: none!important;
    height: 37px!important;
}
.select [class~="ant-select-selection-item"] {
    height: 35px!important;
    display: flex!important;
    align-items: center!important;
}
.select input {
    height: 35px!important;
}
.select [class~="ant-select-selection-placeholder"] {
    margin-top: 7px!important;
    color: rgba(0, 42, 119, 0.50)!important;
    font-family: Outfit, sans-serif!important;
    font-size: 15px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.link {
    display: block!important;
    width: 100% !important;
    color: #000!important;
    font-family: Outfit, sans-serif!important;
    font-size: 12px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.link:hover {
    color: rgb(32, 75, 206)!important;
}
.exportBtn {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    background: #FFF!important;
    color: #042E6B!important;
    font-family: Outfit,sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal!important;
    min-height: 37px!important;
    min-width: 150px!important;
    text-transform: initial!important;
}
.exportIcon {
    font-size: 20px!important;
    margin-right: 8px!important;
}
