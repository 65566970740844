._ {
    border-radius: 0 0 8px 8px;
    border: 1px solid #042E6B;
    background: #FFF;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 26px 32px;
}

.item {
    width: calc(50% - 13px);

    &.fullWidth {
        width: 100%;

        & .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.content {
    border-radius: 10px;
    padding: 0 21px 18px;
    border: 1px solid #042E6B;
    background: #FFF;
}

.contract {
    width: calc(50% - 14px);
    padding-right: 21px;
}

.integration {
    width: calc(50% - 14px);
    padding-top: 53px;

    & .section {
        background: #D9D9D9;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 23px 27px;

        & .section_content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    & .actions {
        width: 100%;
        display: flex;
        gap: 25px;
        margin-top: auto;
        justify-content: space-between;

        & button {
            width: 100%;
        }
    }
}