.fieldRow [class~="Mui-error"]::before {
    border-bottom-color: red!important;
}
.fieldRow [class~="MuiInputBase-formControl"]::after {
    display: none!important;
}
.fieldRow.descField [class~="MuiInputBase-formControl"]::before,
.fieldRow.descField [class~="MuiInputBase-formControl"]::after {
    display: none!important;
}
.fieldRow.descField [class~="MuiInputBase-formControl"] {
    padding-bottom: 0!important;
}
.fieldRow.descField [class~="MuiInputBase-formControl"] textarea {
    padding: 14px 6px!important;
    border-radius: 8px;
    border: 1px solid #000;
    background: rgba(0, 42, 119, 0.16);
    color: #000;
    font-family: Outfit, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    height: 80px!important;
}
.fieldRow.descField [class~="Mui-error"] textarea {
    border-color: red;
}

.editor div[class~="tox-tinymce"] {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
}
.editor.error div[class~="tox-tinymce"] {
    border-color: red!important;
}

.editor div[class~="tox-editor-header"] {
    border-bottom: 1px solid #000!important;
}
.editor div[class~="tox-statusbar"] {
    display: none!important;
}
.editor button[class~="tox-tbtn"] {
    width: 22px!important;
    height: 22px!important;
    margin: 0!important;
}
.editor button[class~="tox-tbtn--select"] {
    width: auto!important;
}
.editor [class~="tox-tinymce"] {
    height: 340px!important;
}
.editor [class~="tox-tinymce"] + div {
    font-size: 10px!important;
    text-align: right;
    color: #aaa;
}

.files {
    border-color: transparent;
}
.files [class~="sf-content"] {
    justify-content: initial;
}

.toolbar {
    padding-top: 24px;
    display: flex;
    justify-content: center;

    @media all and (min-width: 1024px) {
        justify-content: flex-end;
    }
}
.btn {
    min-width: 120px!important;
    margin-right: 16px!important;
    height: 38px!important;
    border-radius: 8px!important;
    text-transform: initial!important;
    text-align: center!important;
    font-family: Outfit, sans-serif!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: normal!important;
}
.removeBtn {
    border: 1px solid #d32f2f!important;
}
.saveBtn {
    background-color: #042E6B !important;
    color: #fff!important;
    @media all and (min-width: 1024px) {
        margin: 0!important;
    }
}
