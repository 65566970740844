.wrap {
  padding: 16px 40px 40px 77px;
}

.header {
  font-size: 18px;
  font-weight: 500;
  padding: 30px 0 20px;
}

.tHeader {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding-bottom: 20px;
}

.row {
  display: flex;
  min-height: 27px;
}

.col {
  text-align: center;

  &:first-child {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  &:last-child {
    flex: 0 0 calc(50% - 35px);
    max-width: calc(50% - 35px);
  }
}

.target {
  [class~="dropable"] {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6);
  }
}

.pageActions {
  padding-top: 30px;
}

.btn {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px!important;
  margin-top: 16px!important;
  text-transform: none!important;
}
