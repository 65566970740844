.table {
  border-radius: 10px;
  border: 1px solid #042E6B;
  overflow: hidden;
  margin-top: 25px;
}

.tableRow {
  display: flex;
}

.tableCol {
  flex: 0 0 50%;
  max-width: 50%;
  border-left: 1px solid #042E6B;
}

.tableCol:first-child {
  border: none;
}

.tableCol.empty {
  background-color: #042E6B;
}

.row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #042E6B;
  height: 100%;
}

.tableRow:last-child .row {
  border: none;
}

.col {
  padding: 9px 15px;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid #042E6B;
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.col:first-child {
  border: none;
}

.col.colored {
  background: #F0F3FB;
}

.inputWrap {
  display: flex;
}

.input {
  width: 100% !important;
}

.input input {
  padding: 0;
  color: #042E6B;
  font-family: Outfit, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  height: 18px;
}
