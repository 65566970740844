.wrap {

}

.category {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
        flex-direction: initial;
    }
}

.item {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    border: 1px solid #042E6B;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;

    @media screen and (min-width: 1200px) {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
    }
}

.itemTitle {
    flex: 160px;
    max-width: 160px;
    background: #042E6B;
    color: white;
    padding: 4px 22px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}

.itemBody {
    flex: 0 0 calc(100% - 230px);
    max-width: calc(100% - 230px);
    background: #F0F3FB;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (min-width: 1400px) {
        padding: 3px 50px;
    }

    &.fixed {
        color: #042E6B;
        justify-content: center;
        font-family: Outfit, sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
        min-height: 46px;
    }
}

.dropdownOverlay {
    border-radius: 8px!important;
    border: 1px solid #042E6B!important;
    background: #F0F3FB!important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)!important;
    overflow: hidden!important;
}

.selected, .selectedText {
    border-radius: 10px;
    border: 1px solid #042E6B;
    padding: 3px;
    color: #042E6B;
    text-align: center;
    font-family: Outfit, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;

    &.empty {
        min-height: 21px;
    }
}

.selectButton {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-height: 40px;
}

.selectButton.empty .selectedText {
    border: none;
}

.arrowIcon {
    color: #042E6B;
}

.selectedText {
    min-width: 70px;
    margin-right: 10px;
    padding-left: 6px;
    padding-right: 6px;
}

.itemPercents {
    flex: 90px;
    max-width: 90px;
    border-left: 1px solid #042E6B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #042E6B;
}
