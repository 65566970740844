.btn {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 147px;
  background: #042E6B !important;
  height: 35px;
  border: none;
  border-radius: 0 0 8px 8px;
  z-index: 0;
  color: white;
}

.btn:hover {
  background: #083f8f !important;
  color: white;
}

.btnText {
  margin-right: 8px;
}

.select {
  width: 100%;
}
