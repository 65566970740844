._ {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-right: 60px;
}

.item {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    border: 1px solid #042E6B;
    border-radius: 8px 0 0 8px;
    position: relative;
}

.itemCol {
    width: calc(100% / 3);
    height: 100%;
}

.input {
    border: none;
    background: transparent;

    &:focus {
        box-shadow: none;
    }

    &[class~="ant-input-status-error"] {
        background: transparent !important;
        box-shadow: none !important;
    }
}

.title {
    border-right: 1px solid #042E6B;
}

.percent {
    text-align: center;
    height: 100%;
    line-height: 33px;
    border-left: 1px solid #042E6B;
}

.answers {
    text-align: center;
    height: 100%;
    line-height: 33px;
}
.answersError {
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1.5715;
    margin-top: -1px;
}

.add {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #042E6B;
    color: #042E6B;
    padding: 0 20px;
    min-width: 250px;
    height: 40px;

    &:hover {
        color: #042E6B;
        border: 1px solid #042E6B;
        background: #F0F3FB;
    }

    &:focus {
        color: #042E6B;
        border: 1px solid #042E6B;
        background: #F0F3FB;
    }
}

.remove {
    position: absolute;
    top: 7px;
    left: calc(100% + 40px);

    &:hover {
        cursor: pointer;
    }
}

.decor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: calc(100% + 2px);
    border: 1px solid #042E6B;
    border-left: none;
    border-radius: 0 8px 8px 0;
    right: -15px;
    top: -1px;
    background: #F0F3FB;
    cursor: grab;
}

.decorPoint {
    width: 3px;
    height: 3px;
    display: block;
    background: #042E6B;
    border-radius: 50%;
    margin: 2px 0;
}
