.viewPage {
  &.wrap {
    padding-top: 10px;
  }

  .table {
    border: 1px solid #042E6B;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    font-family: Outfit, sans-serif;

    @media screen and (min-width: 992px) {
      margin-bottom: 10px;
    }
  }

  .tableRow {
    min-height: 34px;
    border-bottom: 1px solid #042E6B;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #042E6B;

    &.fixed {
      background: #F0F3FB;
    }

    &:last-child {
      border: none;
    }
  }

  .totalWrap {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .total {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Outfit, sans-serif;
  }

  .title {
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
  }

  .counter {
    font-size: 52px;
    font-weight: 400;
    line-height: normal;
  }

  .actions {}
  .action {
    border-radius: 4px!important;
    width: 200px!important;
  }
}

.editPage {
  .header {
    font-size: 18px;
    font-weight: 500;
    padding: 30px 0 20px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .col {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .target {
    [class~="dropable"] {
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6);
    }
  }

  .pageActions {
    padding-top: 30px;
  }

  .btn {
    width: 200px!important;
    height: 38px!important;
    border-radius: 8px!important;
    margin-right: 14px!important;
    text-transform: none!important;
  }

  .footer {
    border: 1px solid #042E6B;
    background: #F0F3FB;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    border-radius: 8px;
    margin-top: 22px;
    color: #042E6B;

  }
}
