.box {
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #042E6B;
  border-radius: 8px;
  background-color: #E1E5ED;
}

.box > span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background-color: transparent;
}

.dragger {
  border-radius: 8px !important;
  border: none !important;
  transition: background-color 0.5s ease-in-out !important;
}
.dragger:hover {
  background-color: #cdd4e3;
}

.dragger img {
  max-width: 55px;
  max-height: 55px;
}
.close {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #ff4d4f;
  color: white;
  font-size: 20px;
  line-height: 1;
  transform: rotate(45deg);
  top: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity .3s ease;
}
[class~="ant-upload-btn"] {
  position: relative;

  &:hover {
    .close {
      opacity: 1;
      transition: opacity .3s ease;
    }
  }
}

.text {
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  color: #042E6B;
  font-weight: 400;
}
