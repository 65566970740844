.page {
  padding: 30px 44px;
}

.form {
  padding: 0!important;
}

.wrapper:last-child {
  padding-bottom: 24px;
}

@media all and (min-width: 1200px) {
  .wrapper:first-child {
    padding-bottom: 24px;
  }
}

.wrapperColFirst, .wrapperColLast {
  margin-bottom: 24px;
}

@media all and (min-width: 1200px) {
  .wrapperColFirst, .wrapperColLast {
    margin-bottom: 0;
  }
}

.block {
  border-radius: 8px;
  border: 1px solid #042E6B;
  background: #FFF;
  padding: 18px 26px 0;
  margin-bottom: 25px;
  height: 100%;
}

.blockHeader {
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}

.formRow {
  margin-top: 0!important;
  min-height: initial;
}

.formLabelCol {
  font-size: 18px;
  font-weight: 400;
}

.mainFormRow  {
  margin-bottom: 12px;
  min-height: 24px;
}

.switcher {
  display: flex;
  flex-wrap: wrap;
}

@media all and (min-width: 600px) {
  .switcher {

  }
}

.switcherField {
  flex: 0 0 50%;
  max-width: 50%;
  min-height: 50px;
}

@media all and (min-width: 600px) and (max-width: 800px) {
  .switcherField {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 50px;
  }
}

.switcherField label {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.formTitleCol {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.formValueCol p[class~="MuiFormHelperText-root"] {
  display: none!important;
}

.formValueCol p[class~="MuiFormHelperText-root"][class~="Mui-error"] {
  display: initial!important;
  color: red!important;
}

.nameWrapper div[class~="MuiInputBase-formControl"]::before,
.nameWrapper div[class~="MuiInputBase-formControl"]::after {
  display: none;
}

.nameWrapper div[class~="MuiInputBase-formControl"] {
  padding: 0;
}

.nameInput {
  position: relative;
}

.nameInput textarea::placeholder {
  color: rgba(0, 0, 0, 0.50);
}

.nameInput textarea {
  border-radius: 8px;
  border: 1px solid #002A77;
  background: #EEE;
  padding: 9px 13px;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  min-height: 50px;
  margin-top: 7px;
}

.nameInput.error textarea {
  border: 1px solid red;
}

.counter {
  position: absolute;
  right: 0;
  top: -16px;
  color: #002A77;
  text-align: right;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.nameInput.error .counter {
  color: red;
}

.actions {
  padding: 0!important;
  min-height: initial!important;
  justify-content: flex-start!important;
  align-items: center!important;
  background-color: transparent!important;
}

.action {
  width: 200px!important;
  height: 38px!important;
  border-radius: 8px;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.actionSave {
  color: #FFF!important;
  background: #042E6B!important;
}

.actionSave:disabled {
  color: #444!important;
  background: #ddd!important;
  border-color: #bbb!important;
}

.actionIcon {
  margin-right: 10px!important;
  font-size: 18px!important;
}
