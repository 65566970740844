.date {
  margin-bottom: auto;
}

.note {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 186px;
  padding-right: 8px;
}

.noteInput {
  width: 100%;
  display: block;
  padding: 14px 24px;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  color: #002A77;
  border: 1px solid #002A77;
  border-radius: 8px;
  outline: none;
  min-height: 90px;
}

.noteExtracted {
  font-weight: bold;
}

.file {
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #E1E5ED;
  padding: 20px 13px;
  min-width: 147px;
  height: 186px;
  position: relative;
}

.fileInner {
  border-radius: 8px;
  border: 2px solid #042E6B;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendBy {
  position: absolute;
  top: 5px;
  right: 5px;
}

