.box {
  width: 100%;
  margin-top: -25px;
  padding: 10px 20px 0;
}
.saldoLine {
  margin-top: 20px;
  border-bottom: 1px solid #000c17;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  margin-left: -5px;
  width: 160px;
}
.logoImg {
  max-width: 100%;
}
.logoText {
  max-width: 300px;
}
.info {
  margin-top: 20px;
}
.code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 1em;
}
.details {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
}
.subtotal {
  margin-top: 30px;
  line-height: 1.8em;
}
.subtotalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.green {
  font-weight: bold;
  color: #52c41a;
}
.orange {
  color: orange;
}
.downloadIcon {
  font-size: 22px;
  color: white;
  margin-right: 10px;
}
.ordersLabel {
  margin-top: 20px;
  text-transform: uppercase;
}
.orders {
  width: 100%;
  border-collapse: collapse;
}
.orders td, .orders th {
  text-align: left;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EFEFEF;
  font-size: 13px;
  line-height: 1.2em;
}
.totalRow td {
  padding-top: 40px;
  font-size: 18px;
  color: #033d56;
}
.totalRow {
  border-bottom: 2px solid #000c17;
}
.balanceRow {
  font-size: 18px;
}
