._ {
    width: calc(100% - 1px);
}

.option {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 38px;
    border-top: 1px solid #042E6B;
    border-left: 1px solid #042E6B;
    border-right: 1px solid #042E6B;
    background: #fff;

    &:first-child {
        border-radius: 8px 8px 0 0;
    }

    & input {
        height: 38px;
        border: none;
        background: transparent;
        padding-left: 14px;
        padding-right: 14px;

        &:focus {
            box-shadow: none;
        }
    }
}

.title {
    margin-right: auto;
}

.percent {
    border-left: 1px solid #042E6B;
    height: 100%;
    max-width: 120px;
}

.action {
    background: #fff;
    padding: 3px 2px;
    border: 1px solid #042E6B;
    border-radius: 0 0 8px 8px;
}

.add {
    background: #F0F3FB;
    border-radius: 4px 4px 8px 8px;
    color: #000;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    line-height: 34px;

    &:hover {
        background: #F0F3FB;
        color: #000;
    }

    &:focus {
        background: #F0F3FB;
        color: #000;
    }

    & svg {
        transform: rotate(45deg);
    }

    & circle {
        fill: #042E6B;
    }
}

.remove {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    left: -16px;
    cursor: pointer;
}
