.cardWrapper {

}
.title {
     margin-bottom: 0!important;
 }
.textarea {
    border-radius: 8px;
    border: 1px solid #002A77;
    height: 124px !important;
    padding: 8px 12px;
    background-color: #E1E5ED;
    font-size: 12px;
    line-height: 1.1;

    & > div::before {
        display: none;
    }

    &::placeholder {
        color: #74706B;
        font-size: 12px;
        line-height: 1.1;
    }
}
