.InitialPageContent .RaEdit-main, .InitialPageContent form, .InitialPageContent .MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
}

.InitialPageContent form > .MuiCardContent-root {
  margin-top: 20px;
  padding: 0;
  background-color: transparent;
}

.InitialPageContent .without-label .MuiInput-root {
  margin-top: -5px;
}
.InitialPageContent .without-label .MuiInput-root::before {
  border-bottom-color: rgba(0, 42, 119, 0.10);
}

.InitialPageContent .MuiFormHelperText-root > span {
  display: none;
}
.InitialPageContent .MuiFormHelperText-root:empty {
  line-height: 0;
}

.InitialPageContent .without-label .ant-picker-input {
  margin-top: 0;
}

.InitialPageContent .without-label .ant-picker-input {
  margin-top: 0;
}

.InitialPageContent form > .MuiToolbar-root {
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
}

.InitialPageContent form > .MuiToolbar-root button {
  min-width: 160px;
  height: 38px;
  color: #E1E5ED;
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  text-transform: none;
  margin-left: 40px;
}

.InitialPageContent form > .MuiToolbar-root button svg {
  font-size: 19px;
}

.InitialPageContent form > .MuiToolbar-root button:disabled {
}

.InitialPageContent form > .MuiToolbar-root button[aria-label="Delete"] {
  order: -1;
  background-color: transparent;
  border: 1px solid #D72B2C;
  color: #D72B2C;
}

.InitialPageContent form > .MuiToolbar-root button[aria-label="Delete"] svg {
  display: none;
}

.InitialPageContent form > .MuiToolbar-root button[aria-label="Save"] {
  background: #042E6B !important;
  order: 10;
}

.InitialPageContent form > .MuiToolbar-root .RaToolbar-defaultToolbar {
  display: flex;
  justify-content: flex-end;
}
